import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ReviewQuestionTemplateState } from '../types'

export const selectReviewQuestionTemplates = createSelector(
  (state: StoreState) => state.performance.reviewQuestionTemplates,
  reviewQuestionTemplatesState =>
    memoize((templateId?: string): ReviewQuestionTemplateState[] => {
      if (!templateId) return []

      return Object.values(reviewQuestionTemplatesState[templateId]?.entities || {}) as ReviewQuestionTemplateState[]
    })
)
