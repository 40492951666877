import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { QuestionState } from '../types'
import { fetchQuestions } from '../actions'
import { selectQuestions } from '../selectors'
import { FetchStrategy } from '~/types/common'

export const useQuestions = (fetchStrategy: FetchStrategy = 'always'): [QuestionState[], boolean] => {
  const questions = useSelector(selectQuestions)
  const loading = useSelector((state: StoreState) => state.performance.questionsLoading)

  useEffect(() => {
    dispatch(fetchQuestions({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [questions, loading]
}
