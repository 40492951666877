import React, { FC, ChangeEvent, useState, useCallback, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Form, Input, Link } from '~/core-components'
import { Col, Row } from '~/components'
import { emptyGuid } from '~/constants'
import { Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { IRatingScale, ISaveRating } from '../../../types'
import './RatingForm.less'

interface RatingFormProps {
  data: ISaveRating
  readOnly?: boolean
  errors?: Errors
  onChange: (data: ISaveRating) => void
}

export const getDefaultRatingScales = () =>
  [
    { id: uuidv4(), scale: 1, name: '' },
    { id: uuidv4(), scale: 2, name: '' },
    { id: uuidv4(), scale: 3, name: '' },
    { id: uuidv4(), scale: 4, name: '' },
    { id: uuidv4(), scale: 5, name: '' }
  ] as IRatingScale[]

export const getEmptyRatingFormData = () =>
  ({
    name: '',
    inactiveDate: '',
    scales: getDefaultRatingScales()
  } as ISaveRating)

const NEW_SCALE: IRatingScale = { id: emptyGuid, scale: 0, name: '' }

export const getNewScale = (scales: IRatingScale[]) => {
  return {
    ...NEW_SCALE,
    id: uuidv4(),
    scale: scales.reduce((max, curr) => (curr.scale > max ? curr.scale : max) + 1, 0)
  } as IRatingScale
}

export const RatingForm: FC<RatingFormProps> = ({ data, readOnly, errors, onChange }) => {
  const [formData, setFormData] = useState<ISaveRating>(getEmptyRatingFormData())
  const [focusRef] = useFocus(!readOnly)
  const [isInactive, setIsInactive] = useState(false)

  useEffect(() => {
    if (data) {
      setFormData(data)
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(getEmptyRatingFormData())
      setIsInactive(false)
    }
  }, [data])

  const getNewScale = useCallback(() => {
    return {
      ...NEW_SCALE,
      scale: formData.scales.reduce((max, curr) => (curr.scale > max ? curr.scale : max) + 1, 0)
    } as IRatingScale
  }, [formData.scales])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onChange === 'function' && onChange(updated)
    },
    [formData, onChange]
  )

  return (
    <div className="rating-form">
      <Row gutter={15}>
        <Col flex="auto">
          <Form.Item label="Name">
            <Input
              ref={focusRef}
              value={formData.name}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col flex="none">
          <Form.Item label="Inactive" validateStatus={errors?.inactiveDate ? 'error' : ''} help={errors?.inactiveDate}>
            <Checkbox
              checked={isInactive}
              readOnly={readOnly}
              onChange={(event: CheckboxChangeEvent) => {
                setIsInactive(event.target.checked)
                handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {formData.scales.map((scale, index) => (
        <Row key={`${scale.scale}`} gutter={15} className="rating-form-scales">
          <Col flex="50px">
            {index === 0 && <label>Scale</label>}
            <Input.Number
              value={scale.scale}
              readOnly
              onChange={(scale: number | null) =>
                handleFormDataChange({
                  scales: [
                    ...formData.scales.slice(0, index),
                    { ...formData.scales[index], scale },
                    ...formData.scales.slice(index + 1, formData.scales.length)
                  ]
                })
              }
            />
          </Col>
          <Col flex={1}>
            {index === 0 && <label>Name</label>}
            <Input
              value={scale.name}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({
                  scales: [
                    ...formData.scales.slice(0, index),
                    { ...formData.scales[index], name: event.target.value },
                    ...formData.scales.slice(index + 1, formData.scales.length)
                  ]
                })
              }
            />
          </Col>
          <Col flex={2}>
            {index === 0 && <label>Description</label>}
            <Input.TextArea
              rows={2}
              value={scale.description}
              readOnly={readOnly}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                handleFormDataChange({
                  scales: [
                    ...formData.scales.slice(0, index),
                    { ...formData.scales[index], description: event.target.value },
                    ...formData.scales.slice(index + 1, formData.scales.length)
                  ]
                })
              }
            />
          </Col>
          <Col hidden={readOnly}>
            {index === 0 && <label>&nbsp;</label>}
            <Link
              onClick={() => {
                const scales = [
                  ...formData.scales.slice(0, index),
                  ...formData.scales.slice(index + 1, formData.scales.length)
                ].map((item, i) => ({ ...item, scale: i + 1 }))

                handleFormDataChange({ scales })
              }}
            >
              remove
            </Link>
          </Col>
        </Row>
      ))}
      {!readOnly && (
        <Form.Item>
          <Link onClick={() => handleFormDataChange({ scales: [...formData.scales, getNewScale()] })}>add scale</Link>
        </Form.Item>
      )}
    </div>
  )
}
