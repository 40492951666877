import { Pagination, Search } from '~/types/common'
import { request, getBaseUrl, timeout } from '~/utils/request'
import { YtdRecordViewState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/ytdrecord')

export const apiGetYtdRecordsView = (viewId: string, ytdYear: string, companyId: string, params: Pagination & Search) =>
  request<YtdRecordViewState>('get', `${baseUrl}/view/${viewId}`, { ...params, ytdYear, companyId })

export const apiGetYtdRecordsExcel = (viewId: string, ytdYear: string, companyId: string, params: Search) =>
  request(
    'get',
    `${baseUrl}/view/${viewId}/excel`,
    { ...params, ytdYear, companyId },
    { responseType: 'blob', timeout }
  )

export const apiProcessYtdRecords = (
  ytdYear: string,
  isAllEmployees: boolean,
  paymentMonth: number,
  employeeIds: string[] | null
) => request<void>('post', `${baseUrl}/process`, { ytdYear, isAllEmployees, paymentMonth, employeeIds })
