import React, { FC } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { PermissionRoute } from '~/routes/PermissionRoute'
import { PrivateRoute } from '~/routes/PrivateRoute'
import { SYS_ROUTES, SYS_PATH } from '~/routes/routes'
import { AppRouteProps } from '../types'
import { auditLogMenus, auditLogRoutes } from '../configs'
import { SystemMenuProvider } from './SystemMenuContext'
import { SystemLayout } from './SystemLayout'
import { Systems } from './Systems'

export const auditLogRouteProps: AppRouteProps[] = auditLogRoutes.map(({ path, exact }) => ({
  path,
  exact,
  sysPermissionId: auditLogMenus.find(m => m.path === path)?.sysPermissionId,
  render: () => (
    <SystemMenuProvider menus={auditLogMenus}>
      <SystemLayout routes={auditLogRoutes} />
    </SystemMenuProvider>
  )
}))

export const SystemsLayout: FC = () => (
  <Switch>
    <Redirect exact from={SYS_PATH} to={SYS_ROUTES.main} />
    <PrivateRoute exact path={SYS_ROUTES.main} component={Systems} />
    {auditLogRouteProps.map(route =>
      route.sysPermissionId ? (
        <PermissionRoute key={route.path as string} sysPermissionId={route.sysPermissionId} {...route} />
      ) : (
        <PrivateRoute key={route.path as string} {...route} />
      )
    )}
  </Switch>
)
