import { showError, showSuccess } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiSubmitCpfFile } from '../api/cpf-submission.api'
import { refetchCpfSubmissionsView } from '../reducers'
import { CpfSubmitRequest } from '../types'

export const submitCpfFile = (params: CpfSubmitRequest): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    const { status, result, errors, message, errorData } = await apiSubmitCpfFile(params)

    if (status) {
      showSuccess('Submission is successful!')
      dispatch(refetchCpfSubmissionsView())
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
