export enum QuickBooksPayItemCode {
  BasicPay = 'basic_pay',
  CpfEr = 'cpf_er',
  CpfEm = 'cpf_em',
  Sdl = 'sdl',
  Fwl = 'fwl',
  Fund = 'fund',
  PayItem = 'pay_item'
}

export enum QuickBooksSignType {
  Addition = '+',
  Deduction = '-'
}

export enum QuickBooksSplitType {
  Employee = 'em'
}
