import React, { FC, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, ColumnsType, PageHeader, Space, Table, Tag } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { IRating, RatingState } from '../../types'
import { useRatings } from '../../hooks'
import { AddRatingDrawer } from '../Rating/components/AddRatingDrawer'
import './Ratings.less'

type RatingTable = IRating

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: RatingState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface RatingsProps {}

export const Ratings: FC<RatingsProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.question, PermissionAction.Modify)
  const [ratings, loading] = useRatings()

  const handleAddRating = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<RatingTable> = useMemo(
    () => [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        render: (value: string, record) => (
          <Space>
            <Link to={SETTINGS_ROUTES.rating.replace(':id', record.id)}>{value}</Link>
            {isInactive(record.inactiveDate) && <InactiveTag />}
          </Space>
        )
      },
      {
        title: 'Scale',
        key: 'minScale',
        dataIndex: 'minScale',
        render: (value: string, record) => (
          <Space direction="vertical" size={1}>
            <div>
              Scale from {record.minScale} to {record.maxScale}
            </div>
            <Space>
              {record.scaleNames.map(name => (
                <Tag>{name}</Tag>
              ))}
            </Space>
          </Space>
        )
      }
    ],
    []
  )

  return (
    <div id="ratings" className="ratings">
      <DocumentTitle title="Review Ratings" />
      <PageHeader
        title="Review ratings"
        containerId="ratings"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddRating}>
              Add rating
            </Button>
          )
        }
      />
      <div className="ratings__body">
        <Card table>
          <Table rowKey="id" dataSource={ratings} pagination={false} columns={columns} loading={loading} />
        </Card>
      </div>
      {canModify && <AddRatingDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
