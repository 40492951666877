import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, ColumnsType, Form, PageHeader, Space, Table, Tag, TagType, Tooltip } from '~/core-components'
import { DateCustomPicker, DateCustomPickerItem, DocumentTitle, SearchInput } from '~/components'
import { Screen, ViewCriteriaSimple, ViewCriteria, updateViewCriteria, useFirstView } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { DateRange } from '~/types/common'
import { StoreState } from '~/types/store'
import { SYS_ROUTES } from '~/routes/routes'
import { fetchAuditLoginsView } from '../../actions/fetch-audit-logins-view'
import { apiDownloadAuditLoginsExcel, apiDownloadAuditLoginsPdf } from '../../api/audit-login.api'
import { showError, getFileTimestamp, downloadWithDom } from '~/utils'
import { selectAuditLoginsView } from '../../selectors'
import { useHasChange } from '~/hooks'
import { refetchAuditLoginsView } from '../../reducers'
import { AuditLoginState } from '../../types'
import { SysDownloadBtn } from '../components/SysDownloadBtn'
import { AuditLoginStatus } from '~/constants'
import { useSysOptions } from '~/features/employee'
import './AuditLogins.less'

interface AuditLoginsProps {}

const routes = [
  {
    path: SYS_ROUTES.main,
    breadcrumbName: 'System'
  },
  {
    path: '',
    breadcrumbName: 'User access log'
  }
]

const paginationStyle: CSSProperties = { marginRight: 20 }

const SCREEN_CODE: Screen = 'audit_login'
const PAGE_SIZE_OPTIONS = ['50', '100', '200']
const START_DATE = moment().startOf('isoWeek').format('YYYY-MM-DD')
const END_DATE = moment().endOf('isoWeek').format('YYYY-MM-DD')

const PERIOD_OPTIONS: DateCustomPickerItem[] = [
  {
    key: 'this_week',
    value: 'This week',
    startDate: START_DATE,
    endDate: END_DATE
  },
  {
    key: 'last_week',
    value: 'Last week',
    startDate: moment().startOf('isoWeek').add(-1, 'week').format('YYYY-MM-DD'),
    endDate: moment().endOf('isoWeek').add(-1, 'week').format('YYYY-MM-DD')
  },
  {
    key: 'this_month',
    value: 'This month',
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  },
  {
    key: 'last_month',
    value: 'Last month',
    startDate: moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'),
    endDate: moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
  }
]

export const AuditLogins: FC<AuditLoginsProps> = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(50)
  const [search, setSearch] = useState<string>('')

  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const dataLoading = useSelector((state: StoreState) => state.system.auditLoginsViewLoading)
  const data = useSelector(selectAuditLoginsView)(viewId)
  const isRefetch = useHasChange((state: StoreState) => state.system.auditLoginsViewRefetch)

  const [startDate, setStartDate] = useState<string | null>(START_DATE)
  const [endDate, setEndDate] = useState<string | null>(END_DATE)

  const [statuses] = useSysOptions('audit_login_status')

  useEffect(() => {
    if (viewId)
      dispatch(
        fetchAuditLoginsView(viewId, { offset: pageSize * (page - 1), limit: pageSize }, search, startDate, endDate)
      )
  }, [viewId, page, pageSize, search, startDate, endDate, isRefetch])

  const handleDateRangeChange = useCallback((startDate: string | null, endDate: string | null) => {
    setPage(1)
    setStartDate(startDate)
    setEndDate(endDate)
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchAuditLoginsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 50)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const columns: ColumnsType<AuditLoginState> = [
    {
      title: 'Date',
      key: 'accessDate',
      dataIndex: 'accessDate',
      width: 250,
      render: (value: string) => (value ? moment(value).format('DD MMM YYYY hh:mm:ss A') : undefined)
    },
    {
      title: 'User',
      key: 'userName',
      dataIndex: 'userName'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (value: string) => {
        let tagType: TagType = 'original'
        if (value === AuditLoginStatus.LoginSuccess) {
          tagType = 'success'
        } else if (value === AuditLoginStatus.LoginFail) {
          tagType = 'danger'
        }

        return <Tag type={tagType}>{statuses[value]?.value}</Tag>
      }
    },
    {
      title: 'IP address',
      key: 'ipAddress',
      dataIndex: 'ipAddress'
    }
  ]

  const handleDownloadPdf = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadAuditLoginsPdf(viewId, {
      search,
      ...({ startDate, endDate } as DateRange)
    })

    if (status) {
      const fileName = `user_access_logs_${getFileTimestamp()}.pdf`
      downloadWithDom(result, fileName)
    } else {
      console.error('Error while downloading', errors)
      showError(message, errorData)
    }
  }, [viewId, search, startDate, endDate])

  const handleDownloadExcel = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadAuditLoginsExcel(viewId, {
      search,
      ...({ startDate, endDate } as DateRange)
    })

    if (status) {
      const fileName = `user_access_logs_${getFileTimestamp()}.xlsx`
      downloadWithDom(result, fileName)
    } else {
      console.error('Error while downloading', errors)
      showError(message, errorData)
    }
  }, [viewId, search, startDate, endDate])

  return (
    <div className="audit-logins">
      <DocumentTitle title="User Access Log" />
      <PageHeader title="User access log" breadcrumb={{ routes }} />
      <div className="audit-logins__body">
        <div className="audit-logins__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            <DateCustomPicker defaultValue="this_week" options={PERIOD_OPTIONS} onChange={handleDateRangeChange} />
            <Tooltip title="Download as">
              <SysDownloadBtn onDownloadPdf={handleDownloadPdf} onDownloadExcel={handleDownloadExcel} />
            </Tooltip>
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            loading={dataLoading || viewLoading}
            fitParent
            scroll={{ y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
    </div>
  )
}
