import React, { createContext, FC, useContext, useState } from 'react'
import { MenuItem } from '~/types/common'

interface SystemMenu {
  menus: MenuItem[]
  setMenus: (menus: MenuItem[]) => void
  siderWidth: number
  setSiderWidth: (width: number) => void
}

const DEFAULT: SystemMenu = {
  menus: [],
  setMenus: () => {},
  siderWidth: 230,
  setSiderWidth: () => {}
}

const SystemMenuContext = createContext<SystemMenu>(DEFAULT)

export const useSystemMenu = () => {
  return useContext(SystemMenuContext)
}

interface SystemMenuProviderProps {
  menus: MenuItem[]
}

export const SystemMenuProvider: FC<SystemMenuProviderProps> = ({ children, menus: initialMenus }) => {
  const [menus, setMenus] = useState<MenuItem[]>(initialMenus)
  const [siderWidth, setSiderWidth] = useState<number>(230)
  return (
    <SystemMenuContext.Provider value={{ menus, setMenus, siderWidth, setSiderWidth }}>
      {children}
    </SystemMenuContext.Provider>
  )
}
