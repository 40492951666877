import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CpfSubmissionState } from '../types'

export const selectCpfSubmission = createSelector(
  (state: StoreState) => state.payroll.cpfSubmissions,
  cpfSubmissionsState =>
    memoize((id?: string): CpfSubmissionState => cpfSubmissionsState.entities[id || ''] || ({} as CpfSubmissionState))
)
