import { replaceReviewQuestionTemplates, setReviewQuestionTemplatesLoading } from '../reducers'
import { apiGetReviewQuestionTemplates } from '../api/review-template.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchReviewQuestionTemplates = (
  templateId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!templateId) return

    const loading = getState().performance.reviewQuestionTemplatesLoading[templateId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().performance.reviewQuestionTemplates[templateId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setReviewQuestionTemplatesLoading({ templateId, loading: true }))
      const { result, status } = await apiGetReviewQuestionTemplates(templateId)
      if (status) {
        dispatch(replaceReviewQuestionTemplates({ templateId, data: result }))
      }
    } finally {
      dispatch(setReviewQuestionTemplatesLoading({ templateId, loading: false }))
    }
  }
}
