import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, ColumnsType, Link, Switch, Table } from '~/core-components'
import { fetchTenantConfigs, usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import {
  TenantIdentityProviderState,
  toggleTenantIdentityProviderInactive,
  useTenantIdentityProviders
} from '~/features/master'
import { useSysOptions } from '~/features/employee'
import { MutateIdentityProviderDrawer } from './components/MutateIdentityProviderDrawer'
import './IdentityProviders.less'

type TenantIdentityProviderTable = TenantIdentityProviderState

interface IdentityProvidersProps {}

interface DrawerState {
  visible: boolean
  data?: TenantIdentityProviderState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const IdentityProviders: FC<IdentityProvidersProps> = () => {
  const canModify = usePermissionGate(Permission.idpConfig, PermissionAction.Modify)
  const [updatingInactive, setUpdatingInactive] = useState<string>()
  const [tenantIdentityProviders, loading] = useTenantIdentityProviders()
  const [identityProviderDict] = useSysOptions('identity_provider')
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  useEffect(() => {
    dispatch(fetchTenantConfigs())
  }, [])

  const handleInactiveChange = useCallback(async (idp: TenantIdentityProviderState) => {
    setUpdatingInactive(idp.providerType)
    try {
      await dispatch(toggleTenantIdentityProviderInactive(idp.id))
    } finally {
      setUpdatingInactive(undefined)
    }
  }, [])

  const handleAdd = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEdit = useCallback((idp: TenantIdentityProviderState) => {
    setDrawerState({ visible: true, data: idp })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<TenantIdentityProviderTable> = useMemo(
    () => [
      {
        title: 'Name',
        key: 'providerType',
        dataIndex: 'providerType',
        render: (value: string) => identityProviderDict[value]?.value || value
      },
      {
        key: 'action',
        align: 'right',
        className: 'fit-width',
        render: (value: string, record) =>
          canModify && (
            <Link size="small" onClick={() => handleEdit(record)}>
              edit
            </Link>
          )
      },
      {
        title: 'Enabled',
        key: 'inactiveDate',
        dataIndex: 'inactiveDate',
        align: 'center',
        width: 100,
        render: (inactiveDate: string, record) => (
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={inactiveDate == null}
            loading={updatingInactive === record.providerType}
            disabled={!canModify || !!updatingInactive}
            onChange={() => handleInactiveChange(record)}
          />
        )
      }
    ],
    [updatingInactive, canModify, identityProviderDict, handleEdit, handleInactiveChange]
  )

  return (
    <div className="identity-providers">
      <Card
        title="Authentication providers"
        table
        extra={<Button onClick={handleAdd}>Add authentication provider</Button>}
      >
        <Table
          rowKey="code"
          dataSource={tenantIdentityProviders}
          pagination={false}
          columns={columns}
          loading={loading}
          rowClassName={record => (record.isShown ? 'identity-providers__table-row--shown' : '')}
        />
      </Card>
      {canModify && <MutateIdentityProviderDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
