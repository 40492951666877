import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { CpfSubmissionState } from '../types'
import { FetchStrategy } from '~/types/common'
import { selectCpfSubmission } from '../selectors'
import { fetchCpfSubmission } from '../actions'

export const useCpfSubmission = (
  id?: string,
  fetchStrategy: FetchStrategy = 'always'
): [CpfSubmissionState, boolean] => {
  const data = useSelector(selectCpfSubmission)(id)
  const loading = useSelector((state: StoreState) => state.payroll.cpfSubmissionsLoading)

  useEffect(() => {
    dispatch(fetchCpfSubmission(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [data, loading]
}
