import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Form, Radio, RadioChangeEvent, Space } from '~/core-components'
import { Col, DrawerForm, YearKeyValues, Row, MonthKeyValues, EmSelect } from '~/components'
import { apiGetEmSelect } from '~/features/employee'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { processYtdRecord } from '../../actions'
import { ActionResult } from '~/types/store'

export interface ProcessYtdRecordsDrawerProps {
  visible: boolean
  onClose: () => void
}

interface FormData {
  ytdYear: string
  isAllEmployees: boolean
  paymentMonth: number | undefined
  employeeId: string
}

const THIS_YEAR = moment().year()
const radioStyle: CSSProperties = { marginTop: 6 }

export const EMPTY_FORM_DATA: FormData = {
  ytdYear: THIS_YEAR.toString(),
  isAllEmployees: true,
  paymentMonth: undefined,
  employeeId: ''
}

export const ProcessYtdRecordsDrawer: FC<ProcessYtdRecordsDrawerProps> = ({ visible, onClose }) => {
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined

    setLoading(true)
    try {
      result = await dispatch(
        processYtdRecord(
          formData.ytdYear,
          formData.isAllEmployees,
          formData.paymentMonth || 0,
          formData.employeeId ? [formData.employeeId] : null
        )
      )
    } finally {
      setLoading(false)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('active')
    if (status) {
      return result
    }
    return []
  }, [])

  return (
    <DrawerForm
      open={visible}
      title="Recalculate YTD records"
      okText="Recalculate"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="process-ytd-records-drawer"
      formId="form-process-ytd-records"
    >
      <Form id="form-process-ytd-records" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Year">
              <YearKeyValues
                ref={focusRef}
                value={formData.ytdYear}
                startYear={THIS_YEAR}
                noOfYearBefore={-10}
                noOfYearAfter={1}
                allowClear={false}
                sortingOrder="desc"
                onChange={(ytdYear: string) => handleFormDataChange({ ytdYear })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item label="Employees :">
              <Radio.Group
                value={formData.isAllEmployees}
                onChange={(event: RadioChangeEvent) => handleFormDataChange({ isAllEmployees: event.target.value })}
                style={radioStyle}
              >
                <Space direction="vertical">
                  <Radio value={true}>All employees</Radio>
                  <Radio value={false}>Specific employees</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24} hidden={formData.isAllEmployees}>
            <Row style={{ marginLeft: 25 }}>
              <Col span={24}>
                <Form.Item label="Month">
                  <MonthKeyValues
                    value={formData.paymentMonth}
                    onChange={(paymentMonth: string) => handleFormDataChange({ paymentMonth })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginLeft: 25 }}>
              <Col span={24}>
                <Form.Item label="Employee">
                  <EmSelect
                    allowClear
                    value={formData.employeeId}
                    onFetch={handleFetchEmployees}
                    onChange={(employeeId: string) => handleFormDataChange({ employeeId })}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
