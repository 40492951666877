import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { ReviewTemplateState } from '../types'
import { fetchReviewTemplate } from '../actions'

export const useReviewTemplate = (
  id: string,
  fetchStrategy: FetchStrategy = 'always'
): [ReviewTemplateState | undefined, boolean] => {
  const reviewTemplate = useSelector((state: StoreState) => state.performance.reviewTemplates?.entities[id])
  const loading = useSelector((state: StoreState) => state.performance.reviewTemplatesLoading)

  useEffect(() => {
    dispatch(fetchReviewTemplate(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [reviewTemplate, loading]
}
