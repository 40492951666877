import React, { FC, useMemo } from 'react'

export interface JsonDisplayProps {
  jsonString: string
}

export const JsonDisplay: FC<JsonDisplayProps> = ({ jsonString }: JsonDisplayProps) => {
  const formattedJson = useMemo(() => {
    let formattedJson
    try {
      formattedJson = JSON.stringify(JSON.parse(jsonString), null, 2)
    } catch (error) {
      formattedJson = 'Invalid JSON'
    }
    return formattedJson
  }, [jsonString])

  return <pre style={{ fontFamily: 'monospace' }}>{formattedJson}</pre>
}
