import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Form, Input } from '~/core-components'
import { Col, Row } from '~/components'
import { Errors } from '~/types/store'

interface ConfigMicrosoftProps {
  configJsonb: string
  onChange: (value: string) => void
  errors?: Errors
}

interface FormData {
  authorityUrl: string
  tenantId: string
  clientId: string
}

const EMPTY_FORM_DATA: FormData = {
  authorityUrl: '',
  tenantId: '',
  clientId: ''
}

export const ConfigMicrosoft: FC<ConfigMicrosoftProps> = ({ configJsonb, onChange, errors }) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  useEffect(() => {
    const data = JSON.parse(configJsonb || '{}') as FormData
    setFormData({
      authorityUrl: data.authorityUrl,
      tenantId: data.tenantId,
      clientId: data.clientId
    })
  }, [configJsonb])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        const value = JSON.stringify({
          authorityUrl: updated.authorityUrl,
          tenantId: updated.tenantId,
          clientId: updated.clientId
        })

        typeof onChange === 'function' && onChange(value)
        return updated
      })
    },
    [onChange]
  )

  return (
    <div className="config-microsoft">
      <Row>
        <Col span={24}>
          <Form.Item
            label="Authority URL"
            validateStatus={errors?.authorityUrl ? 'error' : ''}
            help={errors?.authorityUrl}
          >
            <Input
              value={formData.authorityUrl}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ authorityUrl: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Tenant ID" validateStatus={errors?.tenantId ? 'error' : ''} help={errors?.tenantId}>
            <Input
              value={formData.tenantId}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ tenantId: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Client ID" validateStatus={errors?.clientId ? 'error' : ''} help={errors?.clientId}>
            <Input
              value={formData.clientId}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ clientId: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
