import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import omit from 'lodash/omit'
import { Col, NewTabLink, PeriodKeyValues, ImageBase64Modal, Row, SysOptions } from '~/components'
import { Alert, Button, Form, Input, Select, Space, Tag } from '~/core-components'
import { fetchCompanies, selectCompaniesCpfNo } from '~/features/master'
import { apiGetApexAuthUrl } from '~/features/employee'
import {
  fetchPayRuns,
  refetchCpfSubmissionsView,
  selectPayRuns,
  setCpfSubmissionForm,
  submitCpfFile
} from '~/features/payroll'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { dispatch } from '~/stores/store'
import { PAY_ROUTES } from '~/routes/routes'
import { Errors, StoreState } from '~/types/store'
import { KeyValue } from '~/types/common'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { CpfPaymentMode } from '~/constants'
import { CpfSubmitRequest, CpfFileRequest } from '../../types'
import { fetchCpfPayGroups } from '../../actions'
import { apiGetCpfFile } from '../../api/cpf-submission.api'

export interface CpfSubmissionFormProps {
  type: 'download' | 'submit'
}

export interface CpfSubmissionFormData extends CpfSubmitRequest {}

interface ModalState {
  visible: boolean
  base64?: string
}
const DEFAULT_MODAL_STATE: ModalState = { visible: false }

const currentPeriod = moment().format('YYYY-MM')

const REDIRECT_PATH = PAY_ROUTES.cpfSubmissionCallback
const SCOPE = 'CpfConSub'
const EMPTY_FORM_DATA: CpfSubmissionFormData = {
  paymentMonth: currentPeriod,
  cpfNo: '',
  adviceCode: '01',
  payGroupIds: [],
  cpfLateInterest: 0,
  paymentMode: '',
  deductionDate: ''
}

export const CpfSubmissionForm: FC<CpfSubmissionFormProps> = ({ type }) => {
  const cacheFormData = useSelector((state: StoreState) => state.payroll.cpfSubmissionForm)
  const [formData, setFormData] = useState<CpfSubmissionFormData>({ ...EMPTY_FORM_DATA, ...cacheFormData })
  const [errors, setErrors] = useState<Errors>()
  const [downloading, setDownloading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [payGroups, setPayGroups] = useState<KeyValue[]>([])
  const [payGroupsLoading, setPayGroupsLoading] = useState(false)
  const companiesCpfNo = useSelector(selectCompaniesCpfNo)()
  const isMountedRef = useIsMountedRef()
  const payRuns = useSelector(selectPayRuns)()
  const query = new URLSearchParams(useLocation().search)
  const month = query.get('month')

  const [redirecting, setRedirecting] = useState(false)
  const cpfApexState = useSelector((state: StoreState) =>
    (new Date().getTime() - state.payroll.cpfApexStateTimestamp) / 60000 >= 30 ? null : state.payroll.cpfApexState
  )

  const [modalState, setModalState] = useState<ModalState>(DEFAULT_MODAL_STATE)

  const getCpfPayGroups = useCallback(async (cpfNo: string) => {
    setPayGroupsLoading(true)
    try {
      const { result } = await dispatch(fetchCpfPayGroups(cpfNo))
      setPayGroups(result)
    } finally {
      setPayGroupsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (formData.cpfNo) getCpfPayGroups(formData.cpfNo)
  }, [formData.cpfNo, getCpfPayGroups])

  useEffect(() => {
    dispatch(fetchCompanies())
    dispatch(fetchPayRuns({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (month) {
      setFormData(data => ({ ...data, paymentMonth: cacheFormData.paymentMonth || month }))
    } else {
      const firstPayRunPeriod = payRuns.sort((a, b) => b.payPeriod.localeCompare(a.payPeriod)).slice(0, 1)[0]?.payPeriod
      if (firstPayRunPeriod) {
        setFormData(data => ({ ...data, paymentMonth: cacheFormData.paymentMonth || firstPayRunPeriod }))
      }
    }
  }, [payRuns, month, cacheFormData])

  useEffect(() => {
    if (companiesCpfNo && companiesCpfNo.length > 0) {
      setFormData(data => ({ ...data, cpfNo: cacheFormData.cpfNo || companiesCpfNo[0].cpfNo }))
    }
  }, [companiesCpfNo, cacheFormData])

  const handleFormDataChange = useCallback(async (updates: { [field: string]: any }) => {
    if ('cpfNo' in updates) {
      updates.payGroupIds = []
    }

    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleDownload = useCallback(async () => {
    try {
      setDownloading(true)
      const params: CpfFileRequest = omit(formData, ['paymentMode', 'deductionDate'])
      const { status, result, errors, message, errorData } = await apiGetCpfFile(params)

      if (status) {
        const fileName = `cpf_file_${getFileTimestamp()}.zip`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [formData, isMountedRef])

  const handleLogin = useCallback(async () => {
    try {
      setRedirecting(true)
      dispatch(setCpfSubmissionForm(formData))

      const url = await apiGetApexAuthUrl(REDIRECT_PATH, SCOPE)
      if (url) window.location.href = url.result
    } finally {
      setRedirecting(false)
    }
  }, [formData])

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true)

      const { status, result, errors, message, errorData } = await dispatch(submitCpfFile(formData))
      dispatch(refetchCpfSubmissionsView())

      if (status) {
        if (result.paynowQrCode) {
          setModalState({ visible: true, base64: result.paynowQrCode })
        }
      } else {
        console.error('Error while submitting', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setSubmitting(false)
    }
  }, [formData, isMountedRef])

  const handleQRModalClose = useCallback(() => {
    setModalState({ visible: false })
  }, [])

  return (
    <div className="cpf-submission-form">
      <Form>
        <Row>
          <Col flex="1">
            <Form.Item
              label="Payment month"
              validateStatus={errors?.paymentMonth ? 'error' : ''}
              help={errors?.paymentMonth}
            >
              <PeriodKeyValues
                value={formData.paymentMonth}
                startMonth={currentPeriod}
                noOfMonthBefore={-18}
                noOfMonthAfter={10}
                onChange={(paymentMonth: string) => handleFormDataChange({ paymentMonth })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Form.Item
              label="CPF submission no. (CSN)"
              validateStatus={errors?.cpfNo ? 'error' : ''}
              help={errors?.cpfNo}
            >
              <Select value={formData.cpfNo} onChange={(cpfNo: string) => handleFormDataChange({ cpfNo })}>
                {companiesCpfNo?.map(co => (
                  <Select.Option key={co.cpfNo} value={co.cpfNo}>
                    <Space>
                      {co.cpfNo}
                      {co.companies
                        .filter(c => !!c)
                        .map(c => (
                          <Tag key={c}>{c}</Tag>
                        ))}
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="150px">
            <Form.Item
              label="Advice code"
              validateStatus={errors?.adviceCode ? 'error' : ''}
              help={errors?.adviceCode}
              tooltip={{
                title:
                  'Files with same advice code will be printed on the same hardcopy record of payment. Advice code must be from 01 to 99.',
                icon: (
                  <span>
                    <i className="fal fa-circle-info" />
                  </span>
                )
              }}
            >
              <Input
                value={formData.adviceCode}
                maxLength={2}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ adviceCode: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Form.Item
              label="Payroll groups"
              validateStatus={errors?.payGroupIds ? 'error' : ''}
              help={errors?.payGroupIds}
            >
              <Select
                mode="multiple"
                value={formData.payGroupIds}
                optionFilterProp="title"
                placeholder="All payroll groups"
                onChange={(payGroupIds: string[]) => handleFormDataChange({ payGroupIds })}
                loading={payGroupsLoading}
              >
                {payGroups.map(pg => (
                  <Select.Option key={pg.key} value={pg.key} title={pg.value}>
                    {pg.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Form.Item
              label="CPF late payment interest"
              validateStatus={errors?.cpfLateInterest ? 'error' : ''}
              help={errors?.cpfLateInterest}
            >
              <Input.Number
                value={formData.cpfLateInterest}
                onChange={cpfLateInterest => handleFormDataChange({ cpfLateInterest })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={type === 'download'}>
          <Col flex="1">
            <Form.Item
              label="Payment mode"
              validateStatus={errors?.paymentMode ? 'error' : ''}
              help={errors?.paymentMode}
            >
              <SysOptions
                type="cpf_payment_mode"
                value={formData.paymentMode}
                onChange={paymentMode => handleFormDataChange({ paymentMode })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={type === 'download' || formData.paymentMode !== CpfPaymentMode.DirectDebit}>
          <Col span="24">
            <Form.Item
              label="Deduction date"
              validateStatus={errors?.deductionDate ? 'error' : ''}
              help={errors?.deductionDate}
            >
              <Input.Date
                value={formData.deductionDate ? moment(formData.deductionDate) : undefined}
                onChange={value => handleFormDataChange({ deductionDate: value?.format('YYYY-MM-DD') })}
              />
            </Form.Item>
          </Col>
          <Col span="24">
            <Form.Item>
              <Alert
                type="info"
                message={`If the submission is made before 5:15 PM, the deduction date must be 2 working days later. Otherwise, the deduction date must be 3 working days later. For late submission, the "Deduction date" cannot be later than 3 working days after the submission date.`}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={type !== 'submit' || !!cpfApexState}>
          <Col span={24}>
            <Form.Item label="You've not login to APEX, login is required">
              <Button onClick={handleLogin} loading={redirecting}>
                Login to APEX
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={type !== 'submit' || !cpfApexState}>
          <Col span={24} style={{ marginBottom: 7 }}>
            You have successfully login to APEX.
          </Col>
        </Row>
        <Row hidden={type !== 'submit'}>
          <Col flex="1">
            <Button type="primary" onClick={handleSubmit} loading={submitting} disabled={!cpfApexState}>
              Submit
            </Button>
          </Col>
          <Col>
            <NewTabLink path="https://www2.cpf.gov.sg/ert/dsa/spcpSelection.action">Go to CPF EZPay Portal</NewTabLink>
          </Col>
        </Row>
        <Row hidden={type !== 'download'}>
          <Col flex="1">
            <Button type="primary" onClick={handleDownload} loading={downloading}>
              Download
            </Button>
          </Col>
          <Col>
            <NewTabLink path="https://www2.cpf.gov.sg/ert/dsa/spcpSelection.action">Go to CPF EZPay Portal</NewTabLink>
          </Col>
        </Row>
      </Form>
      <ImageBase64Modal
        title="PayNow QR code for payment"
        description="Submission successful, please scan the PayNow QR code for payment."
        onClose={handleQRModalClose}
        {...modalState}
      />
    </div>
  )
}
