import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { IConfig, ConfigState } from '../types'
import { apiUpdateConfig } from '../api/config.api'
import { setConfig } from '../reducers'

export const updateConfig = (sysConfigCode: string, before: IConfig, request: IConfig): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!sysConfigCode) return

    const after = { ...before, ...request } as IConfig
    const patch = generateJsonPatch<IConfig>(before, after)
    const { status, errors, message, errorData } = await apiUpdateConfig(sysConfigCode, patch)

    if (status) {
      const config = getState().payroll.configs?.entities[sysConfigCode]
      const updated: ConfigState = { ...(config as ConfigState), ...after }
      dispatch(setConfig(updated))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
