export class JobRunStatus {
  static get completed() {
    return 'ok'
  }
  static get running() {
    return 'running'
  }
  static get error() {
    return 'error'
  }
  static get disabled() {
    return 'disabled'
  }
}

export enum SysJobCode {
  EmRefresh = 'em_refresh',
  LveProcess = 'lve_process',
  LveCf = 'lve_cf',
  PayExportAllPayslips = 'pay_export_all_payslips',
  PayProcess = 'pay_process',
  PayslipPublish = 'payslip_publish',
  ReportPayroll = 'report_payroll',
  ReportLeave = 'report_leave',
  RemindIdExp = 'remind_id_exp',
  RemindConfirmDue = 'remind_confirmation_due',
  RemindRetire = 'remind_retire',
  RemindBirthday = 'remind_birthday',
  RemindLeaveApproval = 'remind_leave_approval',
  RemindClaimApproval = 'remind_claim_approval',
  RemindCertExp = 'remind_cert_exp',
  RemindRetentionExp = 'remind_retention_exp'
}
