import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { AuditLoginViewState, AuditTableViewState, systemPersistConfig, SystemRootState } from './types'

const auditLoginViewAdapter = createEntityAdapter<AuditLoginViewState>({ selectId: model => model.viewId })

const auditLoginViewInitialState = auditLoginViewAdapter.getInitialState()
const auditTableViewAdapter = createEntityAdapter<AuditTableViewState>({ selectId: model => model.viewId })

const auditTableViewInitialState = auditTableViewAdapter.getInitialState()

const initialState: SystemRootState = {
  // auditLogins
  auditLoginsView: auditLoginViewInitialState,
  auditLoginsViewLoading: false,
  auditLoginsViewRefetch: 0,

  // auditTables
  auditTablesView: auditTableViewInitialState,
  auditTablesViewLoading: false,
  auditTablesViewRefetch: 0
}

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    // auditLogins
    refetchAuditLoginsView: state => {
      state.auditLoginsViewRefetch += 1
    },
    setAuditLoginsView: (state, action: PayloadAction<AuditLoginViewState>) => {
      auditLoginViewAdapter.upsertOne(state.auditLoginsView, action.payload)
    },
    setAuditLoginsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.auditLoginsViewLoading = action.payload
    },

    // auditTables
    refetchAuditTablesView: state => {
      state.auditTablesViewRefetch += 1
    },
    setAuditTablesView: (state, action: PayloadAction<AuditTableViewState>) => {
      auditTableViewAdapter.upsertOne(state.auditTablesView, action.payload)
    },
    setAuditTablesViewLoading: (state, action: PayloadAction<boolean>) => {
      state.auditTablesViewLoading = action.payload
    }
  }
})

export const {
  refetchAuditLoginsView,
  setAuditLoginsViewLoading,
  setAuditLoginsView,
  refetchAuditTablesView,
  setAuditTablesViewLoading,
  setAuditTablesView
} = systemSlice.actions

export const systemReducers = { system: persistReducer<SystemRootState>(systemPersistConfig, systemSlice.reducer) }
