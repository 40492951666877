import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { ReviewTemplateState } from '../types'
import { fetchReviewTemplates } from '../actions'
import { selectReviewTemplates } from '../selectors'

export const useReviewTemplates = (fetchStrategy: FetchStrategy = 'always'): [ReviewTemplateState[], boolean] => {
  const reviewTemplates = useSelector(selectReviewTemplates)
  const loading = useSelector((state: StoreState) => state.performance.reviewTemplatesLoading)

  useEffect(() => {
    dispatch(fetchReviewTemplates({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [reviewTemplates, loading]
}
