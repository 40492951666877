import React, { FC, useCallback, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Tab, TabTitle, Tabs } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { PAY_ROUTES } from '~/routes/routes'
import { PayRunState } from '../../types'
import { PayRuns } from '../PayRuns/PayRuns'
import { AddPayRunDrawer } from '../PayRuns/AddPayRunDrawer'
import { PayRunsRecent } from '../PayRunsRecent/PayRunsRecent'
import { CpfOverview } from '../CpfOverview/CpfOverview'
import { refetchCpfSubmissionsView, refetchPayRunsView } from '../../reducers'
import { dispatch } from '~/stores/store'
import './PayOverview.less'

export interface PayOverviewProps {}

interface AddPayRunDrawerState {
  visible: boolean
  defaultValue?: PayRunState
}

const DEFAULT_ADD_PAY_RUN_DRAWER_STATE: AddPayRunDrawerState = { visible: false }

export const PayOverview: FC<PayOverviewProps> = () => {
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()
  const canAdd = usePermissionGate(Permission.payRun, PermissionAction.Add)
  const canViewCpf = usePermissionGate(Permission.cpfSubmission, PermissionAction.View)
  const [addPayRunDrawerState, setAddPayRunDrawerState] = useState<AddPayRunDrawerState>(
    DEFAULT_ADD_PAY_RUN_DRAWER_STATE
  )

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${PAY_ROUTES.tab.replace(':tab?', activeKey)}`)

      if (activeKey === 'run') {
        dispatch(refetchPayRunsView())
      } else if (activeKey === 'cpf') {
        dispatch(refetchCpfSubmissionsView())
      }
    },
    [history]
  )

  const handleAddPayRun = useCallback((payRun?: PayRunState) => {
    setAddPayRunDrawerState({ visible: true, defaultValue: payRun })
  }, [])

  const handleCloseAddPayRunDrawer = useCallback(() => {
    setAddPayRunDrawerState(DEFAULT_ADD_PAY_RUN_DRAWER_STATE)
  }, [])

  const tabItems: Tab[] = useMemo(() => {
    const result: Tab[] = [
      { key: 'recent', label: 'Quick access', children: <PayRunsRecent /> },
      { key: 'run', label: 'Payroll runs', children: <PayRuns /> }
    ]

    if (canViewCpf) {
      result.push({ key: 'cpf', label: 'CPF submissions', children: <CpfOverview /> })
    }
    return result
  }, [canViewCpf])

  return (
    <div className="pay-overview">
      <DocumentTitle title="Payroll Overview" />
      <div className="pay-overview__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'pay_run'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle title="Payroll overview" />,
            right: canAdd && (
              <Button key="add" size="small" onClick={() => handleAddPayRun()}>
                Add payroll run
              </Button>
            )
          }}
          items={tabItems}
        />
      </div>
      {canAdd && <AddPayRunDrawer {...addPayRunDrawerState} onClose={handleCloseAddPayRunDrawer} />}
    </div>
  )
}
