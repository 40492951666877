import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchReviewRatingScaleTemplates } from '../actions'
import { ReviewRatingScaleTemplateState } from '../types'
import { selectReviewRatingScaleTemplates } from '../selectors'

export const useReviewRatingScaleTemplates = (
  templateId: string,
  fetchStrategy: FetchStrategy = 'always'
): [ReviewRatingScaleTemplateState[], boolean] => {
  const data = useSelector(selectReviewRatingScaleTemplates)(templateId)
  const loading = useSelector((state: StoreState) => state.attendance.breakTimesLoading[templateId])

  useEffect(() => {
    dispatch(fetchReviewRatingScaleTemplates(templateId, { strategy: fetchStrategy }))
  }, [templateId, fetchStrategy])

  return [data, loading]
}
