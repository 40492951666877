import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Form, Input } from '~/core-components'
import { Col, Row } from '~/components'
import { Errors } from '~/types/store'

interface ConfigSecretCommonProps {
  configSecretJsonb: string
  onChange: (value: string) => void
  errors?: Errors
}

interface FormData {
  clientSecret: string
}

const EMPTY_FORM_DATA: FormData = {
  clientSecret: ''
}

enum SecretType {
  ClientSecret = 'clientSecret'
}

const DEFAULT_SECRET_TYPE: Record<SecretType, boolean> = {
  [SecretType.ClientSecret]: false
}

export const ConfigSecretCommon: FC<ConfigSecretCommonProps> = ({ configSecretJsonb, onChange, errors }) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [visible, setVisible] = useState<Record<SecretType, boolean>>(DEFAULT_SECRET_TYPE)

  useEffect(() => {
    const data = JSON.parse(configSecretJsonb || '{}') as FormData
    setFormData({
      clientSecret: data.clientSecret
    })
    setVisible(DEFAULT_SECRET_TYPE)
  }, [configSecretJsonb])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        const value = JSON.stringify({
          clientSecret: updated.clientSecret
        })

        typeof onChange === 'function' && onChange(value)
        return updated
      })
    },
    [onChange]
  )

  return (
    <div className="config-secret-common">
      <Row>
        <Col span={24}>
          <Form.Item
            label="Client secret"
            validateStatus={errors?.clientSecret ? 'error' : ''}
            help={errors?.clientSecret}
          >
            <Input
              value={formData.clientSecret}
              type={visible[SecretType.ClientSecret] ? 'text' : 'password'}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ clientSecret: event.target.value })
              }
              suffix={
                <Button
                  type="text"
                  icon={visible[SecretType.ClientSecret] ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  onClick={() =>
                    setVisible({ ...visible, [SecretType.ClientSecret]: !visible[SecretType.ClientSecret] })
                  }
                />
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
