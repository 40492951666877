import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { QuestionInfoState, QuestionState } from '../types'

const baseUrl = getBaseUrl('/performance/question')

export const apiGetQuestions = () => request<QuestionState[]>('get', baseUrl)

export const apiGetQuestion = (id: string) => request<QuestionState>('get', `${baseUrl}/${id}`)

export const apiAddQuestion = (question: QuestionInfoState) => request<{ id: string }>('post', baseUrl, question)

export const apiUpdateQuestion = (id: string, question: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, question)

export const apiDeleteQuestion = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
