import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { WarningOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { Card, CardProps, Tooltip } from '~/core-components'
import { Col, NewTabLink, Row } from '~/components'
import { fetchCompanies, selectCompaniesCpfNo } from '~/features/master'
import { usePermissionGate } from '~/features/iam'
import { PAY_ROUTES } from '~/routes/routes'
import { Permission } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectPayRunById } from '../../reducers'
import './CpfFileCard.less'

export interface CpfFileCardProps extends CardProps {
  payRunId: string
}

export const CpfFileCard: FC<CpfFileCardProps> = ({ payRunId, className, ...props }) => {
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const companiesCpfNo = useSelector(selectCompaniesCpfNo)()
  const isEmpty = !companiesCpfNo || companiesCpfNo.length === 0 || !companiesCpfNo[0].cpfNo
  const canView = usePermissionGate(Permission.cpfSubmission)

  useEffect(() => {
    dispatch(fetchCompanies())
  }, [])

  if (!canView) return null

  return (
    <Card className={classNames('cpf-file-card', className)} {...props}>
      <Row>
        <Col>
          <div className="cpf-file-card__title">
            <b>Statutory</b>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {isEmpty ? (
            <Tooltip title="Company CPF submission no. is not defined. Go to Settings > Company > Statutory and update it accordingly.">
              <Row>
                <Col flex="auto">CPF EZPay</Col>
                <Col flex="none" className="cpf-file-card__warning">
                  <WarningOutlined />
                </Col>
              </Row>
            </Tooltip>
          ) : (
            <NewTabLink
              path={`${PAY_ROUTES.tab.replace(':tab?', 'cpf')}?month=${payRun?.payPeriod || ''}`}
              tooltipText="Open CPF file generation in a new tab"
            >
              CPF EZPay
            </NewTabLink>
          )}
        </Col>
      </Row>
    </Card>
  )
}
