import { setIr8aForm, setIr8aFormUpdating } from '../reducers'
import { apiUpdateIr8aDraft } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { showSuccess, showError } from '~/utils'
import { Ir8aFormState, IYtdUpdateIr8aDraft } from '../types'

export const updateIr8aDraft = (request: Ir8aFormState): ThunkResult<void> => {
  return async dispatch => {
    const updateData = mapToDto(request)

    try {
      dispatch(setIr8aFormUpdating(true))
      const { status, errors, message, errorData } = await apiUpdateIr8aDraft(updateData)
      if (status) {
        dispatch(setIr8aForm(request))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setIr8aFormUpdating(false))
    }
  }
}

const mapToDto = (ir8aForm: Ir8aFormState): IYtdUpdateIr8aDraft => {
  const updateForm: IYtdUpdateIr8aDraft = {
    id: ir8aForm.id,
    ytdSubmissionId: ir8aForm.ytdSubmissionId,
    employeeTaxNo: ir8aForm.employeeTaxNo,
    bonusDate: ir8aForm.bonusDate,
    commissionType: ir8aForm.commissionType,
    commissionStartDate: ir8aForm.commissionStartDate,
    commissionEndDate: ir8aForm.commissionEndDate,
    directorFeeDate: ir8aForm.directorFeeDate,
    s45Indicator: ir8aForm.s45Indicator,
    compensationApproval: ir8aForm.compensationApproval,
    compensationApprovalDate: ir8aForm.compensationApprovalDate,
    taxBorneBy: ir8aForm.taxBorneBy,
    taxBorneAmt: ir8aForm.taxBorneAmt,
    lumpsumReason: ir8aForm.lumpsumReason,
    lumpsumBasis: ir8aForm.lumpsumBasis,
    overseasPosting: ir8aForm.overseasPosting,
    cpfErOvsWithTaxName: ir8aForm.cpfErOvsWithTaxName,
    cpfErOvsWithTaxAmt: ir8aForm.cpfErOvsWithTaxAmt,
    cpfErOvsWithTaxMandatory: ir8aForm.cpfErOvsWithTaxMandatory,
    cpfErOvsWithTaxClaimed: ir8aForm.cpfErOvsWithTaxClaimed,
    pensionFundName: ir8aForm.pensionFundName,
    providentFundName: ir8aForm.providentFundName,
    exemptIncomeType: ir8aForm.exemptIncomeType,
    exemptIncomeAmt: ir8aForm.exemptIncomeAmt,
    cpfEmAdj: ir8aForm.cpfEmAdj,
    cpfErAdj: ir8aForm.cpfErAdj,
    cpfErOverseasAdj: ir8aForm.cpfErOverseasAdj,
    cpfErVoluntaryAdj: ir8aForm.cpfErVoluntaryAdj,
    fundCdacAdj: ir8aForm.fundCdacAdj,
    fundComchestAdj: ir8aForm.fundComchestAdj,
    fundEcfAdj: ir8aForm.fundEcfAdj,
    fundMbfAdj: ir8aForm.fundMbfAdj,
    fundMendakiAdj: ir8aForm.fundMendakiAdj,
    fundOtherAdj: ir8aForm.fundOtherAdj,
    fundSindaAdj: ir8aForm.fundSindaAdj,
    grossAdj: ir8aForm.grossAdj,
    bonusAdj: ir8aForm.bonusAdj,
    commissionAdj: ir8aForm.commissionAdj,
    compensationAdj: ir8aForm.compensationAdj,
    directorFeeAdj: ir8aForm.directorFeeAdj,
    entertainmentAdj: ir8aForm.entertainmentAdj,
    exgratiaAdj: ir8aForm.exgratiaAdj,
    gratuityAdj: ir8aForm.gratuityAdj,
    insuranceAdj: ir8aForm.insuranceAdj,
    noticeAdj: ir8aForm.noticeAdj,
    otherAdj: ir8aForm.otherAdj,
    otherLumpsumAdj: ir8aForm.otherLumpsumAdj,
    pensionAdj: ir8aForm.pensionAdj,
    retirementAdj: ir8aForm.retirementAdj,
    retirement2Adj: ir8aForm.retirement2Adj,
    transportAdj: ir8aForm.transportAdj
  }

  return updateForm
}
