import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveRating } from '../api/rating.api'
import { ISaveRating, RatingState } from '../types'
import { setRating } from '../reducers'
import { fetchRatingScales } from './fetch-rating-scales'
import pick from 'lodash/pick'

export const saveRating = (request: ISaveRating): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const { status, result, errors, message, errorData } = await apiSaveRating(request)
    if (status) {
      const ratingId = result?.id || request.id
      if (ratingId) {
        dispatch(fetchRatingScales(ratingId))
      }
      const rating = getState().performance.ratings?.entities[ratingId || '']
      if (rating) {
        const updated: RatingState = {
          ...(rating as RatingState),
          ...request
        }

        dispatch(setRating(updated))
      } else {
        const newRating: RatingState = {
          ...(pick(request, 'name', 'inactiveDate') as RatingState),
          ...result
        }

        dispatch(setRating(newRating))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
