import React, { FC } from 'react'
import { DocumentTitle } from '~/components'
import { PageHeader } from '~/core-components'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { PasswordConfigs } from './PasswordConfigs/PasswordConfigs'
import { IdentityProviders } from './IdentityProviders/IdentityProviders'
import './SecurityConfigs.less'

interface SecurityConfigsProps {}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Security'
  }
]

export const SecurityConfigs: FC<SecurityConfigsProps> = () => {
  return (
    <div id="security-configs" className="security-configs">
      <DocumentTitle title="Security" />
      <PageHeader title="Security" containerId="security-configs" breadcrumb={{ routes }} />
      <div className="security-configs__body">
        <PasswordConfigs />
        <IdentityProviders />
      </div>
    </div>
  )
}
