import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiGetCpfPaygroups } from '../api/cpf-submission.api'

export const fetchCpfPayGroups = (cpfNo: string): ThunkResult<any> => {
  return async () => {
    if (!cpfNo) return

    const { status, result, errors, message, errorData } = await apiGetCpfPaygroups({ cpfNo })
    if (status) {
      return { result }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }

    return { errors }
  }
}
