import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Button, Form, Link, Select } from '~/core-components'
import { AttKeyValues, Col, EditableCard, EditableCardState, InactiveTag, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { emptyGuid, PayItemCategory, Permission, PermissionAction } from '~/constants'
import { PayItemName } from '~/features/master'
import { usePayDailyMaps } from '~/features/payroll'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { isInactive } from '~/utils'
import { updatePayDailyMaps } from '../../../actions'
import { selectPayItems } from '../../../selectors'
import { PayDailyMapState } from '../../../types'
import './PayDailyMap.less'

export interface PayDailyMapProps {
  payGroupId: string
}

const RELEVANT_ERRORS = ['dailyField', 'dailyMonth', 'calendarChange', 'salaryChange', 'payItemId']

interface FormData {
  daily: PayDailyMapState[]
}

const EMPTY_FORM_DATA: FormData = {
  daily: []
}

const PAY_ITEM_CATEGORIES = [PayItemCategory.basicPay, PayItemCategory.others, PayItemCategory.overtime]

export const PayDailyMap: FC<PayDailyMapProps> = ({ payGroupId }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'
  const [errors, setErrors] = useState<Errors>()
  const relevantErrors = Object.keys(errors || {}).filter(e => RELEVANT_ERRORS.includes(e))
  const [payDailyMaps] = usePayDailyMaps(payGroupId)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  const additionPayItems = useSelector(selectPayItems).filter(
    pi =>
      (!isInactive(pi?.inactiveDate) || payDailyMaps.filter(pcm => pcm.payItemId === pi?.id).length > 0) &&
      PAY_ITEM_CATEGORIES.includes(pi?.category)
  )

  const NEW_PAY_DAILY_MAP: PayDailyMapState[] = [
    {
      id: emptyGuid,
      payGroupId: payGroupId,
      dailyField: '',
      dailyMonth: '',
      calendarChange: '',
      salaryChange: '',
      payItemId: ''
    }
  ]

  const mapToState = (formData: FormData): PayDailyMapState[] => {
    return [...formData.daily]
  }

  useEffect(() => {
    if (payDailyMaps.length > 0) {
      setFormData(mapToFormData(payDailyMaps))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payDailyMaps])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    if (payGroupId) {
      setCardState('saving')
      setErrors(undefined)

      let result: ActionResult | undefined
      try {
        result = await dispatch(updatePayDailyMaps(payGroupId, mapToState(formData)))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [payGroupId, formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)

    if (payDailyMaps.length > 0) {
      setFormData(mapToFormData(payDailyMaps))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payDailyMaps])

  return (
    <EditableCard
      className="pay-daily-map"
      title="Attendance"
      bodyStyle={{ paddingBottom: 6, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          {errors && relevantErrors.length > 0 && (
            <Alert
              className="pay-daily-map__error"
              type="error"
              message={
                <>
                  {relevantErrors.map((error, index) => (
                    <div key={index}>{errors[error]}</div>
                  ))}
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {formData.daily.map((ctk, index) => (
            <Row gutter={6} key={index} className="pay-daily-map__row">
              <Col span={6}>
                {index === 0 && <div className="pay-daily-map__row-title">Daily field</div>}
                <AttKeyValues
                  id="sysDailyField"
                  value={ctk.dailyField}
                  allowClear={false}
                  readOnly={readOnly}
                  dropdownMatchSelectWidth={false}
                  onChange={(dailyField: string) =>
                    handleFormDataChange({
                      daily: [
                        ...formData.daily.slice(0, index),
                        { ...formData.daily[index], dailyField },
                        ...formData.daily.slice(index + 1, formData.daily.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={4}>
                {index === 0 && <div className="pay-daily-map__row-title">Month</div>}
                <SysOptions
                  type="att_daily_month"
                  value={ctk.dailyMonth}
                  readOnly={readOnly}
                  allowClear={false}
                  dropdownMatchSelectWidth={false}
                  onChange={(dailyMonth: string) =>
                    handleFormDataChange({
                      daily: [
                        ...formData.daily.slice(0, index),
                        { ...formData.daily[index], dailyMonth },
                        ...formData.daily.slice(index + 1, formData.daily.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={3}>
                {index === 0 && <div className="pay-daily-map__row-title">Calendar</div>}
                <SysOptions
                  type="yes_no_yn"
                  value={ctk.calendarChange}
                  readOnly={readOnly}
                  dropdownMatchSelectWidth={false}
                  onChange={(calendarChange: string) =>
                    handleFormDataChange({
                      daily: [
                        ...formData.daily.slice(0, index),
                        { ...formData.daily[index], calendarChange },
                        ...formData.daily.slice(index + 1, formData.daily.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={3}>
                {index === 0 && <div className="pay-daily-map__row-title">Salary</div>}
                <SysOptions
                  type="yes_no_yn"
                  value={ctk.salaryChange}
                  readOnly={readOnly}
                  dropdownMatchSelectWidth={false}
                  onChange={(salaryChange: string) =>
                    handleFormDataChange({
                      daily: [
                        ...formData.daily.slice(0, index),
                        { ...formData.daily[index], salaryChange },
                        ...formData.daily.slice(index + 1, formData.daily.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={7}>
                {index === 0 && <div className="pay-daily-map__row-title">Payroll item</div>}
                <Select
                  id="payItem"
                  value={ctk.payItemId}
                  readOnly={readOnly}
                  showSearch
                  allowClear={false}
                  optionFilterProp="title"
                  dropdownMatchSelectWidth={false}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      daily: [
                        ...formData.daily.slice(0, index),
                        { ...formData.daily[index], payItemId: value },
                        ...formData.daily.slice(index + 1, formData.daily.length)
                      ]
                    })
                  }
                >
                  {additionPayItems.map(api => {
                    return (
                      <Select.Option key={api?.id} value={api?.id || ''} title={api?.name}>
                        {isInactive(api!.inactiveDate) && <InactiveTag />}
                        <PayItemName id={api!.id} />
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
              <Col span={1} style={{ whiteSpace: 'nowrap' }}>
                <Button
                  size="small"
                  type="text"
                  icon={<i className="fal fa-xmark" />}
                  hidden={readOnly}
                  onClick={() =>
                    handleFormDataChange({
                      daily: [
                        ...formData.daily.slice(0, index),
                        ...formData.daily.slice(index + 1, formData.daily.length)
                      ]
                    })
                  }
                />
              </Col>
            </Row>
          ))}
          <Form.Item hidden={readOnly}>
            <Link onClick={() => handleFormDataChange({ daily: [...formData.daily, ...NEW_PAY_DAILY_MAP] })}>
              add more
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}

const mapToFormData = (dataTakens: PayDailyMapState[]): FormData => ({
  daily: dataTakens
})
