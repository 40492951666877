export enum FeatureFlag {
  Payroll = 'payroll',
  Leave = 'leave',
  Claim = 'claim',
  Schedule = 'schedule',
  Attendance = 'attendance',
  Performance = 'performance',
  MyProfile = 'myprofile',
  MyPayroll = 'mypayroll',
  MyLeave = 'myleave',
  MyClaim = 'myclaim',
  MySchedule = 'myschedule',
  MyAttendance = 'myattendance'
}
