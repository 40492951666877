import { RouteProps } from 'react-router-dom'
import { MenuItem } from '~/types/common'
import { Permission } from '~/constants'
import { SYS_ROUTES } from '~/routes/routes'
import { AuditLogins } from './containers/AuditLogins/AuditLogins'
import { AuditTables } from './containers/AuditTables/AuditTables'

export const auditLogMenus: MenuItem[] = [
  {
    value: 'User access log',
    path: SYS_ROUTES.auditLogin,
    sysPermissionId: Permission.auditLogin
  },
  {
    value: 'Data update log',
    path: SYS_ROUTES.auditTable,
    sysPermissionId: Permission.auditTable
  }
]

export const auditLogRoutes: RouteProps[] = [
  {
    path: SYS_ROUTES.auditLogin,
    component: AuditLogins,
    exact: true
  },
  {
    path: SYS_ROUTES.auditTable,
    component: AuditTables,
    exact: true
  }
]
