import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { attendanceMenus, useMasterMenu } from '~/features/master'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { MenuItem } from '~/types/common'
import { useReviewTemplate } from '../../hooks'
import { deleteReviewTemplate } from '../../actions'
import { selectReviewTemplates } from '../../selectors'
import { AddReviewTemplateDrawer } from './components/AddReviewTemplateDrawer'
import { ReviewTemplateCard } from './components/ReviewTemplateCard'
import { ReviewQuestionTemplateCard } from './components/ReviewQuestionTemplateCard'
import './ReviewTemplate.less'

interface ReviewTemplateProps {}

interface ReviewTemplateParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.reviewTemplates,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Review template'
  }
]

export const ReviewTemplate: FC<ReviewTemplateProps> = () => {
  const { id } = useParams<ReviewTemplateParams>()
  const templates = useSelector(selectReviewTemplates)
  const [template] = useReviewTemplate(id)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const canModify = usePermissionGate(Permission.reviewTemplate, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const { setMenus } = useMasterMenu()

  const handleAddReviewTemplate = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = templates.map(s => ({
      path: SETTINGS_ROUTES.reviewTemplate.replace(':id', s!.id),
      value: s!.name,
      sysPermissionId: Permission.reviewTemplate
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddReviewTemplate} block>
            Add review template
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(attendanceMenus)
    }
  }, [setMenus, templates, canModify, handleAddReviewTemplate])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && template) {
      confirm({
        title: 'Delete review template',
        content: `Do you want to delete review template "${template?.name}"?`,
        onOk: () => {
          dispatch(deleteReviewTemplate(id))
          history.push(SETTINGS_ROUTES.reviewTemplates)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, template, history])

  return (
    <div id="review-template" className="review-template">
      <DocumentTitle title="Review Template" />
      <PageHeader
        title="Review template"
        containerId="review-template"
        breadcrumb={{ routes }}
        extra={
          canModify &&
          editing && (
            <Button key="add" onClick={handleDelete}>
              Delete
            </Button>
          )
        }
      />
      <div className="review-template__body">
        <ReviewTemplateCard template={template} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        <ReviewQuestionTemplateCard template={template} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        {canModify && <AddReviewTemplateDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
