import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchRatingScales } from '../actions'
import { RatingScaleState } from '../types'
import { selectRatingScales } from '../selectors'

export const useRatingScales = (
  ratingId: string,
  fetchStrategy: FetchStrategy = 'always'
): [RatingScaleState[], boolean] => {
  const data = useSelector(selectRatingScales)(ratingId)
  const loading = useSelector((state: StoreState) => state.attendance.breakTimesLoading[ratingId])

  useEffect(() => {
    dispatch(fetchRatingScales(ratingId, { strategy: fetchStrategy }))
  }, [ratingId, fetchStrategy])

  return [data, loading]
}
