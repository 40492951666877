import { CSSProperties, FC } from 'react'
import { Tag, TagType } from '~/core-components'
import { CpfSubmissionStatus } from '~/constants'

export interface CpfSubmissionStatusTagProps {
  status: string
}

const lowercase: CSSProperties = { textTransform: 'lowercase' }

export const CpfSubmissionStatusTag: FC<CpfSubmissionStatusTagProps> = ({ status }) => {
  let tagType: TagType = 'neutral',
    label = ''
  switch (status) {
    case CpfSubmissionStatus.submitted:
      tagType = 'success'
      label = 'submitted'
      break
    case CpfSubmissionStatus.failed:
      tagType = 'danger'
      label = 'failed'
      break
  }

  if (!label) return null

  return (
    <Tag type={tagType} style={lowercase}>
      {label}
    </Tag>
  )
}
