import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, Row, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { addEmFamily, deleteEmFamily, updateEmFamily } from '../../../actions'
import { EmFamilyInfoState, EmFamilyState } from '../../../types'
import { mapEmFamilyStateToEmFamilyInfo, mapEmFamilyInfoStateToEmFamilyInfo } from '../../../types/em-details.mapper'
import { isDependant } from '../../../util'
import { FamilyRelation } from '~/constants'
import { ActionResult, Errors } from '~/types/store'
import { useSysOptions } from '~/features/employee/hooks'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'

export interface MutateEmFamilyDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmFamilyState
  readOnly?: boolean
  onClose: () => void
}

const EMPTY_FORM_DATA: EmFamilyInfoState = {
  name: '',
  relation: '',
  gender: '',
  birthDate: '',
  nationalityId: '',
  identityType: '',
  identityNo: '',
  phone: '',
  dependant: false,
  firstCclDate: ''
}

export const MutateEmFamilyDrawer: FC<MutateEmFamilyDrawerProps> = ({
  visible,
  employeeId,
  data,
  readOnly,
  onClose
}: MutateEmFamilyDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<EmFamilyInfoState>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [identityTypes] = useSysOptions('identity_type')
  const [relationships] = useSysOptions('relationship')
  const [genders] = useSysOptions('gender')
  const nationalities = useSelector(selectKeyvaluesById)('nationality')
  const [yesNo] = useSysOptions('yes_no')

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)

    if (visible) dispatch(fetchEmpKeyvalues('nationality', undefined, undefined, { strategy: 'when-empty' }))
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const {
        name,
        relation,
        gender,
        birthDate,
        nationalityId,
        identityType,
        identityNo,
        phone,
        dependant,
        firstCclDate
      } = data
      setFormData({
        name,
        relation,
        gender,
        birthDate,
        nationalityId,
        identityType,
        identityNo,
        phone,
        dependant,
        firstCclDate
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(
          updateEmFamily(
            data.employeeId,
            data.id,
            mapEmFamilyStateToEmFamilyInfo(data),
            mapEmFamilyInfoStateToEmFamilyInfo(formData)
          )
        )
      } else if (employeeId) {
        result = await dispatch(addEmFamily(employeeId, mapEmFamilyInfoStateToEmFamilyInfo(formData)))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeId, data, formData, onClose])

  const handleDelete = useCallback(
    (emFamily: EmFamilyState | undefined) => {
      if (employeeId && emFamily) {
        const { id, name } = emFamily
        confirm({
          title: 'Delete family member',
          content: `Do you want to delete family member record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmFamily(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? (readOnly ? 'Family member' : 'Edit family member') : 'Add family member'}
      okText={!readOnly ? 'Save' : 'Close'}
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      showDelete={data && !readOnly ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-family"
    >
      <Form id="form-em-family" onFinish={!readOnly ? handleOk : onClose}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name (as per IC)">
              {readOnly ? (
                formData.name
              ) : (
                <Input
                  value={formData.name}
                  ref={focusRef}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ name: event.target.value })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Relationship">
              {readOnly ? (
                relationships[formData.relation]?.value
              ) : (
                <SysOptions
                  type="relationship"
                  value={formData.relation}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      relation: value,
                      firstCclDate: value === FamilyRelation.child ? formData.firstCclDate : undefined,
                      ...{ dependant: isDependant(value) }
                    })
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Gender">
              {readOnly ? (
                genders[formData.gender]?.value
              ) : (
                <SysOptions
                  type="gender"
                  value={formData.gender}
                  onChange={(value: string) => handleFormDataChange({ gender: value })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Date of birth">
              {readOnly ? (
                formData.birthDate ? (
                  moment(formData.birthDate).format('DD MMM YYYY')
                ) : (
                  '-'
                )
              ) : (
                <Input.Date
                  value={formData.birthDate ? moment(formData.birthDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ birthDate: value?.format('YYYY-MM-DD') })
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Nationality">
              {readOnly ? (
                nationalities[formData.nationalityId]?.value
              ) : (
                <EmpKeyValues
                  id="nationality"
                  controller="nationality"
                  value={formData.nationalityId}
                  onChange={(value: string) => handleFormDataChange({ nationalityId: value })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              label="Identity type"
              validateStatus={errors?.identityType ? 'error' : ''}
              help={errors?.identityType}
            >
              {readOnly ? (
                identityTypes[formData.identityType]?.value
              ) : (
                <SysOptions
                  type="identity_type"
                  value={formData.identityType}
                  onChange={(value: string) => handleFormDataChange({ identityType: value })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Identity no."
              validateStatus={errors?.identityNo ? 'error' : ''}
              help={errors?.identityNo}
            >
              {readOnly ? (
                formData.identityNo?.toUpperCase()
              ) : (
                <Input
                  value={formData.identityNo}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ identityNo: event.target.value.toUpperCase() })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Phone">
              {readOnly ? (
                formData.phone
              ) : (
                <Input
                  value={formData.phone}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ phone: event.target.value })
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Dependant?">
              {readOnly ? (
                yesNo[formData.dependant?.toString()]?.value
              ) : (
                <SysOptions
                  type="yes_no"
                  value={formData.dependant?.toString()}
                  onChange={(value: string) => handleFormDataChange({ dependant: value })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={formData.relation !== FamilyRelation.child}>
          <Col span={12}>
            <Form.Item label="First child care leave">
              {readOnly ? (
                formData.firstCclDate ? (
                  moment(formData.firstCclDate).format('DD MMM YYYY')
                ) : (
                  '-'
                )
              ) : (
                <Input.Date
                  value={formData.firstCclDate ? moment(formData.firstCclDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ firstCclDate: value?.format('YYYY-MM-DD') })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
