import { request, getBaseUrl } from '~/utils/request'
import {
  ReviewTemplateState,
  ISaveReviewTemplate,
  ReviewQuestionTemplateState,
  ReviewRatingScaleTemplateState
} from '../types'

const baseUrl = getBaseUrl('/performance/reviewtemplate')

export const apiGetReviewTemplates = () => request<ReviewTemplateState[]>('get', baseUrl)

export const apiGetReviewTemplate = (id: string) => request<ReviewTemplateState>('get', `${baseUrl}/${id}`)

export const apiSaveReviewTemplate = (template: ISaveReviewTemplate) =>
  request<{ id: string }>('post', baseUrl, template)

export const apiDeleteReviewTemplate = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetReviewRatingScaleTemplates = (reviewTemplateId: string) =>
  request<ReviewRatingScaleTemplateState[]>('get', `${baseUrl}/${reviewTemplateId}/scale`)

export const apiGetReviewQuestionTemplates = (reviewTemplateId: string) =>
  request<ReviewQuestionTemplateState[]>('get', `${baseUrl}/${reviewTemplateId}/question`)

export const apiSaveReviewQuestionTemplate = (reviewTemplateId: string, question: ReviewQuestionTemplateState) =>
  request<{ id: string }>('post', `${baseUrl}/${reviewTemplateId}/question`, question)

export const apiDeleteReviewQuestionTemplate = (reviewTemplateId: string, id: string) =>
  request<void>('delete', `${baseUrl}/${reviewTemplateId}/question/${id}`)
