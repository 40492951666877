import { replaceQuickBooksConnections, setQuickBooksConnectionsLoading } from '../reducers'
import { apiGetQuickBooksConnections } from '../api/quick-books.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchQuickBooksConnections = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().payroll.quickBooksConnectionsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.quickBooksConnections?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setQuickBooksConnectionsLoading(true))
      const { result, status } = await apiGetQuickBooksConnections()
      if (status) {
        dispatch(replaceQuickBooksConnections(result))
      }
    } finally {
      dispatch(setQuickBooksConnectionsLoading(false))
    }
  }
}
