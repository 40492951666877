import React, { FC, useCallback, useEffect, useState } from 'react'
import { ActionGroup, Button } from '~/core-components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { useQuery } from '~/hooks/use-query'
import { YtdSubmissionState } from '../../types/'
import { IrasSubmissionDrawer } from './IrasSubmissionDrawer'
import { useHistory, useLocation } from 'react-router-dom'

interface IrasActionsProps {
  ytdSubmission?: YtdSubmissionState
}

export type IrasSubmitType = 'file' | 'validate' | 'submission'

interface SubmitDrawerState {
  visible: boolean
  ytdSubmission?: YtdSubmissionState
  type: IrasSubmitType
}

const DEFAULT_SUBMIT_DRAWER_STATE: SubmitDrawerState = { visible: false, ytdSubmission: undefined, type: 'validate' }

export const IrasActions: FC<IrasActionsProps> = ({ ytdSubmission }) => {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const showAction = query.get('action') === '1'
  const canModify = usePermissionGate(Permission.ytd, PermissionAction.Modify)
  const [submitDrawerState, setSubmitDrawerState] = useState<SubmitDrawerState>(DEFAULT_SUBMIT_DRAWER_STATE)

  useEffect(() => {
    if (showAction) {
      setSubmitDrawerState({ visible: true, ytdSubmission, type: 'submission' })
    }
  }, [showAction, ytdSubmission])

  const handleSubmit = useCallback(async () => {
    setSubmitDrawerState({ visible: true, ytdSubmission, type: 'submission' })
  }, [ytdSubmission])

  const handleValidate = useCallback(async () => {
    setSubmitDrawerState({ visible: true, ytdSubmission, type: 'validate' })
  }, [ytdSubmission])

  const handleDownloadFile = useCallback(async () => {
    setSubmitDrawerState({ visible: true, ytdSubmission, type: 'file' })
  }, [ytdSubmission])

  const handleCloseSubmitDrawer = useCallback(() => {
    setSubmitDrawerState(DEFAULT_SUBMIT_DRAWER_STATE)
    history.replace(location.pathname)
  }, [history, location.pathname])

  if (!canModify) return null

  return (
    <div className="iras">
      <ActionGroup>
        <Button onClick={handleDownloadFile}>Download file...</Button>
        <Button onClick={handleValidate}>Validate...</Button>
        <Button type="primary" onClick={handleSubmit}>
          Submit to IRAS...
        </Button>
        <IrasSubmissionDrawer {...submitDrawerState} onClose={handleCloseSubmitDrawer} />
      </ActionGroup>
    </div>
  )
}
