import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Card, ColumnsType, Link, Space, Table, Tag, Tooltip } from '~/core-components'
import { usePermissionGate } from '~/features/iam/hooks'
import { maskString } from '~/features/employee/util'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MutateEmIdentityDrawer } from './MutateEmIdentityDrawer'
import { EmIdentityState } from '../../../types'
import { fetchEmIdentities } from '../../../actions'
import { selectEmIdentities } from '../../../selectors'
import { useEmIdentityRequests, useSysOptions } from '../../../hooks'
import { EmIdentityRequestsDrawer } from './EmIdentityRequest/EmIdentityRequestsDrawer'

interface EmIdentitiesProps {
  employeeId?: string
  id?: string
}

type EmIdentityTable = EmIdentityState

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmIdentityState
  readOnly?: boolean
}

interface RequestDrawerState {
  employeeId?: string
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const DEFAULT_REQUEST_DRAWER_STATE: RequestDrawerState = { visible: false }
const TODAY = moment().format('YYYY-MM-DD')

const cardStyle = { margin: 24 }
const cardBodyStyle = { padding: 0 }
const btnPendingStyle: CSSProperties = {
  backgroundColor: '#ff8500',
  borderColor: '#ff8500',
  color: '#ffffff',
  marginRight: 30
}

export const EmIdentities: FC<EmIdentitiesProps> = ({ employeeId, id }: EmIdentitiesProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [showExpired, toggleShowExpired] = useToggle()
  const loading = useSelector((state: StoreState) => state.employee.emIdentitiesLoading[employeeId || ''])
  const emIdentities = useSelector(selectEmIdentities)(employeeId)
  const [identityTypes] = useSysOptions('identity_type')
  const canView = usePermissionGate(Permission.employeeIdentity)
  const canModify = usePermissionGate(Permission.employeeIdentity, PermissionAction.Modify)
  const readOnly = canView && !canModify
  const [emIdentityRequests, emIdentityRequestLoading] = useEmIdentityRequests(employeeId || '', inView)
  const [requestDrawerState, setRequestDrawerState] = useState<RequestDrawerState>(DEFAULT_REQUEST_DRAWER_STATE)

  useEffect(() => {
    if (inView && employeeId) dispatch(fetchEmIdentities(employeeId))
  }, [inView, employeeId])

  const handleAddIdentity = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId, setDrawerState])

  const handleEditIdentity = useCallback(
    (emIdentity: EmIdentityState) => {
      setDrawerState({ visible: true, employeeId, data: emIdentity, readOnly })
    },
    [employeeId, readOnly, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const dataSource = useMemo(() => {
    return emIdentities.filter(emi =>
      showExpired ? true : (emi?.cancelledDate || emi?.expiryDate || '9999-12-31') >= TODAY
    )
  }, [emIdentities, showExpired])

  const hasExpired = useMemo(() => {
    return emIdentities.filter(emi => (emi?.cancelledDate || emi?.expiryDate || '9999-12-31') < TODAY).length > 0
  }, [emIdentities])

  const isAllExpired = useMemo(() => {
    return (
      emIdentities.filter(emi => (emi?.cancelledDate || emi?.expiryDate || '9999-12-31') < TODAY).length ===
      emIdentities.length
    )
  }, [emIdentities])

  if (isAllExpired && !showExpired) {
    toggleShowExpired()
  }

  const columns: ColumnsType<EmIdentityTable> = [
    {
      title: 'Type',
      key: 'identityType',
      dataIndex: 'identityType',
      render: (value: string, record) => (
        <>
          {identityTypes[value]?.value} {record.id === id ? <Tag type="primary">main</Tag> : ''}
        </>
      )
    },
    {
      title: 'Identity no.',
      key: 'identityNo',
      dataIndex: 'identityNo',
      render: (value: string) => (value === 'REDACTED' ? value : <Tooltip title={value}>{maskString(value)}</Tooltip>)
    },
    {
      title: 'Issue date',
      key: 'issueDate',
      dataIndex: 'issueDate',
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Cancelled / expiry date',
      key: 'expiryDate',
      dataIndex: 'expiryDate',
      render: (value: string, record) => {
        const valueDate = record.cancelledDate ? record.cancelledDate : value
        return valueDate && moment(valueDate).format('DD MMM YYYY')
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleEditIdentity(record)}>
          {readOnly ? 'view' : 'edit'}
        </Link>
      )
    }
  ]

  const handleOpenRequestDrawer = useCallback(() => {
    setRequestDrawerState({ employeeId, visible: true })
  }, [employeeId])

  const handleCloseRequestDrawer = useCallback(() => {
    setRequestDrawerState(DEFAULT_REQUEST_DRAWER_STATE)
  }, [])

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Personal identification"
        extra={
          employeeId &&
          canModify && (
            <Space>
              {emIdentityRequests.length > 0 && (
                <Button
                  style={btnPendingStyle}
                  size="small"
                  onClick={handleOpenRequestDrawer}
                  loading={emIdentityRequestLoading}
                >
                  {emIdentityRequests.length > 1 ? `${emIdentityRequests.length} pending requests` : 'pending request'}
                </Button>
              )}
              <Link onClick={handleAddIdentity}>add</Link>
            </Space>
          )
        }
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
      >
        <Table
          rowKey="id"
          dataSource={dataSource}
          pagination={false}
          columns={columns}
          loading={loading}
          {...(hasExpired && !isAllExpired ? { showMore: showExpired, onShowMoreToggle: toggleShowExpired } : {})}
        />
        <MutateEmIdentityDrawer {...drawerState} onClose={handleCloseDrawer} />
        {canModify && emIdentityRequests.length > 0 && (
          <EmIdentityRequestsDrawer {...requestDrawerState} onClose={handleCloseRequestDrawer} />
        )}
      </Card>
    </div>
  )
}
