import React, { FC, useCallback, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuItemType, Space } from '~/core-components'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'

interface SysDownloadBtnProps {
  onDownloadPdf?: () => Promise<void>
  onDownloadExcel?: () => Promise<void>
}

export const SysDownloadBtn: FC<SysDownloadBtnProps> = ({ onDownloadPdf, onDownloadExcel, children }) => {
  const [downloading, setDownloading] = useState(false)
  const isMountedRef = useIsMountedRef()

  const handleDownloadPdf = useCallback(async () => {
    try {
      setDownloading(true)
      typeof onDownloadPdf === 'function' && (await onDownloadPdf())
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [onDownloadPdf, isMountedRef])

  const handleDownloadExcel = useCallback(async () => {
    try {
      setDownloading(true)
      typeof onDownloadExcel === 'function' && (await onDownloadExcel())
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [onDownloadExcel, isMountedRef])

  const items = useMemo(() => {
    const result: MenuItemType[] = []
    if (onDownloadPdf) {
      result.push({ key: 'pdf', label: 'PDF', onClick: handleDownloadPdf })
    }
    if (onDownloadExcel) {
      result.push({ key: 'excel', label: 'Excel', onClick: handleDownloadExcel })
    }
    return result
  }, [onDownloadPdf, onDownloadExcel, handleDownloadPdf, handleDownloadExcel])

  if (!onDownloadPdf && !onDownloadExcel) return null

  return (
    <Dropdown disabled={downloading} menu={{ items }}>
      <Button>
        {downloading ? (
          <LoadingOutlined />
        ) : (
          <Space size={4}>
            {children ? <>{children}</> : <i className="fal fa-arrow-down-to-bracket" />}
            <i className="fa-light fa-angle-down" />
          </Space>
        )}
      </Button>
    </Dropdown>
  )
}
