import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { TenantIdentityProviderState } from '../types'
import { selectTenantIdentityProviders } from '../selectors'
import { fetchTenantIdentityProviders } from '../actions'

export const useTenantIdentityProviders = (): [TenantIdentityProviderState[], boolean] => {
  const data = useSelector(selectTenantIdentityProviders)
  const loading = useSelector((state: StoreState) => state.master.tenantIdentityProvidersLoading)

  useEffect(() => {
    dispatch(fetchTenantIdentityProviders())
  }, [])

  return [data, loading]
}
