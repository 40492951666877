import { request, getBaseUrl } from '~/utils/request'
import { ITenantIdentityProvider, TenantIdentityProviderState } from '../types'
import { Operation } from 'fast-json-patch'

const activeTenantCode = localStorage.getItem('tenant')
const baseUrl = getBaseUrl(`/host/tenant/${activeTenantCode}/identityprovider`)

export const apiGetTenantIdentityProviders = () => request<TenantIdentityProviderState[]>('get', baseUrl)

export const apiToggleInactive = (providerType: string) => request('post', `${baseUrl}/${providerType}`)

export const apiAddTenantIdentityProvider = (idp: ITenantIdentityProvider) =>
  request<{ id: string }>('post', baseUrl, idp)

export const apiUpdateTenantIdentityProvider = (id: string, idp: Operation[]) =>
  request('patch', `${baseUrl}/${id}`, idp)

export const apiDeleteTenantIdentityProvider = (id: string) => request('delete', `${baseUrl}/${id}`)
