import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchReviewQuestionTemplates } from '../actions'
import { ReviewQuestionTemplateState } from '../types'
import { selectReviewQuestionTemplates } from '../selectors'

export const useReviewQuestionTemplates = (
  templateId: string,
  fetchStrategy: FetchStrategy = 'always'
): [ReviewQuestionTemplateState[], boolean] => {
  const data = useSelector(selectReviewQuestionTemplates)(templateId)
  const loading = useSelector((state: StoreState) => state.attendance.breakTimesLoading[templateId])

  useEffect(() => {
    dispatch(fetchReviewQuestionTemplates(templateId, { strategy: fetchStrategy }))
  }, [templateId, fetchStrategy])

  return [data, loading]
}
