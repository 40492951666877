import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchQuickBooksConnections } from '../actions'
import { QuickBooksConnectionState } from '../types'
import { selectQuickBooksConnections } from '../selectors'
import { useHasChange } from '~/hooks'

export const useQuickBooksConnections = (
  fetchStrategy: FetchStrategy = 'always'
): [QuickBooksConnectionState[], boolean] => {
  const connections = useSelector(selectQuickBooksConnections)
  const loading = useSelector((state: StoreState) => state.payroll.quickBooksConnectionsLoading)
  const isRefetch = useHasChange((state: StoreState) => state.payroll.quickBooksConnectionsRefetch)
  const strategy = isRefetch ? 'always' : fetchStrategy

  useEffect(() => {
    dispatch(fetchQuickBooksConnections({ strategy }))
  }, [isRefetch, strategy])

  return [connections, loading]
}
