import { AnyAction } from 'redux'
import { combineReducers } from '@reduxjs/toolkit'
import { localeReducers } from '~/features/locale'
import { globalReducers } from '~/features/global'
import { masterPersistConfig, masterReducers } from '~/features/master'
import { employeePersistConfig, employeeReducers } from '~/features/employee'
import { payrollPersistConfig, payrollReducers } from '~/features/payroll'
import { taxPersistConfig, taxReducers } from '~/features/tax'
import { leavePersistConfig, leaveReducers } from '~/features/leave'
import { claimPersistConfig, claimReducers } from '~/features/claim'
import { attendancePersistConfig, attendanceReducers } from '~/features/attendance'
import { schedulePersistConfig, scheduleReducers } from '~/features/schedule'
import { performancePersistConfig, performanceReducers } from '~/features/performance'
import { jobPersistConfig, jobReducers } from '~/features/job'
import { reportPersistConfig, reportReducers } from '~/features/report'
import { notificationPersistConfig, notificationReducers } from '~/features/notification'
import { selectionPersistConfig, selectionReducers } from '~/features/selection'
import { rulePersistConfig, ruleReducers } from '~/features/rule'
import { formulaPersistConfig, formulaReducers } from '~/features/formula'
import { iamPersistConfig, iamReducers } from '~/features/iam'
import { myPersistConfig, myReducers } from '~/features/my'
import { myClaimReducers } from '~/features/my-claim'
import { myPayrollReducers } from '~/features/my-payroll'
import { myScheduleReducers } from '~/features/my-schedule'
import { myAttendanceReducers } from '~/features/my-attendance'
import { microAppReducers } from '../features/micro-app/reducers'
import { tempReducers } from '../features/temp/reducers'
import { synchronizeStore } from './reduxpersist-listener'
import { store } from './store'
import { persistConfig } from './configure-store'
import { groupingReducers } from '~/features/grouping/reducers'
import { groupingPersistConfig } from '~/features/grouping'
import { workflowReducers } from '~/features/workflow'
import { calendarViewerReducers } from '~/features/calendar-viewer'
import { managerReducers } from '~/features/manager'
import { systemPersistConfig, systemReducers } from '~/features/system'

const rootReducers = {
  ...localeReducers,
  ...globalReducers,
  ...microAppReducers,
  ...masterReducers,
  ...employeeReducers,
  ...payrollReducers,
  ...taxReducers,
  ...leaveReducers,
  ...claimReducers,
  ...attendanceReducers,
  ...scheduleReducers,
  ...performanceReducers,
  ...jobReducers,
  ...reportReducers,
  ...notificationReducers,
  ...selectionReducers,
  ...groupingReducers,
  ...workflowReducers,
  ...ruleReducers,
  ...formulaReducers,
  ...iamReducers,
  ...myReducers,
  ...myClaimReducers,
  ...myPayrollReducers,
  ...myScheduleReducers,
  ...myAttendanceReducers,
  ...calendarViewerReducers,
  ...managerReducers,
  ...systemReducers,
  ...tempReducers
}

const handleCrossBrowserListeners = async (e: StorageEvent) => {
  if (e.key === 'tenant') {
    window.location.reload()
  }

  console.log(`Synchronizing storage for ${e.key}...`)
  await synchronizeStore(store, persistConfig)
  await synchronizeStore(store, masterPersistConfig)
  await synchronizeStore(store, employeePersistConfig)
  await synchronizeStore(store, payrollPersistConfig)
  await synchronizeStore(store, taxPersistConfig)
  await synchronizeStore(store, leavePersistConfig)
  await synchronizeStore(store, claimPersistConfig)
  await synchronizeStore(store, attendancePersistConfig)
  await synchronizeStore(store, schedulePersistConfig)
  await synchronizeStore(store, performancePersistConfig)
  await synchronizeStore(store, jobPersistConfig)
  await synchronizeStore(store, reportPersistConfig)
  await synchronizeStore(store, notificationPersistConfig)
  await synchronizeStore(store, selectionPersistConfig)
  await synchronizeStore(store, groupingPersistConfig)
  await synchronizeStore(store, rulePersistConfig)
  await synchronizeStore(store, formulaPersistConfig)
  await synchronizeStore(store, iamPersistConfig)
  await synchronizeStore(store, myPersistConfig)
  await synchronizeStore(store, systemPersistConfig)
  // await synchronizeStore(store, mySchedulePersistConfig)
}

export const createRootReducer = () => {
  const reducers = combineReducers(rootReducers)
  return (state: any, action: AnyAction): any => {
    return reducers(state, action)
  }
}

export const bindStoreListeners = () => {
  // window.addEventListener('storage', handleCrossBrowserListeners)
}

export const unbindStoreListeners = () => {
  // window.removeEventListener('storage', handleCrossBrowserListeners)
}
