import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteTenantIdentityProvider } from '../api/tenant-identity-provider.api'
import { removeTenantIdentityProvider } from '../reducers'

export const deleteTenantIdentityProvider = (code: string): ThunkResult<void> => {
  return async dispatch => {
    if (!code) return

    const { status, errors, message, errorData } = await apiDeleteTenantIdentityProvider(code)
    if (status) {
      dispatch(removeTenantIdentityProvider(code))
      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
