import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { Route, RouteProps, Switch } from 'react-router-dom'
import { Layout } from 'antd'
import { Input } from '~/core-components'
import { ErrorBoundary, MenuLinks } from '~/components'
import { useDebounce } from '~/hooks/use-debounce'
import { MenuItem } from '~/types/common'
import { useSystemMenu } from './SystemMenuContext'
import './SystemLayout.less'

const { Content, Sider } = Layout

interface SystemLayoutProps {
  routes: RouteProps[]
}

export const SystemLayout: FC<SystemLayoutProps> = ({ routes }) => {
  const { menus, siderWidth } = useSystemMenu()
  const [searchText, setSearchText] = useState<string>('')
  const [filteredMenus, setFilteredMenu] = useState<MenuItem[]>(menus)

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }, [])

  const filterMenus = useCallback(
    (filterText?: string) => {
      const filtered = menus.filter(m => (filterText ? m.value.toLowerCase().includes(filterText.toLowerCase()) : true))
      setFilteredMenu(filtered)
    },
    [menus]
  )

  useDebounce(() => filterMenus(searchText), 300, [searchText, filterMenus])

  return (
    <Layout className="system-layout">
      {menus.length > 1 && (
        <Sider className="system-layout__sider" width={siderWidth}>
          {(menus?.length || 0) > 20 ? (
            <>
              <Input.Search value={searchText} onChange={handleSearchChange} allowClear={true} />
              <MenuLinks menus={filteredMenus} />
            </>
          ) : (
            <MenuLinks menus={menus} />
          )}
        </Sider>
      )}
      <Content className="system-layout__content">
        <ErrorBoundary>
          <Switch>
            {routes.map(route => (
              <Route key={route.path as string} {...route} />
            ))}
          </Switch>
        </ErrorBoundary>
      </Content>
    </Layout>
  )
}
