import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiSaveReviewQuestionTemplate } from '../api/review-template.api'
import { ReviewQuestionTemplateState } from '../types'
import { fetchReviewQuestionTemplates } from './fetch-review-question-templates'

export const saveReviewQuestionTemplate = (
  reviewTemplateId: string,
  question: ReviewQuestionTemplateState
): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiSaveReviewQuestionTemplate(
      reviewTemplateId,
      question
    )
    if (status) {
      dispatch(fetchReviewQuestionTemplates(reviewTemplateId))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
