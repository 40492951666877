import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateQuestion } from '../api/question.api'
import { QuestionInfoState, QuestionState } from '../types'
import { setQuestion } from '../reducers'

export const updateQuestion = (
  id: string,
  before: QuestionInfoState,
  request: QuestionInfoState
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const after = { ...before, ...request } as QuestionInfoState
    const patch = generateJsonPatch<QuestionInfoState>(before, after)
    const { status, errors, message, errorData } = await apiUpdateQuestion(id, patch)
    if (status) {
      const question = getState().performance.questions?.entities[id] as QuestionState
      const updated: QuestionState = { ...question, ...after }
      dispatch(setQuestion(updated))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
