import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ChartSummary, EChartsOption } from '~/charts'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchTotalHeadcount } from '../../../actions'
import { useHasChange } from '~/hooks'

interface TotalHeadcountProps {
  viewId: string
}

export const TotalHeadcount: FC<TotalHeadcountProps> = ({ viewId }) => {
  const data = useSelector((state: StoreState) => state.employee.chartSummaries['total_hc'])
  const loading = useSelector((state: StoreState) => state.employee.chartSummariesLoading['total_hc'])
  const viewLoading = useSelector((state: StoreState) => state.selection.viewsLoading['em_dashboard'] || false)
  const isRefetch = useHasChange((state: StoreState) => state.employee.emDashboardRefetch)

  const option = useMemo(() => {
    return {
      tooltip: {
        formatter: (params: any) => {
          const month = moment(params[0].name).format('MMM')
          const marker = params[0].marker
          const value = params[0].value
          const unit = 'employee(s)'
          return `${month}<br />${marker} <b>${value}</b> ${unit}`
        }
      },
      grid: {
        left: 5
      },
      xAxis: {
        data: data?.labels || []
      },
      series: [
        {
          data: data?.values || [],
          type: 'line',
          smooth: true,
          areaStyle: {},
          itemStyle: {
            color: '#531dab'
          }
        }
      ]
    } as EChartsOption
  }, [data])

  useEffect(() => {
    if (viewId) dispatch(fetchTotalHeadcount(viewId))
  }, [viewId, isRefetch])

  return (
    <ChartSummary
      title="Total headcount"
      number={data?.number || 0}
      unit={data?.unit?.toLowerCase()}
      option={option}
      footer={
        data?.change != null ? (
          <>
            YoY change {data.change.toFixed(2)}%{' '}
            {data.change !== 0 && <i className={`fal ${data.change > 0 ? 'fa-caret-up' : 'fa-caret-down'}`} />}
          </>
        ) : (
          <>YoY change n/a</>
        )
      }
      loading={loading || viewLoading}
    />
  )
}
