import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { CpfSubmissionViewState } from '../types'

export const selectCpfSubmissionsView = createSelector(
  (state: StoreState) => state.payroll.cpfSubmissionsView,
  cpfSubmissionsViewState =>
    memoize(
      (viewId: string): CpfSubmissionViewState =>
        cpfSubmissionsViewState.entities[viewId] || ({} as CpfSubmissionViewState)
    )
)
