import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { replaceTenantIdentityProviders, setTenantIdentityProvidersLoading } from '../reducers'
import { apiGetTenantIdentityProviders } from '../api/tenant-identity-provider.api'

export const fetchTenantIdentityProviders = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().master.tenantIdentityProvidersLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().master.tenantIdentityProviders?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setTenantIdentityProvidersLoading(true))
      const { result, status } = await apiGetTenantIdentityProviders()
      if (status) {
        dispatch(replaceTenantIdentityProviders(result))
      }
    } finally {
      dispatch(setTenantIdentityProvidersLoading(false))
    }
  }
}
