import React, { FC, useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Form } from '~/core-components'
import { DrawerForm } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { saveRating } from '../../../actions'
import { ISaveRating } from '../../../types'
import { getEmptyRatingFormData, RatingForm } from './RatingForm'

export interface AddRatingDrawerProps {
  visible: boolean
  onClose: () => void
}

const getEmptyFormData = () =>
  ({
    ...getEmptyRatingFormData(),
    scales: getEmptyRatingFormData().scales.map(scale => ({ ...scale, id: uuidv4() }))
  } as ISaveRating)

export const AddRatingDrawer: FC<AddRatingDrawerProps> = ({ visible, onClose }: AddRatingDrawerProps) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ISaveRating>(getEmptyFormData())
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setErrors(undefined)
  }, [visible])

  useEffect(() => {
    setFormData(getEmptyFormData())
  }, [])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(saveRating(formData))
    } finally {
      setLoading(false)
    }
    if (result?.errors) {
      setErrors(result.errors)
    }
    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(getEmptyRatingFormData())

      if (result?.result?.id) {
        const ratingId = result.result.id
        history.push(`${SETTINGS_ROUTES.rating.replace(':id', ratingId)}`)
      }
    }
  }, [formData, onClose, history])

  return (
    <DrawerForm
      open={visible}
      title="Add rating"
      onClose={onClose}
      confirmLoading={loading}
      width={700}
      formId="form-add-rating"
    >
      <Form id="form-add-rating" onFinish={handleOk}>
        <RatingForm data={formData} errors={errors} onChange={handleFormDataChange} />
      </Form>
    </DrawerForm>
  )
}
