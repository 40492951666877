import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { QuickBooksAccountState } from '../types'

export const selectQuickBooksAccounts = createSelector(
  (state: StoreState) => state.payroll.quickBooksAccounts,
  quickBooksAccountsState =>
    memoize((realmId: string): QuickBooksAccountState[] => {
      const accounts = Object.values(quickBooksAccountsState[realmId]?.entities || {}) as QuickBooksAccountState[]
      return accounts.sort((a, b) =>
        a.active === b.active
          ? a.accountType?.localeCompare(b.accountType) || a.displayName.localeCompare(b.displayName)
          : a.active
          ? -1
          : 1
      )
    })
)
