import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { Button, Form, Input, Link } from '~/core-components'
import { Col, DrawerForm, ErrorDisplay, Row, SysOptions } from '~/components'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { AnswerType, emptyGuid } from '~/constants'
import { useFocus } from '~/hooks'
import { ReviewQuestionTemplateState } from '../../../types'
import { saveReviewQuestionTemplate, deleteReviewQuestionTemplate } from '../../../actions'
import { useQuestionsDict, useRatingScales } from '../../../hooks'
import { getDefaultRatingScales, getNewScale } from '../../Rating/components/RatingForm'
import { SelectQuestions } from '../../Questions/components/SelectQuestions'
import { SelectRating } from '../../Rating/components/SelectRating'

export interface MutateReviewQuestionTemplateDrawerProps {
  visible: boolean
  reviewTemplateId: string
  data?: ReviewQuestionTemplateState
  onClose: () => void
}

type FormData = ReviewQuestionTemplateState

const getEmptyFormData = () =>
  ({
    id: emptyGuid,
    questionIds: [],
    answerType: AnswerType.RatingScale,
    scales: getDefaultRatingScales()
  } as FormData)

export const MutateReviewQuestionTemplateDrawer: FC<MutateReviewQuestionTemplateDrawerProps> = ({
  visible,
  reviewTemplateId,
  data,
  onClose
}) => {
  const [saving, setSaving] = useState(false)
  const [formData, setFormData] = useState<FormData>(getEmptyFormData())
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [questionDict] = useQuestionsDict()
  const [ratingId, setRatingId] = useState<string>('')
  const [ratingScales] = useRatingScales(ratingId)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { id, questionIds, description, answerType, scales } = data
      setFormData({ id, questionIds, description, answerType, scales })
    } else {
      setFormData(getEmptyFormData())
    }
  }, [data])

  useEffect(() => {
    if (ratingScales.length > 0) {
      setFormData(formData => ({ ...formData, scales: ratingScales }))
    }
  }, [ratingScales])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates } as FormData
      if (updated.answerType === AnswerType.Text) {
        updated.scales = []
      }
      setFormData(updated)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving(true)
    try {
      result = await dispatch(saveReviewQuestionTemplate(reviewTemplateId, formData))
    } finally {
      setSaving(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(getEmptyFormData())
    }
  }, [reviewTemplateId, formData, onClose])

  const handleDelete = useCallback(
    (question: ReviewQuestionTemplateState | undefined) => {
      if (question) {
        const { id } = question
        confirm({
          title: `Delete question`,
          content: 'Do you want to delete this review question?',
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteReviewQuestionTemplate(reviewTemplateId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [reviewTemplateId, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit review question' : 'Add review question'}
      onClose={onClose}
      confirmLoading={saving}
      width={700}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-review-question-template"
    >
      <Form id="form-review-question-template" onFinish={handleOk}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item label="Questions" validateStatus={errors?.questionIds ? 'error' : ''} help={errors?.questionIds}>
              <SelectQuestions
                ref={focusRef}
                value={formData.questionIds}
                onChange={(questionIds: string[]) =>
                  handleFormDataChange({
                    questionIds,
                    description: questionIds.length === 1 ? questionDict[questionIds[0]]?.description : ''
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24} hidden={formData.questionIds.length > 1}>
            <Form.Item
              label="Description"
              validateStatus={errors?.description ? 'error' : ''}
              help={errors?.description}
            >
              <Input
                value={formData.description}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ description: event.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Answer type" validateStatus={errors?.answerType ? 'error' : ''} help={errors?.answerType}>
              <SysOptions
                type="pfm_answer_type"
                value={formData.answerType}
                onChange={(answerType: string[]) => handleFormDataChange({ answerType })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={formData.answerType !== AnswerType.RatingScale}>
          <Col flex={1}>
            <Form.Item label="Select pre-defined rating scales">
              <SelectRating value={ratingId} onChange={(ratingId: string) => setRatingId(ratingId)} />
            </Form.Item>
          </Col>
        </Row>
        {formData.answerType === AnswerType.RatingScale && (
          <>
            <ErrorDisplay keys={['scales[*]']} errors={errors} />
            {formData.scales.map((scale, index) => (
              <Row key={`${scale.scale}`} gutter={15} className="review-template-form-scales">
                <Col flex="50px">
                  {index === 0 && <label>Scale</label>}
                  <Input.Number
                    value={scale.scale}
                    readOnly
                    onChange={(scale: number | null) =>
                      handleFormDataChange({
                        scales: [
                          ...formData.scales.slice(0, index),
                          { ...formData.scales[index], scale },
                          ...formData.scales.slice(index + 1, formData.scales.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col flex={1}>
                  {index === 0 && <label>Name</label>}
                  <Input
                    value={scale.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({
                        scales: [
                          ...formData.scales.slice(0, index),
                          { ...formData.scales[index], name: event.target.value },
                          ...formData.scales.slice(index + 1, formData.scales.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col flex={2}>
                  {index === 0 && <label>Description</label>}
                  <Input.TextArea
                    rows={2}
                    value={scale.description}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                      handleFormDataChange({
                        scales: [
                          ...formData.scales.slice(0, index),
                          { ...formData.scales[index], description: event.target.value },
                          ...formData.scales.slice(index + 1, formData.scales.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col span={1} style={{ whiteSpace: 'nowrap' }}>
                  {index === 0 && <label>&nbsp;</label>}
                  <Button
                    size="small"
                    type="text"
                    icon={<i className="fal fa-xmark" />}
                    onClick={() =>
                      handleFormDataChange({
                        scales: [
                          ...formData.scales.slice(0, index),
                          ...formData.scales.slice(index + 1, formData.scales.length)
                        ]
                      })
                    }
                  />
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Link
                onClick={() => handleFormDataChange({ scales: [...formData.scales, getNewScale(formData.scales)] })}
              >
                add scale
              </Link>
            </Form.Item>
          </>
        )}
      </Form>
    </DrawerForm>
  )
}
