import { setPayRunQuickBooks, setPayRunQuickBooksLoading } from '../reducers'
import { apiGetPayRunQuickBooks } from '../api/payrun-quick-books.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchPayRunQuickBooks = (
  payRunId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!payRunId) return

    const loading = getState().payroll.payRunQuickBooksLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.payRunQuickBooks.entities[payRunId]
      if (hasData) return
    }

    try {
      dispatch(setPayRunQuickBooksLoading(true))
      const { result, status } = await apiGetPayRunQuickBooks(payRunId)
      if (status) {
        dispatch(setPayRunQuickBooks(result))
      }
    } finally {
      dispatch(setPayRunQuickBooksLoading(false))
    }
  }
}
