import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, PageHeader, Select, Space, Spin, Switch, Tooltip } from '~/core-components'
import { DocumentTitle } from '~/components'
import { HELP_ROUTES, SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { disconnectQuickBooksConnection, initiateQuickBooksAuthentication, updateConfig } from '../../actions'
import { useConfigsDict, useQuickBooksConnections } from '../../hooks'
import { QuickBooksCompanyMap } from './QuickBooksCompanyMap'
import { QuickBooksLineItemMap } from './QuickBooksLineItemMap'
import { Config } from '~/constants'
import { ConfigState } from '../../types'
import './QuickBooks.less'

interface QuickBooksProps {}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'QuickBooks integration'
  }
]

export const QuickBooks: FC<QuickBooksProps> = () => {
  const [connections, loading] = useQuickBooksConnections()
  const [connecting, setConnecting] = useState(false)
  const [disconnecting, setDisconnecting] = useState(false)
  const noConnections = connections == null || connections.length === 0
  const [realmId, setRealmId] = useState('')
  const sysConfigCodes = useMemo(() => [Config.PayShowQuickBooks], [])
  const [configs, configsLoading] = useConfigsDict(sysConfigCodes)
  const config = configs[Config.PayShowQuickBooks] as ConfigState
  const [enabling, setEnabling] = useState(false)

  useEffect(() => {
    if (connections && connections.length > 0) {
      setRealmId(connections[0].realmId)
    }
  }, [connections])

  const handleConnect = useCallback(async () => {
    try {
      setConnecting(true)
      await dispatch(initiateQuickBooksAuthentication())
    } finally {
      setConnecting(false)
    }
  }, [])

  const handleDisconnect = useCallback(async () => {
    try {
      setDisconnecting(true)
      await dispatch(disconnectQuickBooksConnection(realmId))
    } finally {
      setDisconnecting(false)
    }
  }, [realmId])

  const handleConnectionChange = useCallback((realmId: string) => {
    setRealmId(realmId)
  }, [])

  const handleHelpClick = useCallback(() => {
    window.open(HELP_ROUTES.page.replace(':page', 'quick-books'), '_blank')
  }, [])

  const handleEnableChange = useCallback(
    async (value: string) => {
      setEnabling(true)
      try {
        await dispatch(updateConfig(Config.PayShowQuickBooks, config, { ...config, value }))
      } finally {
        setEnabling(false)
      }
    },
    [config]
  )

  return (
    <div id="quick-books" className="quick-books">
      <DocumentTitle title="QuickBooks Integration" />
      <PageHeader
        title={
          <Space>
            <img alt="QuickBooks" src="/partners/quick-books.png" width="32" />
            QuickBooks integration
            <Switch
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              checked={config?.value === '1' ? true : false}
              loading={configsLoading || enabling}
              onChange={(checked: boolean) => {
                handleEnableChange(checked ? '1' : '0')
              }}
            />
          </Space>
        }
        containerId="quick-books"
        breadcrumb={{ routes }}
        extra={
          <Space>
            {noConnections && (
              <Button type="primary" loading={connecting} onClick={handleConnect}>
                Connect to QuickBooks
              </Button>
            )}
            {!noConnections && (
              <>
                <Select
                  style={{ width: 230 }}
                  dropdownMatchSelectWidth={false}
                  value={realmId}
                  loading={loading}
                  onChange={(value: string) => handleConnectionChange(value)}
                >
                  {connections.map(opt => (
                    <Select.Option key={opt.realmId} value={opt.realmId} display={opt.companyName}>
                      {opt.companyName}
                    </Select.Option>
                  ))}
                </Select>
                <Button danger loading={disconnecting} onClick={handleDisconnect} disabled={noConnections}>
                  Disconnect
                </Button>
                <Button type="primary" loading={connecting} onClick={handleConnect}>
                  Connect to QuickBooks
                </Button>
              </>
            )}
            <Tooltip title="Guide to configure and send payroll data to QuickBooks" placement="bottomRight">
              <Button icon={<i className="fal fa-circle-question" />} onClick={handleHelpClick} />
            </Tooltip>
          </Space>
        }
      />
      <div className="quick-books__body">
        {connections.length > 0 ? (
          <>
            <QuickBooksCompanyMap realmId={realmId} />
            <QuickBooksLineItemMap realmId={realmId} />
          </>
        ) : (
          loading && <Spin indicator={<LoadingOutlined spin />} />
        )}
      </div>
    </div>
  )
}
