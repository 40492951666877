import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { EChartsOption, PieChart } from '~/charts'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchHeadcountGroupBy } from '../../../actions'
import { useHasChange } from '~/hooks'

interface HeadcountDistributionProps {
  cacheKey: string
  viewId: string
  groupBy: string
}

const chartStyle: CSSProperties = { width: 280, height: 150 }

export const HeadcountDistribution: FC<HeadcountDistributionProps> = ({ cacheKey, viewId, groupBy }) => {
  const [emGroup, setEmGroup] = useState(groupBy)
  const data = useSelector((state: StoreState) => state.employee.headcountGroupBy[cacheKey])
  const loading = useSelector((state: StoreState) => state.employee.headcountGroupByLoading[cacheKey])
  const viewLoading = useSelector((state: StoreState) => state.selection.viewsLoading['em_dashboard'] || false)
  const isRefetch = useHasChange((state: StoreState) => state.employee.emDashboardRefetch)

  const option = useMemo(() => {
    return {
      series: [
        {
          data: data?.data || []
        }
      ]
    } as EChartsOption
  }, [data])

  useEffect(() => {
    if (viewId) dispatch(fetchHeadcountGroupBy(cacheKey, viewId, emGroup))
  }, [cacheKey, viewId, emGroup, isRefetch])

  const handleChange = useCallback((emGroup: string) => {
    setEmGroup(emGroup)
  }, [])

  return (
    <PieChart
      emGroup={emGroup}
      option={option}
      onChange={handleChange}
      loading={loading || viewLoading}
      style={chartStyle}
    />
  )
}
