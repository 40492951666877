import { DateRange, Pagination, Search } from '~/types/common'
import { request, getBaseUrl, timeout } from '~/utils/request'
import { AuditTableViewState } from '../types'

const baseUrl = getBaseUrl('/log/audittable')

export const apiGetAuditTablesView = (viewId: string, params: Pagination & Search & DateRange) =>
  request<AuditTableViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiDownloadAuditTablesPdf = (viewId: string, params: Search & DateRange) =>
  request('get', `${baseUrl}/view/${viewId}/pdf`, params, { responseType: 'blob', timeout })

export const apiDownloadAuditTablesExcel = (viewId: string, params: Search & DateRange) =>
  request('get', `${baseUrl}/view/${viewId}/excel`, params, { responseType: 'blob', timeout })
