import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchQuickBooksCompanyMap } from '../actions'
import { QuickBooksCompanyMapState } from '../types'

export const useQuickBooksCompanyMap = (
  realmId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [QuickBooksCompanyMapState | undefined, boolean] => {
  const config = useSelector((state: StoreState) => state.payroll.quickBooksCompanyMap.entities[realmId || ''])
  const loading = useSelector((state: StoreState) => state.payroll.quickBooksCompanyMapLoading)

  useEffect(() => {
    dispatch(fetchQuickBooksCompanyMap(realmId, { strategy: fetchStrategy }))
  }, [realmId, fetchStrategy])

  return [config, loading]
}
