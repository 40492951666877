import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchQuickBooksContacts } from '../actions'
import { QuickBooksContactState } from '../types'
import { selectQuickBooksContacts } from '../selectors'

export const useQuickBooksContacts = (
  realmId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [QuickBooksContactState[], boolean] => {
  const contacts = useSelector(selectQuickBooksContacts)(realmId || '')
  const loading = useSelector((state: StoreState) => state.payroll.quickBooksContactsLoading[realmId || ''])

  useEffect(() => {
    dispatch(fetchQuickBooksContacts(realmId, { strategy: fetchStrategy }))
  }, [realmId, fetchStrategy])

  return [contacts, loading]
}
