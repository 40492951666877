import { request, getBaseUrl } from '~/utils/request'
import { BarChartState, ChartSummaryState, PieChartState } from '../types/em-dashboard.state'

const baseUrl = getBaseUrl('/employee/emdashboard')

export const apiGetTotalHeadcount = (viewId: string) =>
  request<ChartSummaryState>('get', `${baseUrl}/totalhc/${viewId}`)

export const apiGetAttritionRate = (viewId: string) =>
  request<ChartSummaryState>('get', `${baseUrl}/attritionrate/${viewId}`)

export const apiGetAverageAge = (viewId: string) => request<ChartSummaryState>('get', `${baseUrl}/avgage/${viewId}`)

export const apiGetAverageServiceLength = (viewId: string) =>
  request<ChartSummaryState>('get', `${baseUrl}/avgsvclen/${viewId}`)

export const apiGetServiceLength = (viewId: string) => request<BarChartState>('get', `${baseUrl}/svclen/${viewId}`)

export const apiGetHeadcountGroupBy = (viewId: string, groupBy: string) =>
  request<PieChartState>('get', `${baseUrl}/hcgrp/${viewId}`, { groupBy })
