import { replaceQuickBooksAccounts, setQuickBooksAccountsLoading } from '../reducers'
import { apiGetQuickBooksAccounts } from '../api/quick-books.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { showError } from '~/utils'

export const fetchQuickBooksAccounts = (
  realmId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!realmId) return

    const loading = getState().payroll.quickBooksAccountsLoading[realmId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.quickBooksAccounts[realmId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setQuickBooksAccountsLoading({ realmId, loading: true }))
      const { result, status, errors, message, errorData } = await apiGetQuickBooksAccounts(realmId)
      if (status) {
        dispatch(replaceQuickBooksAccounts({ realmId, data: result }))
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setQuickBooksAccountsLoading({ realmId, loading: false }))
    }
  }
}
