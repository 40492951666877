import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { setConfigs, setConfigsLoading } from '../reducers'
import { apiGetConfigs } from '../api/config.api'

export const fetchConfigs = (
  sysConfigCodes: string[],
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().payroll.configsLoading
    if (loading) return
    console.log(options)

    if (options.strategy === 'when-empty') {
      const configs = getState().payroll.configs.ids
      const hasData = sysConfigCodes.some(code => configs.includes(code))
      if (hasData) return
    }

    try {
      dispatch(setConfigsLoading(true))
      const { result, status } = await apiGetConfigs({ sysConfigCodes })
      if (status) {
        dispatch(setConfigs(result))
      }
    } finally {
      dispatch(setConfigsLoading(false))
    }
  }
}
