import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { BarChart, EChartsOption } from '~/charts'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchServiceLength } from '../../../actions'
import { useHasChange } from '~/hooks'

interface ServiceLengthProps {
  viewId: string
}

export const ServiceLength: FC<ServiceLengthProps> = ({ viewId }) => {
  const data = useSelector((state: StoreState) => state.employee.chartServiceLen)
  const loading = useSelector((state: StoreState) => state.employee.chartServiceLenLoading)
  const viewLoading = useSelector((state: StoreState) => state.selection.viewsLoading['em_dashboard'] || false)
  const isRefetch = useHasChange((state: StoreState) => state.employee.emDashboardRefetch)

  const option = useMemo(() => {
    return {
      xAxis: {
        data: data?.labels || []
      },
      yAxis: {
        name: 'No. of employees'
      },
      series: [
        {
          data: data?.values,
          type: 'bar',
          barWidth: '50%',
          itemStyle: {
            color: '#ff8500'
          }
        }
      ]
    } as EChartsOption
  }, [data])

  useEffect(() => {
    if (viewId) dispatch(fetchServiceLength(viewId))
  }, [viewId, isRefetch])

  return <BarChart title="Years of service" option={option} loading={loading || viewLoading} />
}
