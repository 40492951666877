import { DateRange, Pagination, Search } from '~/types/common'
import { request, getBaseUrl, timeout } from '~/utils/request'
import { AuditLoginViewState } from '../types'

const baseUrl = getBaseUrl('/log/auditlogin')

export const apiGetAuditLoginsView = (viewId: string, params: Pagination & Search & DateRange) =>
  request<AuditLoginViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiDownloadAuditLoginsPdf = (viewId: string, params: Search & DateRange) =>
  request('get', `${baseUrl}/view/${viewId}/pdf`, params, { responseType: 'blob', timeout })

export const apiDownloadAuditLoginsExcel = (viewId: string, params: Search & DateRange) =>
  request('get', `${baseUrl}/view/${viewId}/excel`, params, { responseType: 'blob', timeout })
