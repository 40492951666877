import React, { FC, useMemo } from 'react'
import classNames from 'classnames'
import { Card, CardProps } from '~/core-components'
import { Col, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Config, Permission, PermissionAction } from '~/constants'
import { XeroIntegration } from './XeroIntegration'
import { QuickBooksIntegration } from './QuickBooksIntegration'
import { useConfigsDict } from '../../hooks'
import './PayIntegrationCard.less'

interface PayIntegrationCardProps extends CardProps {
  payRunId: string
}

export const PayIntegrationCard: FC<PayIntegrationCardProps> = ({ payRunId, className, ...props }) => {
  const canXeroSend = usePermissionGate(Permission.xero, PermissionAction.Send)
  const canQuickBooksSend = usePermissionGate(Permission.quickBooks, PermissionAction.Send)
  const sysConfigCodes = useMemo(() => [Config.PayShowQuickBooks, Config.PayShowXero], [])
  const [configs] = useConfigsDict(sysConfigCodes)
  const isShownQBooks = configs[Config.PayShowQuickBooks]?.value === '1'
  const isShownXero = configs[Config.PayShowXero]?.value === '1'

  if (!canXeroSend || !canQuickBooksSend || (!isShownQBooks && !isShownXero)) return null

  return (
    <Card className={classNames('pay-integration-card', className)} {...props}>
      <Row>
        <Col>
          <div className="pay-integration-card__title">
            <b>Integration</b>
          </div>
        </Col>
      </Row>
      {isShownXero && <XeroIntegration payRunId={payRunId} />}
      {isShownQBooks && <QuickBooksIntegration payRunId={payRunId} />}
    </Card>
  )
}
