import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { RatingScaleState } from '../types'

export const selectRatingScales = createSelector(
  (state: StoreState) => state.performance.ratingScales,
  ratingScalesState =>
    memoize((ratingId?: string): RatingScaleState[] => {
      if (!ratingId) return []

      const ratingScales = Object.values(ratingScalesState[ratingId]?.entities || {}) as RatingScaleState[]
      return ratingScales.sort((a, b) => a.scale - b.scale)
    })
)
