import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  Button,
  ColumnsType,
  Form,
  SecondaryText,
  Space,
  Switch,
  Table,
  TablePaginationConfig,
  TableRowSelection,
  Tag
} from '~/core-components'
import { PersonAvatar, SearchInput } from '~/components'
import {
  Screen,
  ViewCriteria,
  ViewCriteriaSimple,
  updateViewCriteria,
  resetViewCriteria,
  ViewName,
  useGetViewIdByName,
  ViewCriteriaFieldValue
} from '~/features/selection'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { Pagination } from '~/types/common'
import { emptyGuid } from '~/constants'
import { getBaseUrl } from '~/utils'
import { selectEmSelectionById } from '../../reducers'
import { EmSelectionState } from '../../types'
import { apiGetEmNameDesc } from '../../api/employee.api'
import './EmSelection.less'

const SCREEN_CODE: Screen = 'em_selection'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const secondaryTextStyle: CSSProperties = { fontSize: 12 }
const tableScroll = { y: 1000 }
const baseUrl = getBaseUrl('/filestore')

export interface EmSelectionProps {
  viewName: ViewName
  visible: boolean
  employeeIds?: string[]
  onFetchData: (viewId: string, pagination: Pagination, search: string, pastResignees: boolean) => void
  onSelect: (employeeIds: string[]) => void
  resetOnClose?: boolean
  resetTo?: ViewCriteriaFieldValue[]
}

export const EmSelection: FC<EmSelectionProps> = ({
  viewName,
  visible,
  employeeIds,
  onFetchData,
  onSelect,
  resetOnClose = false,
  resetTo = []
}: EmSelectionProps) => {
  const [selected, setSelected] = useState<EmSelectionState[]>([])
  const [selectedLoading, setSelectedLoading] = useState(false)
  const [selection, setSelection] = useState<EmSelectionState[]>([])
  const [viewId, viewLoading] = useGetViewIdByName(SCREEN_CODE, viewName)
  const loading = useSelector((state: StoreState) => state.employee.emSelectionLoading)
  const data = useSelector((state: StoreState) => (viewId ? selectEmSelectionById(state, viewId) : undefined))
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [pastResignees, setPastResignees] = useState(false)
  const [refetch, setRefetch] = useState(0)
  const [init, setInit] = useState(true)

  useEffect(() => {
    if (visible) {
      setInit(true)
    }
  }, [visible])

  useDeepCompareEffect(() => {
    const closingDrawer = async () => {
      if (viewId) {
        await dispatch(resetViewCriteria(SCREEN_CODE, viewId, resetTo))
        setRefetch(r => r + 1)
        setPage(1)
      }
      if (resetOnClose) {
        setSelected([])
        typeof onSelect === 'function' && onSelect([])
      }
    }

    if (!visible) {
      closingDrawer()
    }
  }, [visible, viewId, resetOnClose, resetTo, onSelect])

  useEffect(() => {
    if (viewId && typeof onFetchData === 'function') {
      const pagination = { offset: pageSize * (page - 1), limit: pageSize }
      onFetchData(viewId, pagination, search, pastResignees)
    }
  }, [viewId, page, pageSize, search, pastResignees, refetch, onFetchData])

  useEffect(() => {
    setSelection(data?.data || [])
  }, [data])

  useEffect(() => {
    if (init && !loading) {
      if (employeeIds && employeeIds.length > 0) {
        const fetchNameDescs = async () => {
          try {
            setSelectedLoading(true)
            const { result } = await apiGetEmNameDesc(employeeIds)
            setSelected(result)
          } finally {
            setSelectedLoading(false)
          }
        }
        fetchNameDescs()
      }

      const selected = employeeIds?.map(id => ({ id })) as EmSelectionState[]
      typeof onSelect === 'function' && onSelect(selected.map(s => s.id))
      setInit(false)
    }
  }, [init, loading, data, employeeIds, onSelect])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        setRefetch(r => r + 1)
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleRefresh = useCallback(() => {
    setRefetch(r => r + 1)
  }, [])

  const pagination = useMemo(
    (): TablePaginationConfig => ({
      total: data?.count || 0,
      current: page,
      pageSize,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      showSizeChanger: true,
      position: ['bottomLeft'],
      onChange: handlePaginationChange
    }),
    [data, handlePaginationChange, page, pageSize]
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
    setPage(1)
  }, [])

  const handleRemove = useCallback(
    (record: EmSelectionState) => {
      setSelected(selected => {
        const newSelected = selected.filter(s => s.id !== record.id)
        typeof onSelect === 'function' && onSelect(newSelected.map(s => s.id))
        return newSelected
      })
    },
    [onSelect]
  )

  const rowSelection: TableRowSelection<EmSelectionState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(s => s.id),
      hideSelectAll: false,
      onChange: (rowKeys, rows) => {
        setSelected(selected => {
          const newSelected = [...selected, ...rows]
          typeof onSelect === 'function' && onSelect(newSelected.map(s => s.id))
          return newSelected
        })
      }
    }),
    [selected, onSelect]
  )

  const columnsSelection: ColumnsType<EmSelectionState> = useMemo(
    () => [
      {
        title: 'Selections',
        key: 'name',
        dataIndex: 'name',
        render: (value: string, record) => (
          <>
            <div className="em-selection__person">
              <PersonAvatar
                photo={
                  record.photoId && record.photoId !== emptyGuid
                    ? `${baseUrl}/file/${record.photoId}/thumbnailphoto/24`
                    : undefined
                }
                size={24}
                loading={loading || viewLoading}
              />
              <div className="em-selection__person__info">
                <h1>{record.name}</h1>
                <Space>
                  {record.description && <SecondaryText style={secondaryTextStyle}>{record.description}</SecondaryText>}
                  {record.isResignee && <Tag type="neutral">resignee</Tag>}
                </Space>
              </div>
            </div>
          </>
        )
      }
    ],
    [loading, viewLoading]
  )

  const columnsSelected: ColumnsType<EmSelectionState> = useMemo(
    () => [
      {
        title: `${selected.length} selected`,
        key: 'name',
        dataIndex: 'name',
        render: (value: string, record) => (
          <>
            <div className="em-selection__person">
              <PersonAvatar
                photo={
                  record.photoId && record.photoId !== emptyGuid
                    ? `${baseUrl}/file/${record.photoId}/thumbnailphoto/24`
                    : undefined
                }
                size={24}
                loading={loading || viewLoading}
              />
              <div className="em-selection__person__info">
                <h1>{record.name}</h1>
                <Space>
                  {record.description && <SecondaryText style={secondaryTextStyle}>{record.description}</SecondaryText>}
                  {record.isResignee && <Tag type="neutral">resignee</Tag>}
                </Space>
              </div>
            </div>
          </>
        )
      },
      {
        key: 'action',
        width: 45,
        align: 'right',
        render: (value, record) => (
          <Button
            type="text"
            className="remove-button"
            icon={<CloseCircleOutlined />}
            onClick={() => handleRemove(record)}
          />
        )
      }
    ],
    [handleRemove, selected.length, loading, viewLoading]
  )

  return (
    <div className="em-selection">
      <div className="em-selection__action-bar">
        <Form.Item label="Search">
          <SearchInput onSearch={handleSearch} />
        </Form.Item>
        <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} />
        {['payrun_newem', 'payrun_recalculate'].includes(viewName) && (
          <Form.Item label="Past resignees" className="em-selection__past-resignee">
            <Switch checkedChildren="Yes" unCheckedChildren="No" checked={pastResignees} onChange={setPastResignees} />
          </Form.Item>
        )}
      </div>
      <div className="em-selection__table">
        <div className="em-selection__table-selection">
          <Table
            className="selection"
            rowKey="id"
            rowSelection={rowSelection}
            dataSource={loading ? [] : selection.filter(s => !selected.some(x => x.id === s.id))}
            columns={columnsSelection}
            loading={loading || viewLoading}
            scroll={tableScroll}
            pagination={pagination}
            fitParent
            onRefresh={handleRefresh}
          />
        </div>
        <div className="em-selection__table-selected">
          <Table
            className="selected"
            rowKey="id"
            dataSource={selected}
            columns={columnsSelected}
            loading={selectedLoading}
            pagination={false}
            fitParent
            scroll={tableScroll}
          />
        </div>
      </div>
    </div>
  )
}
