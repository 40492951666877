import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { RatingState } from '../types'
import { fetchRating } from '../actions'
import { FetchStrategy } from '~/types/common'

export const useRating = (id: string, fetchStrategy: FetchStrategy = 'always'): [RatingState | undefined, boolean] => {
  const rating = useSelector((state: StoreState) => state.performance.ratings?.entities[id])
  const loading = useSelector((state: StoreState) => state.performance.ratingsLoading)

  useEffect(() => {
    dispatch(fetchRating(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [rating, loading]
}
