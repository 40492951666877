import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiToggleInactive } from '../api/tenant-identity-provider.api'
import { setTenantIdentityProvider } from '../reducers'
import { TenantIdentityProviderState } from '../types'

export const toggleTenantIdentityProviderInactive = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const identityProvider = getState().master.tenantIdentityProviders?.entities[id] as TenantIdentityProviderState
    const { providerType } = identityProvider

    if (!providerType) return

    const { result, status, errors, message, errorData } = await apiToggleInactive(providerType)
    if (status) {
      const updated = { ...identityProvider, inactiveDate: result.inactiveDate }
      dispatch(setTenantIdentityProvider(updated))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
