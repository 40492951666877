import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { QuickBooksConnectionState } from '../types'

export const selectQuickBooksConnections = createSelector(
  (state: StoreState) => state.payroll.quickBooksConnections,
  quickBooksConnectionsState => {
    return Object.values(quickBooksConnectionsState?.entities || {}) as QuickBooksConnectionState[]
  }
)
