import React, { FC, useCallback, useState } from 'react'
import { Button, Card, ColumnsType, Link, PageHeader, Text, Space, Table } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { QuestionState } from '../../types'
import { useQuestions } from '../../hooks'
import { MutateQuestionDrawer } from './components/MutateQuestionDrawer'
import './Questions.less'

type QuestionTable = QuestionState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: QuestionState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface QuestionsProps {}

export const Questions: FC<QuestionsProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.question, PermissionAction.Modify)
  const [questions, loading] = useQuestions()

  const handleAddQuestion = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditQuestion = useCallback((question: QuestionState) => {
    setDrawerState({ visible: true, data: question })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<QuestionTable> = [
    {
      title: 'Question',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space direction="vertical" size={1}>
          <Space>
            <b>{value}</b>
            {isInactive(record.inactiveDate) && <InactiveTag />}
          </Space>
          {record.description && (
            <Text size="small" italic>
              {record.description}
            </Text>
          )}
        </Space>
      )
    },
    {
      key: 'action',
      align: 'right',
      width: 70,
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditQuestion(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div id="questions" className="questions">
      <DocumentTitle title="Questions" />
      <PageHeader
        title="Questions"
        containerId="questions"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddQuestion}>
              Add question
            </Button>
          )
        }
      />
      <div className="questions__body">
        <Card table>
          <Table rowKey="id" dataSource={questions} pagination={false} columns={columns} loading={loading} />
        </Card>
      </div>
      {canModify && <MutateQuestionDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
