import { setCpfSubmissionsView, setCpfSubmissionsViewLoading } from '../reducers'
import { apiGetCpfSubmissionsView } from '../api/cpf-submission.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions, Pagination } from '~/types/common'

export const fetchCpfSubmissionsView = (
  viewId: string,
  pagination: Pagination,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId) return

    const loading = getState().payroll.cpfSubmissionsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.cpfSubmissionsView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setCpfSubmissionsViewLoading(true))
      const { result, status } = await apiGetCpfSubmissionsView(viewId, { ...pagination })
      if (status) {
        dispatch(setCpfSubmissionsView(result))
      }
    } finally {
      dispatch(setCpfSubmissionsViewLoading(false))
    }
  }
}
