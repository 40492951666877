import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { QuickBooksLineItemMapState } from '../types'

export const selectQuickBooksLineItemMaps = createSelector(
  (state: StoreState) => state.payroll.quickBooksLineItemMaps,
  quickBooksLineItemMapsState =>
    memoize((realmId: string): QuickBooksLineItemMapState[] => {
      return Object.values(quickBooksLineItemMapsState[realmId]?.entities || {}) as QuickBooksLineItemMapState[]
    })
)
