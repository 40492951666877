import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, Row, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import { addEmIdentity, deleteEmIdentity, updateEmIdentity } from '../../../actions'
import { IEmIdentityInfo, EmIdentityState } from '../../../types'
import { mapEmIdentityStateToEmIdentityInfo } from '../../../types/em-details.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { KeyValue } from '~/types/common'
import { CpfType } from '~/constants'
import { useSysOptions } from '~/features/employee/hooks'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'

export interface MutateEmIdentityDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmIdentityState
  readOnly?: boolean
  onClose: () => void
}

const EMPTY_FORM_DATA: IEmIdentityInfo = {
  identityType: '',
  identityNo: '',
  issueDate: '',
  expiryDate: '',
  cancelledDate: '',
  notes: '',
  workPermitNo: '',
  sPassNo: '',
  cpfType: '',
  fwlCode: ''
}

export const MutateEmIdentityDrawer: FC<MutateEmIdentityDrawerProps> = ({
  visible,
  employeeId,
  data,
  readOnly,
  onClose
}: MutateEmIdentityDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmIdentityInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [identityTypes] = useSysOptions('identity_type')
  const [cpfTypes] = useSysOptions('cpf_type')
  const fwlCodes = useSelector(selectKeyvaluesById)('fwlCode')

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)

    if (visible) dispatch(fetchEmpKeyvalues('fwlCode', 'fwl', null, { strategy: 'when-empty' }))
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const {
        identityType,
        identityNo,
        issueDate,
        expiryDate,
        cancelledDate,
        notes,
        workPermitNo,
        sPassNo,
        cpfType,
        fwlCode
      } = data
      setFormData({
        identityType,
        identityNo,
        issueDate,
        expiryDate,
        cancelledDate,
        notes,
        workPermitNo,
        sPassNo,
        cpfType,
        fwlCode
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if ('identityType' in updates) {
      if (updates.identityType === 'sg01') {
        updates.cpfType = CpfType.CITIZEN
      } else if (updates.identityType === 'sg02') {
        updates.cpfType = CpfType.GRADUATED
      }
    }

    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(
          updateEmIdentity(data.employeeId, data.id, mapEmIdentityStateToEmIdentityInfo(data), formData)
        )
      } else if (employeeId) {
        result = await dispatch(addEmIdentity(employeeId, formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeId, data, formData, onClose])

  const handleDelete = useCallback(
    (emIdentity: EmIdentityState | undefined) => {
      if (employeeId && emIdentity) {
        const { id, identityNo } = emIdentity
        confirm({
          title: 'Delete personal identification',
          content: `Do you want to delete personal identification record of "${identityNo}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmIdentity(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={
        data ? (readOnly ? 'Personal identification' : 'Edit personal identification') : 'Add personal identification'
      }
      okText={!readOnly ? 'Save' : 'Close'}
      onClose={onClose}
      confirmLoading={loading}
      width={600}
      showDelete={data && !readOnly ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-identity"
    >
      <Form id="form-em-identity" onFinish={!readOnly ? handleOk : onClose}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Identity type"
              validateStatus={errors?.identityType ? 'error' : ''}
              help={errors?.identityType}
            >
              {readOnly ? (
                identityTypes[formData.identityType]?.value
              ) : (
                <SysOptions
                  type="identity_type"
                  sort="key"
                  value={formData.identityType}
                  ref={focusRef}
                  onChange={(value: string) => handleFormDataChange({ ...EMPTY_FORM_DATA, identityType: value })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Identity no."
              validateStatus={errors?.identityNo ? 'error' : ''}
              help={errors?.identityNo}
            >
              {readOnly ? (
                formData.identityNo?.toUpperCase()
              ) : (
                <Input
                  value={formData.identityNo}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ identityNo: event.target.value.toUpperCase() })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Issue date" validateStatus={errors?.issueDate ? 'error' : ''} help={errors?.issueDate}>
              {readOnly ? (
                formData.issueDate ? (
                  moment(formData.issueDate).format('DD MMM YYYY')
                ) : (
                  '-'
                )
              ) : (
                <Input.Date
                  value={formData.issueDate ? moment(formData.issueDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ issueDate: value?.format('YYYY-MM-DD') })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Expiry date" validateStatus={errors?.expiryDate ? 'error' : ''} help={errors?.expiryDate}>
              {readOnly ? (
                formData.expiryDate ? (
                  moment(formData.expiryDate).format('DD MMM YYYY')
                ) : (
                  '-'
                )
              ) : (
                <Input.Date
                  value={formData.expiryDate ? moment(formData.expiryDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ expiryDate: value?.format('YYYY-MM-DD') })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Cancelled date"
              validateStatus={errors?.cancelledDate ? 'error' : ''}
              help={errors?.cancelledDate}
            >
              {readOnly ? (
                formData.cancelledDate ? (
                  moment(formData.cancelledDate).format('DD MMM YYYY')
                ) : (
                  '-'
                )
              ) : (
                <Input.Date
                  value={formData.cancelledDate ? moment(formData.cancelledDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ cancelledDate: value?.format('YYYY-MM-DD') })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {formData.identityType === 'sg07' && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Work permit no."
                validateStatus={errors?.workPermitNo ? 'error' : ''}
                help={errors?.workPermitNo}
              >
                {readOnly ? (
                  formData.workPermitNo?.toUpperCase()
                ) : (
                  <Input
                    value={formData.workPermitNo}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({ workPermitNo: event.target.value.toUpperCase() })
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        {formData.identityType === 'sg06' && (
          <Row>
            <Col span={24}>
              <Form.Item label="S Pass no." validateStatus={errors?.sPassNo ? 'error' : ''} help={errors?.sPassNo}>
                {readOnly ? (
                  formData.sPassNo?.toUpperCase()
                ) : (
                  <Input
                    value={formData.sPassNo}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({ sPassNo: event.target.value.toUpperCase() })
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        {(formData.identityType === 'sg01' || formData.identityType === 'sg02') && (
          <Row>
            <Col span={24}>
              <Form.Item label="CPF type" validateStatus={errors?.cpfType ? 'error' : ''} help={errors?.cpfType}>
                {readOnly ? (
                  cpfTypes[formData.cpfType]?.value
                ) : (
                  <SysOptions
                    type="cpf_type"
                    value={formData.cpfType}
                    onFilter={(value: KeyValue | undefined) => {
                      if (formData.identityType === 'sg01') {
                        return CpfType.CITIZEN === value?.key
                      }
                      return true
                    }}
                    onChange={(value: string) => handleFormDataChange({ cpfType: value })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        {(formData.identityType === 'sg06' || formData.identityType === 'sg07') && (
          <Row>
            <Col span={24}>
              <Form.Item label="FWL category" validateStatus={errors?.fwlCode ? 'error' : ''} help={errors?.fwlCode}>
                {readOnly ? (
                  fwlCodes[formData.fwlCode]?.value
                ) : (
                  <EmpKeyValues
                    id="fwl"
                    value={formData.fwlCode}
                    onChange={(value: string) => handleFormDataChange({ fwlCode: value })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item label="Notes">
              <Form.Item>
                {readOnly ? (
                  formData.notes || '-'
                ) : (
                  <Input.TextArea
                    value={formData.notes}
                    rows={4}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                      handleFormDataChange({ notes: event.target.value })
                    }
                  />
                )}
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
