import React, { FC, useCallback, useEffect, useMemo, useState, ChangeEvent, HTMLAttributes } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { useSelector } from 'react-redux'
import { Form, Input, Radio, RadioChangeEvent, Space, Select, Skeleton, SecondaryText } from '~/core-components'
import { Row, Col, SalaryInput, SysOptions, ErrorDisplay, InfoTooltip } from '~/components'
import { formatAddress } from '~/features/master'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { formatMoney } from '~/utils'
import { Ir8aFormState } from '../../../types'
import { dispatch } from '~/stores/store'
import { addIr8aDraft, deleteIr8aDraft, fetchIr8aForm, updateIr8aDraft } from '../../../actions'
import { selectIr8aForm } from '../../../selectors'
import {
  removeA8aForm,
  removeA8bForm,
  removeIr8aForm,
  removeIr8sForm,
  setA8aFormRefetch,
  setIr8aFormRefetch,
  setIr8sFormRefetch
} from '../../../reducers'
import { YtdEmptyForm } from '../YtdEmptyForm/YtdEmptyForm'
import './Ir8aForm.less'

interface Ir8aFormProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  ytdYear: string
  employerTaxNo: string
  employeeTaxNo: string
  ytdSubmissionId: string
  readOnly?: boolean
  onFormChange: (hasChange: boolean) => void

  // Trigger action from parent component
  triggerUpdate: number
  triggerDelete: number
  triggerDiscard: number
}

const EMPTY_FORM_DATA: Ir8aFormState = {
  id: '',
  ytdSubmissionId: '',
  employeeTaxNo: '',
  employerTaxNo: '',
  employeeTaxNoType: '',
  employeeName: '',
  hireDate: '',
  employmentEndDate: '',
  irasNationality: '',
  nationalityName: '',
  gender: '',
  genderCode: '',
  birthDate: '',
  job: '',
  irasBank: '',
  bankName: '',
  addressType: '',
  addressBlock: '',
  addressStreet: '',
  addressLevel: '',
  addressUnit: '',
  addressPostalCode: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressCountryCode: '',
  addressCountryName: '',
  irasCountry: '',
  serviceLength: '',
  cpfEm: 0.0,
  cpfEr: 0.0,
  cpfErOverseas: 0.0,
  cpfErOvsWithTaxAmt: 0.0,
  cpfErOvsWithTaxClaimed: false,
  cpfErOvsWithTaxMandatory: false,
  cpfErOvsWithTaxName: '',
  cpfErVoluntary: 0.0,
  fundCdac: 0.0,
  fundComchest: 0.0,
  fundEcf: 0.0,
  fundMbf: 0.0,
  fundMendaki: 0.0,
  fundOther: 0.0,
  fundSinda: 0.0,
  grossAmt: 0.0,
  bonusAmt: 0.0,
  bonusDate: '',
  commissionAmt: 0.0,
  commissionType: '',
  commissionStartDate: '',
  commissionEndDate: '',
  compensationAmt: 0.0,
  compensationApproval: false,
  compensationApprovalDate: '',
  directorFeeAmt: 0.0,
  directorFeeDate: '',
  entertainmentAmt: 0.0,
  exemptIncomeAmt: 0.0,
  exemptIncomeType: '',
  exgratiaAmt: 0.0,
  gratuityAmt: 0.0,
  insuranceAmt: 0.0,
  noticeAmt: 0.0,
  otherAmt: 0.0,
  otherLumpsumAmt: 0.0,
  pensionAmt: 0.0,
  retirementAmt: 0.0,
  retirement2Amt: 0.0,
  taxBorneAmt: 0.0,
  taxBorneBy: '',
  transportAmt: 0.0,
  lumpsumReason: '',
  lumpsumBasis: '',
  overseasPosting: '',
  pensionFundName: '',
  providentFundName: '',
  s45Indicator: '',
  cessationIndicator: '',
  ir8sIndicator: '',
  cpfErExcess: 0.0,
  gainTotalBefore: 0.0,
  gainTotalAfter: 0.0,
  bikTotal: 0.0,
  cpfEmAdj: 0.0,
  cpfErAdj: 0.0,
  cpfErOverseasAdj: 0.0,
  cpfErVoluntaryAdj: 0.0,
  fundCdacAdj: 0.0,
  fundComchestAdj: 0.0,
  fundEcfAdj: 0.0,
  fundMbfAdj: 0.0,
  fundMendakiAdj: 0.0,
  fundOtherAdj: 0.0,
  fundSindaAdj: 0.0,
  grossAdj: 0.0,
  bonusAdj: 0.0,
  commissionAdj: 0.0,
  compensationAdj: 0.0,
  directorFeeAdj: 0.0,
  entertainmentAdj: 0.0,
  exgratiaAdj: 0.0,
  gratuityAdj: 0.0,
  insuranceAdj: 0.0,
  noticeAdj: 0.0,
  otherAdj: 0.0,
  otherLumpsumAdj: 0.0,
  pensionAdj: 0.0,
  retirementAdj: 0.0,
  retirement2Adj: 0.0,
  transportAdj: 0.0,
  cpfEmTotal: 0.0,
  cpfErTotal: 0.0,
  cpfErOverseasTotal: 0.0,
  cpfErVoluntaryTotal: 0.0,
  fundCdacTotal: 0.0,
  fundComchestTotal: 0.0,
  fundEcfTotal: 0.0,
  fundMbfTotal: 0.0,
  fundMendakiTotal: 0.0,
  fundOtherTotal: 0.0,
  fundSindaTotal: 0.0,
  grossTotal: 0.0,
  bonusTotal: 0.0,
  commissionTotal: 0.0,
  compensationTotal: 0.0,
  directorFeeTotal: 0.0,
  entertainmentTotal: 0.0,
  exgratiaTotal: 0.0,
  gratuityTotal: 0.0,
  insuranceTotal: 0.0,
  noticeTotal: 0.0,
  otherTotal: 0.0,
  otherLumpsumTotal: 0.0,
  pensionTotal: 0.0,
  retirementTotal: 0.0,
  retirement2Total: 0.0,
  transportTotal: 0.0,
  cpfRadio: '',

  // readonly fields
  allowanceAmt: 0.0,
  lumpsumPaymentAmt: 0.0,
  totalOthersAmt: 0.0,
  totalDonationsAmt: 0.0,

  // audit
  lockedBy: '',
  lockedDate: ''
}

const opStyle = { width: 150 }

export const Ir8aForm: FC<Ir8aFormProps> = ({
  ytdYear,
  employerTaxNo,
  employeeTaxNo,
  ytdSubmissionId,
  readOnly,
  onFormChange,
  triggerUpdate,
  triggerDelete,
  triggerDiscard,
  ...divProps
}) => {
  const emptyFormData: Ir8aFormState = useMemo(
    () => ({ ...EMPTY_FORM_DATA, ytdSubmissionId, employeeTaxNo, employerTaxNo }),
    [ytdSubmissionId, employeeTaxNo, employerTaxNo]
  )
  const [formData, setFormData] = useState<Ir8aFormState>(emptyFormData)
  const [errors, setErrors] = useState<Errors>()
  const ir8aForm = useSelector(selectIr8aForm)(ytdSubmissionId, employeeTaxNo)
  const hasData = !!ir8aForm
  const loading = useSelector((state: StoreState) => state.tax.ir8aFormLoading || false)
  const refetch = useSelector((state: StoreState) => state.tax.ir8aFormRefetch)

  const {
    addressBlock,
    addressStreet,
    addressLevel,
    addressUnit,
    addressPostalCode,
    addressCountryCode,
    addressCountryName
  } = formData

  const address = useMemo(
    () =>
      formatAddress(
        {
          block: addressBlock,
          street: addressStreet,
          level: addressLevel,
          unit: addressUnit,
          building: '',
          city: '',
          state: '',
          postalCode: addressPostalCode,
          countryCode: addressCountryCode
        },
        addressCountryName
      ),
    [addressBlock, addressStreet, addressLevel, addressUnit, addressPostalCode, addressCountryCode, addressCountryName]
  )

  useEffect(() => {
    dispatch(fetchIr8aForm(ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId))
  }, [refetch, ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId])

  useEffect(() => {
    if (ir8aForm) {
      setFormData(ir8aForm)
    } else {
      setFormData(emptyFormData)
    }
  }, [ir8aForm, emptyFormData])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }

        if ('grossTotal' in updates) updated.grossAdj = updated.grossTotal - updated.grossAmt
        if ('bonusTotal' in updates) updated.bonusAdj = updated.bonusTotal - updated.bonusAmt
        if ('directorFeeTotal' in updates) updated.directorFeeAdj = updated.directorFeeTotal - updated.directorFeeAmt
        if ('transportTotal' in updates) updated.transportAdj = updated.transportTotal - updated.transportAmt
        if ('entertainmentTotal' in updates)
          updated.entertainmentAdj = updated.entertainmentTotal - updated.entertainmentAmt
        if ('otherTotal' in updates) updated.otherAdj = updated.otherTotal - updated.otherAmt
        if ('commissionTotal' in updates) updated.commissionAdj = updated.commissionTotal - updated.commissionAmt
        if ('pensionTotal' in updates) updated.pensionAdj = updated.pensionTotal - updated.pensionAmt
        if ('gratuityTotal' in updates) updated.gratuityAdj = updated.gratuityTotal - updated.gratuityAmt
        if ('noticeTotal' in updates) updated.noticeAdj = updated.noticeTotal - updated.noticeAmt
        if ('exgratiaTotal' in updates) updated.exgratiaAdj = updated.exgratiaTotal - updated.exgratiaAmt
        if ('otherLumpsumTotal' in updates)
          updated.otherLumpsumAdj = updated.otherLumpsumTotal - updated.otherLumpsumAmt
        if ('compensationTotal' in updates)
          updated.compensationAdj = updated.compensationTotal - updated.compensationAmt
        if ('retirementTotal' in updates) updated.retirementAdj = updated.retirementTotal - updated.retirementAmt
        if ('retirement2Total' in updates) updated.retirement2Adj = updated.retirement2Total - updated.retirement2Amt
        if ('cpfErOverseasTotal' in updates)
          updated.cpfErOverseasAdj = updated.cpfErOverseasTotal - updated.cpfErOverseas
        if ('cpfEmTotal' in updates) updated.cpfEmAdj = updated.cpfEmTotal - updated.cpfEm
        if ('fundMendakiTotal' in updates) updated.fundMendakiAdj = updated.fundMendakiTotal - updated.fundMendaki
        if ('fundComchestTotal' in updates) updated.fundComchestAdj = updated.fundComchestTotal - updated.fundComchest
        if ('fundSindaTotal' in updates) updated.fundSindaAdj = updated.fundSindaTotal - updated.fundSinda
        if ('fundCdacTotal' in updates) updated.fundCdacAdj = updated.fundCdacTotal - updated.fundCdac
        if ('fundEcfTotal' in updates) updated.fundEcfAdj = updated.fundEcfTotal - updated.fundEcf
        if ('fundOtherTotal' in updates) updated.fundOtherAdj = updated.fundOtherTotal - updated.fundOther
        if ('fundMbfTotal' in updates) updated.fundMbfAdj = updated.fundMbfTotal - updated.fundMbf
        if ('insuranceTotal' in updates) updated.insuranceAdj = updated.insuranceTotal - updated.insuranceAmt

        updated.allowanceAmt = updated.transportTotal + updated.entertainmentTotal + updated.otherTotal

        updated.lumpsumPaymentAmt =
          updated.gratuityTotal + updated.noticeTotal + updated.exgratiaTotal + updated.otherLumpsumTotal

        updated.totalOthersAmt = Math.floor(
          updated.allowanceAmt +
            updated.commissionTotal +
            updated.pensionTotal +
            updated.lumpsumPaymentAmt +
            updated.retirement2Total +
            updated.cpfErOverseasTotal +
            updated.cpfErExcess +
            updated.gainTotalAfter +
            updated.bikTotal
        )

        updated.totalDonationsAmt =
          updated.fundMendakiTotal +
          updated.fundComchestTotal +
          updated.fundSindaTotal +
          updated.fundCdacTotal +
          updated.fundEcfTotal +
          updated.fundOtherTotal

        if (updated.cpfRadio === 'cpf') updated.pensionFundName = ''

        return updated
      })

      onFormChange(true)
    },
    [onFormChange]
  )

  const handleCreateForm = useCallback(async () => {
    const result: ActionResult | undefined = await dispatch(
      addIr8aDraft({ ytdSubmissionId, employeeTaxNos: [formData.employeeTaxNo] })
    )

    if (!result?.errors) {
      dispatch(setIr8aFormRefetch())
      dispatch(setIr8sFormRefetch())
      dispatch(setA8aFormRefetch())
    }
  }, [ytdSubmissionId, formData.employeeTaxNo])

  const handleUpdateForm = useCallback(async () => {
    setErrors(undefined)

    const result = await dispatch(updateIr8aDraft(formData))

    if (result?.errors) {
      setErrors(result.errors)
    } else {
      onFormChange(false)
    }
  }, [formData, onFormChange])

  const handleDeleteForm = useCallback(async () => {
    confirm({
      title: 'Delete',
      content: (
        <>
          <div>Do you want to delete this IR8A?</div>
          <br />
          <div>This will also delete (if any) the following:</div>
          <div>&#9;- IR8S</div>
          <div>&#9;- Appendix 8A</div>
          <div>&#9;- Appendix 8B</div>
        </>
      ),
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteIr8aDraft(formData.id))

        if (!result?.errors) {
          dispatch(removeIr8aForm(formData.employeeTaxNo))
          dispatch(removeIr8sForm(formData.employeeTaxNo))
          dispatch(removeA8aForm(formData.employeeTaxNo))
          dispatch(removeA8bForm(formData.employeeTaxNo))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [formData])

  const handleDiscardForm = useCallback(() => {
    if (ir8aForm) {
      setFormData(ir8aForm)
    }
  }, [ir8aForm])

  useEffect(() => {
    if (triggerUpdate > 0) handleUpdateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerUpdate])

  useEffect(() => {
    if (triggerDelete > 0) handleDeleteForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDelete])

  useEffect(() => {
    if (triggerDiscard > 0) handleDiscardForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDiscard])

  if (loading)
    return (
      <div className="ir8a">
        <div className="ir8a-form">
          <Skeleton active />
        </div>
      </div>
    )

  if (!hasData)
    return (
      <YtdEmptyForm onCreate={handleCreateForm}>
        <SecondaryText>There is no IR8A for the selected employee.</SecondaryText>
      </YtdEmptyForm>
    )

  return (
    <div {...divProps} className="ir8a">
      <div className="ir8a-form mt-10 pt15">
        <ErrorDisplay errors={errors} />
        <div>
          <div className="ir8a-form__bordered-row">
            <div className="ir8a-form__bordered maxW457 bdr-l bdr-t bdr-r">
              <div className="ir8a-form__nowrap w44">Employer's Tax Ref. No. / UEN</div>
              <div className="ir8a-form__header">{employerTaxNo}</div>
            </div>
            <div className="ir8a-form__bordered minW630 bdr-t bdr-r">
              <div className="ir8a-form__nowrap w67">
                Employee's Tax Ref. No. : *NRIC / FIN (Foreign Identification No.)
              </div>
              <div className="ir8a-form__header">{formData.employeeTaxNo}</div>
            </div>
          </div>
          <div className="ir8a-form__bordered-row">
            <div className="ir8a-form__bordered bdr-l bdr-t bdr-r">
              <div className="ir8a-form__nowrap w44">Full Name of Employee as per NRIC / FIN</div>
              <div className="ir8a-form__header">{formData.employeeName}</div>
            </div>
            <div className="ir8a-form__bordered bdr-t bdr-r maxW250">
              <div className="w30">Date of Birth</div>
              <div className="ir8a-form__header">
                {formData.birthDate ? moment(formData.birthDate).format('DD MMM YYYY') : ''}
              </div>
            </div>
            <div className="ir8a-form__bordered bdr-t bdr-r">
              <div className="w15">Sex</div>
              <div className="ir8a-form__header">{formData.gender}</div>
            </div>
            <div className="ir8a-form__bordered bdr-t bdr-r">
              <div className="w15">Nationality</div>
              <div className="ir8a-form__header">{formData.nationalityName}</div>
            </div>
          </div>
          <div className="ir8a-form__bordered-row">
            <div className="ir8a-form__bordered maxW457 bdr-l bdr-t bdr-r">
              <div className="w44">Residential Address</div>
              <div className="ir8a-form__header">
                {address.addressLine1} {address.addressLine2}
              </div>
            </div>
            <div className="ir8a-form__bordered bdr-t bdr-r maxW250">
              <div className="w30">Designation</div>
              <div className="ir8a-form__header">{formData.job}</div>
            </div>
            <div className="ir8a-form__bordered bdr-t bdr-r">
              <div className="w25">Bank to which salary is credited</div>
              <div className="ir8a-form__header">{formData.bankName}</div>
            </div>
          </div>
          <div className="ir8a-form__bordered-row" style={{ minHeight: 45 }}>
            <div className="ir8a-form__bordered maxW457 bdr-l bdr-t bdr-r bdr-b">
              <div className="ir8a-form__nowrap w44">If employment commenced and/or ceased during the year, state:</div>
            </div>
            <div className="ir8a-form__bordered bdr-t bdr-r bdr-b maxW250">
              <div className="w30">Date of Commencement</div>
              <div className="ir8a-form__header">
                {formData.hireDate ? moment(formData.hireDate).format('DD MMM YYYY') : ''}
              </div>
            </div>
            <div className="ir8a-form__bordered bdr-t bdr-r bdr-b">
              <div className="w25">Date of Cessation</div>
              <div className="ir8a-form__header">
                {formData.employmentEndDate ? moment(formData.employmentEndDate).format('DD MMM YYYY') : ''}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="ir8a-form__title">INCOME</div>
          <div className="ir8a-form__field">
            <div className="ir8a-form__field-name">
              <div>a) Gross Salary, Fees, Leave Pay, Wages and Overtime Pay</div>
            </div>
            <div className="ir8a-form__field-action">
              <SalaryInput
                size="small"
                value={formData.grossTotal}
                onChange={(grossTotal: number | null) => handleFormDataChange({ grossTotal })}
                readOnly={readOnly}
              />
              <Ir8aTooltip originalAmt={formData.grossAmt} adjustmentAmt={formData.grossAdj} />
            </div>
          </div>
          <div className="ir8a-form__field">
            <div className="ir8a-form__field-name">
              <div>
                b) Bonus (non-contractual bonus paid on &nbsp;
                <Form.Item noStyle>
                  <Input.Date
                    size="small"
                    className="ir8a-form__input"
                    value={formData.bonusDate ? moment(formData.bonusDate) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({ bonusDate: value?.format('YYYY-MM-DD') })
                    }
                    inputReadOnly={readOnly}
                  />
                </Form.Item>
                &nbsp; and / or contractual bonus)
              </div>
            </div>
            <div className="ir8a-form__field-action">
              <SalaryInput
                size="small"
                value={formData.bonusTotal}
                onChange={(bonusTotal: number | null) => handleFormDataChange({ bonusTotal })}
                readOnly={readOnly}
              />
              <Ir8aTooltip originalAmt={formData.bonusAmt} adjustmentAmt={formData.bonusAdj} />
            </div>
          </div>
          <div className="ir8a-form__field">
            <div className="ir8a-form__field-name">
              <div className="ir8a-form__field-rowb">
                <div className="pr60">
                  c) Director's fees (approved at the company's AGM/EGM on &nbsp;
                  <Form.Item noStyle>
                    <Input.Date
                      size="small"
                      className="ir8a-form__input"
                      value={formData.directorFeeDate ? moment(formData.directorFeeDate) : undefined}
                      onChange={(value: moment.Moment | null) =>
                        handleFormDataChange({ directorFeeDate: value?.format('YYYY-MM-DD') })
                      }
                      inputReadOnly={readOnly}
                    />
                  </Form.Item>
                  )
                </div>
                <div>
                  Section 45 applicable (for foreign director's withholding tax)
                  <InfoTooltip title="For non–resident director where withholding of tax on director’s fees may be applicable. Leave it blank for resident director." />
                </div>
                <div className="ir8a-form__field-content ml15">
                  <SysOptions
                    type="yes_no_yn"
                    size="small"
                    readOnly={readOnly}
                    className="ir8a-form__field-selectyn"
                    value={formData.s45Indicator}
                    onChange={(s45Indicator?: string) => handleFormDataChange({ s45Indicator })}
                  />
                </div>
              </div>
            </div>
            <div className="ir8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.directorFeeTotal}
                onChange={(directorFeeTotal: number | null) => handleFormDataChange({ directorFeeTotal })}
              />
              <Ir8aTooltip originalAmt={formData.directorFeeAmt} adjustmentAmt={formData.directorFeeAdj} />
            </div>
          </div>
          <div className="ir8a-form__field">
            <div className="ir8a-form__field-name">
              <div>d) Others:</div>
              <div className="ir8a-form__field-row pr60">
                <div className="ir8a-form__field-content">1. Allowances:</div>
                <div className="ir8a-form__field-content ta-r">(i) Transport</div>
                <div className="ir8a-form__field-content" style={{ position: 'relative' }}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.transportTotal}
                    onChange={(transportTotal: number | null) => handleFormDataChange({ transportTotal })}
                  />
                  <Ir8aTooltip originalAmt={formData.transportAmt} adjustmentAmt={formData.transportAdj} />
                </div>
                <div className="ir8a-form__field-content ta-r">(ii) Entertainment</div>
                <div className="ir8a-form__field-content" style={{ position: 'relative' }}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.entertainmentTotal}
                    onChange={(entertainmentTotal: number | null) => handleFormDataChange({ entertainmentTotal })}
                  />
                  <Ir8aTooltip originalAmt={formData.entertainmentAmt} adjustmentAmt={formData.entertainmentAdj} />
                </div>

                <div className="ir8a-form__field-content ta-r">(iii) Others</div>
                <div className="ir8a-form__field-content mr80" style={{ position: 'relative' }}>
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.otherTotal}
                    onChange={(otherTotal: number | null) => handleFormDataChange({ otherTotal })}
                  />
                  <Ir8aTooltip originalAmt={formData.otherAmt} adjustmentAmt={formData.otherAdj} />
                </div>

                <div className="ir8a-form__field-actionro">{formatMoney(formData.allowanceAmt, 2)}</div>
              </div>
              <div className="ir8a-form__field-row pr60">
                <div className="ir8a-form__field-content maxW20vw">2. Gross Commission for the period:</div>
                <div className="ir8a-form__field-content--dp">
                  <Input.Date
                    size="small"
                    inputReadOnly={readOnly}
                    className="ir8a-form__input"
                    value={formData.commissionStartDate ? moment(formData.commissionStartDate) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({ commissionStartDate: value?.format('YYYY-MM-DD') })
                    }
                  />
                </div>
                <div className="ir8a-form__field-content--ac">to</div>
                <div className="ir8a-form__field-content--dp">
                  <Input.Date
                    size="small"
                    inputReadOnly={readOnly}
                    className="ir8a-form__input"
                    value={formData.commissionEndDate ? moment(formData.commissionEndDate) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({ commissionEndDate: value?.format('YYYY-MM-DD') })
                    }
                  />
                </div>
                <div className="ir8a-form__field-content minW407">
                  <SysOptions
                    type="iras_commission_type"
                    size="small"
                    readOnly={readOnly}
                    className="ir8a-form__input-select"
                    value={formData.commissionType}
                    onChange={(value: number | null) => handleFormDataChange({ commissionType: value })}
                  />
                </div>
                <div className="ir8a-form__field-action">
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.commissionTotal}
                    onChange={(commissionTotal: number | null) => handleFormDataChange({ commissionTotal })}
                  />
                  <Ir8aTooltip originalAmt={formData.commissionAmt} adjustmentAmt={formData.commissionAdj} />
                </div>
              </div>
              <div className="ir8a-form__field-row pr60">
                <div className="ir8a-form__field-content">3. Pension</div>
                <div className="ir8a-form__field-action">
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.pensionTotal}
                    onChange={(pensionTotal: number | null) => handleFormDataChange({ pensionTotal })}
                  />
                  <Ir8aTooltip originalAmt={formData.pensionAmt} adjustmentAmt={formData.pensionAdj} />
                </div>
              </div>
              <div className="ir8a-form__field-row pr60 mb5">
                <div className="ir8a-form__field-content">4i. Gratuity/ Notice Pay/ Ex-gratia payment/ Others</div>
                <div className="ir8a-form__field-actionro">{formatMoney(formData.lumpsumPaymentAmt, 2)}</div>
              </div>
              <div className="ir8a-form__field-rowb">
                <div className="ir8a-form__field-innertable">
                  <div className="ir8a-form__field-rowb--bordered bdr-l bdr-t p5">
                    <div className="ir8a-form__field-content minW232">(i) Gratuity</div>
                    <div className="ir8a-form__field-action">
                      <SalaryInput
                        size="small"
                        readOnly={readOnly}
                        value={formData.gratuityTotal}
                        onChange={(gratuityTotal: number | null) => handleFormDataChange({ gratuityTotal })}
                      />
                      <Ir8aTooltip originalAmt={formData.gratuityAmt} adjustmentAmt={formData.gratuityAdj} />
                    </div>
                  </div>
                  <div className="ir8a-form__field-rowb--bordered bdr-t pl2vw p5">
                    <div className="ir8a-form__field-content maxW140">(ii) Notice Pay</div>
                    <div className="ir8a-form__field-action">
                      <SalaryInput
                        size="small"
                        readOnly={readOnly}
                        value={formData.noticeTotal}
                        onChange={(noticeTotal: number | null) => handleFormDataChange({ noticeTotal })}
                      />
                      <Ir8aTooltip originalAmt={formData.noticeAmt} adjustmentAmt={formData.noticeAdj} />
                    </div>
                  </div>
                  <div className="ir8a-form__field-rowb--bordered bdr-t bdr-r p5 pr28"></div>
                </div>
              </div>
              <div className="ir8a-form__field-rowb">
                <div className="ir8a-form__field-innertable">
                  <div className="ir8a-form__field-rowb--bordered bdr-l bdr-t bdr-b p5">
                    <div className="ir8a-form__field-content minW232">(iii) Ex-gratia payment</div>
                    <div className="ir8a-form__field-action">
                      <SalaryInput
                        size="small"
                        readOnly={readOnly}
                        value={formData.exgratiaTotal}
                        onChange={(exgratiaTotal: number | null) => handleFormDataChange({ exgratiaTotal })}
                      />
                      <Ir8aTooltip originalAmt={formData.exgratiaAmt} adjustmentAmt={formData.exgratiaAdj} />
                    </div>
                  </div>
                  <div className="ir8a-form__field-rowb--bordered bdr-t bdr-b pl2vw p5">
                    <div className="ir8a-form__field-content maxW140">(iv) Others</div>
                    <div className="ir8a-form__field-action">
                      <SalaryInput
                        size="small"
                        readOnly={readOnly}
                        value={formData.otherLumpsumTotal}
                        onChange={(otherLumpsumTotal: number | null) => handleFormDataChange({ otherLumpsumTotal })}
                      />
                      <Ir8aTooltip originalAmt={formData.otherLumpsumAmt} adjustmentAmt={formData.otherLumpsumAdj} />
                    </div>
                  </div>
                  <div className="ir8a-form__field-rowb--bordered bdr-t bdr-b bdr-r p5 pr28"></div>
                </div>
              </div>
              <div className="ir8a-form__field-row pr60 mb5">
                <div className="ir8a-form__field-content">4ii. Compensation for loss of office amount</div>
                <div className="ir8a-form__field-action">
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.compensationTotal}
                    onChange={(compensationTotal: number | null) => handleFormDataChange({ compensationTotal })}
                  />
                  <Ir8aTooltip originalAmt={formData.compensationAmt} adjustmentAmt={formData.compensationAdj} />
                </div>
              </div>
              <div className="ir8a-form__field-rowb">
                <div className="ir8a-form__field-innertable">
                  <div className="ir8a-form__field-rowb">
                    <div className="ir8a-form__field-content minW232">Approval obtained from IRAS</div>
                    <div className="ir8a-form__field-action">
                      <SysOptions
                        type="yes_no"
                        size="small"
                        readOnly={readOnly}
                        className="ir8a-form__field-selectyn"
                        value={formData.compensationApproval?.toString()}
                        onChange={(compensationApproval?: string) => handleFormDataChange({ compensationApproval })}
                      />
                    </div>
                  </div>
                  <div className="ir8a-form__field-rowb pl2vw">
                    <div className="ir8a-form__field-content maxW140">Date approval &nbsp;</div>
                    <div className="ir8a-form__field-action">
                      <Form.Item noStyle>
                        <Input.Date
                          size="small"
                          inputReadOnly={readOnly}
                          className="ir8a-form__input"
                          value={
                            formData.compensationApprovalDate ? moment(formData.compensationApprovalDate) : undefined
                          }
                          onChange={(value: moment.Moment | null) =>
                            handleFormDataChange({ compensationApprovalDate: value?.format('YYYY-MM-DD') })
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="ir8a-form__field-rowb p5 pr28"></div>
                </div>
              </div>
              <div className="ir8a-form__field-row pr60">
                <div className="ir8a-form__field-content minH31 pt4">
                  5. Retirement benefits including gratuities/pension/commutation of pension/lump sum payments, etc from
                  pension/provident fund:
                </div>
              </div>
              <div className="ir8a-form__field-row">
                <div className="ir8a-form__field-content--ml">
                  <Row gutter={30}>
                    <Col className="minW246 pt4">Name of Fund</Col>
                    <Col>
                      <Form.Item noStyle>
                        <Input
                          size="small"
                          readOnly={readOnly}
                          className="ir8a-form__input"
                          value={formData.providentFundName}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleFormDataChange({ providentFundName: event.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="ir8a-form__field-content"></div>
              </div>
              <div className="ir8a-form__field-row pr60">
                <div className="ir8a-form__field-content--ml maxW240">Amount accrued up to 31 Dec 1992</div>
                <div className="ir8a-form__field-action">
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.retirementTotal}
                    onChange={(retirementTotal: number | null) => handleFormDataChange({ retirementTotal })}
                  />
                  <Ir8aTooltip originalAmt={formData.retirementAmt} adjustmentAmt={formData.retirementAdj} />
                </div>
                <div className="ir8a-form__field-content ml345">Amount accrued from 1993:</div>
                <div className="ir8a-form__field-action">
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.retirement2Total}
                    onChange={(retirement2Total: number | null) => handleFormDataChange({ retirement2Total })}
                  />
                  <Ir8aTooltip originalAmt={formData.retirement2Amt} adjustmentAmt={formData.retirement2Adj} />
                </div>
              </div>
              <div className="ir8a-form__field-row pr60">
                <div className="ir8a-form__field-content minH62 lh2">
                  6. Contributions made by employer to any Pension/Provident Fund constituted outside Singapore without
                  tax concession:
                  <br />
                  &nbsp;&nbsp;&nbsp; Contributions made by employer to any Pension/Provident Fund constituted outside
                  Singapore with tax concession:
                </div>
                <div className="ir8a-form__field-action">
                  <SalaryInput
                    size="small"
                    readOnly={readOnly}
                    value={formData.cpfErOverseasTotal}
                    onChange={(cpfErOverseasTotal: number | null) => handleFormDataChange({ cpfErOverseasTotal })}
                  />
                  <Ir8aTooltip originalAmt={formData.cpfErOverseas} adjustmentAmt={formData.cpfErOverseasAdj} />
                </div>
              </div>
              <div className="ir8a-form__field-rowb">
                <div className="ir8a-form__field-innertable">
                  <div className="ir8a-form__field-rowb--bordered bdr-l bdr-t bdr-r p5">
                    <div className="ir8a-form__field-content maxW341">Name of the overseas pension/provident fund:</div>
                    <div className="ir8a-form__field-content">
                      <Form.Item noStyle>
                        <Input
                          size="small"
                          readOnly={readOnly}
                          className="ir8a-form__input"
                          value={formData.cpfErOvsWithTaxName}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleFormDataChange({ cpfErOvsWithTaxName: event.target.value })
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ir8a-form__field-rowb">
                <div className="ir8a-form__field-innertable">
                  <div className="ir8a-form__field-rowb--bordered bdr-l bdr-t p5">
                    <div className="ir8a-form__field-content maxW349 minW349">Full amount of the contributions:</div>
                    <div className="ir8a-form__field-action mr5">
                      <SalaryInput
                        size="small"
                        readOnly={readOnly}
                        value={formData.cpfErOvsWithTaxAmt}
                        onChange={(cpfErOvsWithTaxAmt: number | null) => handleFormDataChange({ cpfErOvsWithTaxAmt })}
                      />
                    </div>
                  </div>
                  <div className="ir8a-form__field-rowb--bordered bdr-t bdr-r p5">
                    <div className="ir8a-form__field-content">Are contributions mandatory:</div>
                    <div className="ir8a-form__field-content">
                      <SysOptions
                        type="yes_no"
                        size="small"
                        readOnly={readOnly}
                        className="ir8a-form__field-selectyn"
                        value={formData.cpfErOvsWithTaxMandatory?.toString()}
                        onChange={(cpfErOvsWithTaxMandatory?: string) =>
                          handleFormDataChange({ cpfErOvsWithTaxMandatory })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ir8a-form__field-rowb">
                <div className="ir8a-form__field-innertable">
                  <div className="ir8a-form__field-rowb--bordered bdr-l bdr-t bdr-r bdr-b p5">
                    <div className="ir8a-form__field-content">
                      Were contributions changed / deductions claimed by a Singapore permanent establishment:
                    </div>
                    <div className="ir8a-form__field-content">
                      <SysOptions
                        type="yes_no"
                        size="small"
                        readOnly={readOnly}
                        className="ir8a-form__field-selectyn"
                        value={formData.cpfErOvsWithTaxClaimed?.toString()}
                        onChange={(cpfErOvsWithTaxClaimed?: string) => handleFormDataChange({ cpfErOvsWithTaxClaimed })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ir8a-form__field-row pr60 mt10">
                <div className="ir8a-form__field-content minH62 lh2">
                  7. Excess/Voluntary contribution to CPF by employer (less amount refunded/to be refunded):
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;(Give details in Form IR8S)
                </div>
                <div className="ir8a-form__field-actionro">{formatMoney(formData.cpfErExcess, 2)}</div>
              </div>
              <div className="ir8a-form__field-row pr60">
                <div className="ir8a-form__field-content">
                  8i. Gains and profits from share option for sec.10(1)(b):
                  <br />
                </div>
                <div className="ir8a-form__field-actionro">{formatMoney(formData.gainTotalAfter, 2)}</div>
              </div>
              <div className="ir8a-form__field-row pr60">
                <div className="ir8a-form__field-content minH62 lh2">
                  &nbsp;&nbsp;ii. Gains and profits from share option for sec.10(1)(g):
                  <br />
                  &nbsp;&nbsp;(Give details in Appendix 8B)
                </div>
                <div className="ir8a-form__field-actionro">{formatMoney(formData.gainTotalBefore, 2)}</div>
              </div>
              <div className="ir8a-form__field-row pr60">
                <div className="ir8a-form__field-content">
                  9. Value of Benefits-in-kind (Give details in Appendix 8A)
                </div>
                <div className="ir8a-form__field-actionro">{formatMoney(formData.bikTotal, 2)}</div>
              </div>
            </div>
          </div>
          <div className="ir8a-form__field">
            <div className="ir8a-form__field-name">
              <div className="ir8a-form__field-contenttotal">Total of items d1 to d9 (excluding 4ii & 8ii)</div>
            </div>
            <div className="ir8a-form__field-actionro">{formatMoney(formData.totalOthersAmt, 2)}</div>
          </div>
          <div className="ir8a-form__field">
            <div className="ir8a-form__field-name">
              <div className="ir8a-form__field-row">
                <div className="minW342 pt4">
                  e) Remission / Overseas Posting / Exempt Indicator : &nbsp;&nbsp;&nbsp;
                </div>
                <div className="ir8a-form__field-content">
                  <SysOptions
                    type="iras_exempt_income_type"
                    size="small"
                    readOnly={readOnly}
                    className="ir8a-form__input-selectlg"
                    dropdownMultiline={true}
                    value={formData.exemptIncomeType}
                    onChange={(exemptIncomeType?: string) => handleFormDataChange({ exemptIncomeType })}
                  />
                </div>
              </div>
            </div>
            <div className="ir8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={readOnly}
                value={formData.exemptIncomeAmt}
                onChange={(exemptIncomeAmt: number | null) => handleFormDataChange({ exemptIncomeAmt })}
              />
            </div>
          </div>
          <div className="mt-10">
            <div className="ir8a-form__field-row">
              <div className="ir8a-form__field-content--ml maxW522">1. Remission: Amount of Income </div>
              <div className="ir8a-form__field-content maxW150">
                <SalaryInput
                  size="small"
                  className="inputReadOnly"
                  readOnly
                  value={
                    ['3', '4', '5', '6', '7'].includes(formData.exemptIncomeType ? formData.exemptIncomeType : '')
                      ? 0.0
                      : formData.exemptIncomeAmt
                  }
                />
              </div>
            </div>
            <div className="ir8a-form__field-row">
              <div className="ir8a-form__field-content--ml maxW522">2. Overseas Posting: </div>
              <div className="ir8a-form__field-content maxW150">
                <Select
                  size="small"
                  readOnly={readOnly}
                  style={opStyle}
                  onChange={(value: number) => handleFormDataChange({ overseasPosting: value })}
                  value={formData.overseasPosting}
                >
                  <Select.Option value="f">Full Year</Select.Option>
                  <Select.Option value="p">Part of the Year</Select.Option>
                </Select>
              </div>
            </div>
            <div className="ir8a-form__field-row">
              <div className="ir8a-form__field-content--ml maxW522">3. Exempt Income: </div>
              <div className="ir8a-form__field-content maxW150">
                <SalaryInput
                  size="small"
                  className="inputReadOnly"
                  readOnly
                  value={
                    ['3', '4', '5', '6', '7'].includes(formData.exemptIncomeType ? formData.exemptIncomeType : '')
                      ? formData.exemptIncomeAmt
                      : 0.0
                  }
                />
              </div>
            </div>
          </div>

          <div className="ir8a-form__field">
            <div className="ir8a-form__field-name">
              <div className="ir8a-form__field-row">
                <div className="minW348 pt4">f) Employee's income tax</div>
                <div className="ir8a-form__field-content">
                  <SysOptions
                    type="iras_tax_borne_by"
                    size="small"
                    readOnly={readOnly}
                    className="ir8a-form__input-selectlg"
                    dropdownMultiline={true}
                    onChange={(value: number | string) =>
                      handleFormDataChange({
                        taxBorneBy: value,
                        taxBorneAmt: !(formData.taxBorneBy === 'p' || formData.taxBorneBy === 'h')
                          ? 0.0
                          : formData.taxBorneAmt
                      })
                    }
                    value={formData.taxBorneBy}
                  />
                </div>
              </div>
            </div>
            <div className="ir8a-form__field-action">
              <SalaryInput
                size="small"
                readOnly={!(formData.taxBorneBy === 'p' || formData.taxBorneBy === 'h') || readOnly}
                value={!(formData.taxBorneBy === 'p' || formData.taxBorneBy === 'h') ? 0.0 : formData.taxBorneAmt}
                onChange={(value: number | null) => handleFormDataChange({ taxBorneAmt: value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="ir8a-form__title">DEDUCTION</div>
        </div>
        <div className="ir8a-form__field">
          <div className="ir8a-form__field-name">
            <div>
              EMPLOYEE'S COMPULSORY contribution to CPF/Designated Pension or Provident Fund (less amount refunded/to be
              refunded)
            </div>
            <div className="ir8a-form__field-row mt5">
              <Radio.Group
                readOnly={readOnly}
                value={formData.cpfRadio}
                onChange={(event: RadioChangeEvent) => {
                  handleFormDataChange({ cpfRadio: event.target.value })
                }}
              >
                <Space direction="vertical">
                  <Radio value={'cpf'}>Central Provident Fund (CPF)</Radio>
                  <Radio value={'pfn'}>
                    Pension or Provident Fund Name: &nbsp;&nbsp;
                    <Form.Item noStyle>
                      <Input
                        size="small"
                        readOnly={readOnly}
                        className="ir8a-form__input w461"
                        value={formData.pensionFundName}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ pensionFundName: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Radio>
                </Space>
              </Radio.Group>
              <div className="ir8a-form__field-content"></div>
            </div>
          </div>
          <div className="ir8a-form__field-action">
            <SalaryInput
              size="small"
              readOnly={readOnly}
              value={formData.cpfEmTotal}
              onChange={(cpfEmTotal: number | null) => handleFormDataChange({ cpfEmTotal })}
            />
            <Ir8aTooltip originalAmt={formData.cpfEm} adjustmentAmt={formData.cpfEmAdj} />
          </div>
        </div>
        <div className="ir8a-form__field">
          <div className="ir8a-form__field-name">
            <div>DONATIONS deducted from salaries for:</div>
            <div className="ir8a-form__field-row">
              <div className="ir8a-form__field-content">a. Yayasan Mendaki Fund</div>
              <div className="ir8a-form__field-action">
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.fundMendakiTotal}
                  onChange={(fundMendakiTotal: number | null) => handleFormDataChange({ fundMendakiTotal })}
                />
                <Ir8aTooltip originalAmt={formData.fundMendaki} adjustmentAmt={formData.fundMendakiAdj} />
              </div>
            </div>
            <div className="ir8a-form__field-row">
              <div className="ir8a-form__field-content">b. Community Chest of Singapore</div>
              <div className="ir8a-form__field-action">
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.fundComchestTotal}
                  onChange={(fundComchestTotal: number | null) => handleFormDataChange({ fundComchestTotal })}
                />
                <Ir8aTooltip originalAmt={formData.fundComchest} adjustmentAmt={formData.fundComchestAdj} />
              </div>
            </div>
            <div className="ir8a-form__field-row">
              <div className="ir8a-form__field-content">c. Singapore Indian Development Association (SINDA)</div>
              <div className="ir8a-form__field-action">
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.fundSindaTotal}
                  onChange={(fundSindaTotal: number | null) => handleFormDataChange({ fundSindaTotal })}
                />
                <Ir8aTooltip originalAmt={formData.fundSinda} adjustmentAmt={formData.fundSindaAdj} />
              </div>
            </div>
            <div className="ir8a-form__field-row">
              <div className="ir8a-form__field-content">d. Chinese Development Assistance Council (CDAC)</div>
              <div className="ir8a-form__field-action">
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.fundCdacTotal}
                  onChange={(fundCdacTotal: number | null) => handleFormDataChange({ fundCdacTotal })}
                />
                <Ir8aTooltip originalAmt={formData.fundCdac} adjustmentAmt={formData.fundCdacAdj} />
              </div>
            </div>
            <div className="ir8a-form__field-row">
              <div className="ir8a-form__field-content">e. Eurasian Community Fund (ECF)</div>
              <div className="ir8a-form__field-action">
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.fundEcfTotal}
                  onChange={(fundEcfTotal: number | null) => handleFormDataChange({ fundEcfTotal })}
                />
                <Ir8aTooltip originalAmt={formData.fundEcf} adjustmentAmt={formData.fundEcfAdj} />
              </div>
            </div>
            <div className="ir8a-form__field-row">
              <div className="ir8a-form__field-content">f. Other tax exempt donations</div>
              <div className="ir8a-form__field-action">
                <SalaryInput
                  size="small"
                  readOnly={readOnly}
                  value={formData.fundOtherTotal}
                  onChange={(fundOtherTotal: number | null) => handleFormDataChange({ fundOtherTotal })}
                />
                <Ir8aTooltip originalAmt={formData.fundOther} adjustmentAmt={formData.fundOtherAdj} />
              </div>
            </div>
          </div>
          <div className="ir8a-form__field-actionro">{formatMoney(formData.totalDonationsAmt, 2)}</div>
        </div>
        <div className="ir8a-form__field">
          <div className="ir8a-form__field-name">
            <div>CONTRIBUTIONS deducted from salaries to Mosque Building Fund :</div>
          </div>
          <div className="ir8a-form__field-action">
            <SalaryInput
              size="small"
              readOnly={readOnly}
              value={formData.fundMbfTotal}
              onChange={(fundMbfTotal: number | null) => handleFormDataChange({ fundMbfTotal })}
            />
            <Ir8aTooltip originalAmt={formData.fundMbf} adjustmentAmt={formData.fundMbfAdj} />
          </div>
        </div>
        <div className="ir8a-form__field">
          <div className="ir8a-form__field-name">
            <div>LIFE INSURANCE PREMIUMS deducted from salaries :</div>
          </div>
          <div className="ir8a-form__field-action">
            <SalaryInput
              size="small"
              readOnly={readOnly}
              value={formData.insuranceTotal}
              onChange={(value: number | null) => handleFormDataChange({ insuranceTotal: value })}
            />
            <Ir8aTooltip originalAmt={formData.insuranceAmt} adjustmentAmt={formData.insuranceAdj} />
          </div>
        </div>
        <ErrorDisplay errors={errors} />
      </div>
    </div>
  )
}

interface Ir8aTooltipProps {
  originalAmt: number
  adjustmentAmt: number
}

const Ir8aTooltip: FC<Ir8aTooltipProps> = ({ originalAmt, adjustmentAmt }) => {
  if (adjustmentAmt === 0) return null

  return (
    <InfoTooltip
      className="amount-tooltip"
      title={`Original amount: ${formatMoney(originalAmt, 2)}
        Adjustment amount: ${formatMoney(adjustmentAmt, 2)}`}
      overlayStyle={{ whiteSpace: 'pre-line', maxWidth: 400 }}
    />
  )
}
