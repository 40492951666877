import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { attendanceMenus, useMasterMenu } from '~/features/master'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { MenuItem } from '~/types/common'
import { dispatch } from '~/stores/store'
import { deleteRating } from '../../actions'
import { useRating } from '../../hooks'
import { selectRatings } from '../../selectors'
import { AddRatingDrawer } from './components/AddRatingDrawer'
import { RatingCard } from './components/RatingCard'
import './Rating.less'

interface RatingProps {}

interface RatingParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.ratings,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Rating'
  }
]

export const Rating: FC<RatingProps> = () => {
  const { id } = useParams<RatingParams>()
  const ratings = useSelector(selectRatings)
  const [rating] = useRating(id)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const canModify = usePermissionGate(Permission.rating, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const { setMenus } = useMasterMenu()

  const handleAddRating = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = ratings.map(s => ({
      path: SETTINGS_ROUTES.rating.replace(':id', s!.id),
      value: s!.name,
      sysPermissionId: Permission.rating
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddRating} block>
            Add rating
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(attendanceMenus)
    }
  }, [setMenus, ratings, canModify, handleAddRating])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && rating) {
      confirm({
        title: 'Delete rating',
        content: `Do you want to delete rating "${rating?.name}"?`,
        onOk: () => {
          dispatch(deleteRating(id))
          history.push(SETTINGS_ROUTES.ratings)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, rating, history])

  return (
    <div id="rating" className="rating">
      <DocumentTitle title="Review Rating" />
      <PageHeader
        title="Review rating"
        containerId="rating"
        breadcrumb={{ routes }}
        extra={
          canModify &&
          editing && (
            <Button key="add" onClick={handleDelete}>
              Delete
            </Button>
          )
        }
      />
      <div className="rating__body">
        <RatingCard rating={rating} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
        {canModify && <AddRatingDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
