import React, { FC, useState, useCallback, useEffect, ChangeEvent, CSSProperties } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Checkbox, CheckboxChangeEvent, Form, Input, Space } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, NewTabLinkIcon, Row, YearKeyValues, EmFieldValues } from '~/components'
import { dispatch } from '~/stores/store'
import { addEmEducation, deleteEmEducation, updateEmEducation } from '../../../actions'
import { IEmEducationInfo, EmEducationState } from '../../../types'
import { mapEmEducationStateToEmEducationInfo } from '../../../types/em-details.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { fetchEmEducationField } from '~/features/employee/actions/fetch-em-education-fields'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { fetchEmpKeyvalues, selectQualificationKeyvalues } from '~/features/master'
import { useSelector } from 'react-redux'

export interface MutateEmEducationDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmEducationState
  readOnly?: boolean
  onClose: () => void
}

const EMPTY_FORM_DATA: IEmEducationInfo = {
  isHighest: false,
  startDate: '',
  endDate: '',
  qualificationId: '',
  major: '',
  institute: '',
  graduationYear: '',
  notes: ''
}

const checkboxStyle: CSSProperties = { marginLeft: 10 }

export const MutateEmEducationDrawer: FC<MutateEmEducationDrawerProps> = ({
  visible,
  employeeId,
  data,
  readOnly,
  onClose
}: MutateEmEducationDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmEducationInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)
  const qualifications = useSelector(selectQualificationKeyvalues)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)

    if (visible) dispatch(fetchEmpKeyvalues('qualification', undefined, undefined, { strategy: 'when-empty' }))
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { isHighest, startDate, endDate, qualificationId, major, institute, graduationYear, notes } = data
      setFormData({ isHighest, startDate, endDate, qualificationId, major, institute, graduationYear, notes })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  useEffect(() => {
    if (visible) {
      dispatch(fetchEmEducationField('major'))
      dispatch(fetchEmEducationField('institute'))
    }
  }, [visible])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(
          updateEmEducation(data.employeeId, data.id, mapEmEducationStateToEmEducationInfo(data), formData)
        )
      } else if (employeeId) {
        result = await dispatch(addEmEducation(employeeId, formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeId, data, formData, onClose])

  const handleDelete = useCallback(
    (emEducation: EmEducationState | undefined) => {
      if (employeeId && emEducation) {
        const { id, institute } = emEducation
        confirm({
          title: 'Delete education',
          content: `Do you want to delete education record of "${institute}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmEducation(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? (readOnly ? 'Education' : 'Edit education') : 'Add education'}
      okText={!readOnly ? 'Save' : 'Close'}
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      showDelete={data && !readOnly ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-education"
    >
      <Form id="form-em-education" onFinish={!readOnly ? handleOk : onClose}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Qualification"
              validateStatus={errors?.qualificationId ? 'error' : ''}
              help={errors?.qualificationId}
            >
              {readOnly ? (
                qualifications[formData.qualificationId]?.value
              ) : (
                <Space>
                  <NewTabLinkIcon
                    path={SETTINGS_ROUTES.qualifications}
                    tooltipText="Open qualification in a new tab"
                    tooltipPlacement="topRight"
                    hidden={!canModify}
                  >
                    <EmpKeyValues
                      id="qualification"
                      ref={focusRef}
                      value={formData.qualificationId}
                      onChange={(qualificationId?: string) => handleFormDataChange({ qualificationId })}
                      style={{ width: 350 }}
                    />
                  </NewTabLinkIcon>
                  <Checkbox
                    checked={formData.isHighest}
                    onChange={(event: CheckboxChangeEvent) => handleFormDataChange({ isHighest: event.target.checked })}
                    style={checkboxStyle}
                  >
                    Highest qualification
                  </Checkbox>
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Major">
              {readOnly ? (
                formData.major || '-'
              ) : (
                <EmFieldValues
                  field="major"
                  value={formData.major}
                  onChange={(value?: string) => handleFormDataChange({ major: value })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Institute" validateStatus={errors?.institute ? 'error' : ''} help={errors?.institute}>
              {readOnly ? (
                formData.institute || '-'
              ) : (
                <EmFieldValues
                  field="institute"
                  value={formData.institute}
                  onChange={(value?: string) => handleFormDataChange({ institute: value })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Start" validateStatus={errors?.startDate ? 'error' : ''} help={errors?.startDate}>
              {readOnly ? (
                formData.startDate ? (
                  moment(formData.startDate).format('DD MMM YYYY')
                ) : (
                  '-'
                )
              ) : (
                <Input.Date
                  value={formData.startDate ? moment(formData.startDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="End">
              {readOnly ? (
                formData.endDate ? (
                  moment(formData.endDate).format('DD MMM YYYY')
                ) : (
                  '-'
                )
              ) : (
                <Input.Date
                  value={formData.endDate ? moment(formData.endDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ endDate: value?.format('YYYY-MM-DD') })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Graduation year"
              validateStatus={errors?.graduationYear ? 'error' : ''}
              help={errors?.graduationYear}
            >
              {readOnly ? (
                formData.graduationYear ? (
                  moment(formData.graduationYear).format('YYYY')
                ) : (
                  '-'
                )
              ) : (
                <YearKeyValues
                  noOfYearBefore={-60}
                  noOfYearAfter={6}
                  sortingOrder="desc"
                  value={formData.graduationYear}
                  onChange={(value: string) => handleFormDataChange({ graduationYear: value })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Notes">
              {readOnly ? (
                formData.notes || '-'
              ) : (
                <Input
                  value={formData.notes}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ notes: event.target.value })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
