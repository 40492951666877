import { replaceQuickBooksLineItemMaps, setQuickBooksLineItemMapsLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateQuickBooksLineItemMaps } from '../api/quick-books.api'
import { QuickBooksLineItemMapState } from '../types'

export const updateQuickBooksLineItemMaps = (
  realmId?: string,
  data?: QuickBooksLineItemMapState[]
): ThunkResult<void> => {
  return async dispatch => {
    if (!realmId || !data || data.length === 0) return

    try {
      dispatch(setQuickBooksLineItemMapsLoading({ realmId, loading: true }))
      const { status, result, errors, message, errorData } = await apiUpdateQuickBooksLineItemMaps(realmId, data)

      if (status) {
        dispatch(replaceQuickBooksLineItemMaps({ realmId, data: result }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setQuickBooksLineItemMapsLoading({ realmId, loading: false }))
    }
  }
}
