import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import {
  performancePersistConfig,
  PerformanceRootState,
  QuestionState,
  RatingScaleState,
  RatingState,
  ReviewQuestionRatingScaleTemplateState,
  ReviewQuestionTemplateState,
  ReviewRatingScaleTemplateState,
  ReviewTemplateState
} from './types'

const questionsAdapter = createEntityAdapter<QuestionState>()
const ratingsAdapter = createEntityAdapter<RatingState>()
const ratingScalesAdapter = createEntityAdapter<RatingScaleState>()
const reviewTemplatesAdapter = createEntityAdapter<ReviewTemplateState>()
const reviewRatingScaleTemplatesAdapter = createEntityAdapter<ReviewRatingScaleTemplateState>()
const reviewQuestionTemplatesAdapter = createEntityAdapter<ReviewQuestionTemplateState>()
const reviewQuestionRatingScaleTemplatesAdapter = createEntityAdapter<ReviewQuestionRatingScaleTemplateState>()

const questionInitialState = questionsAdapter.getInitialState()
const ratingInitialState = ratingsAdapter.getInitialState()
const ratingScaleInitialState = ratingScalesAdapter.getInitialState()
const reviewTemplateInitialState = reviewTemplatesAdapter.getInitialState()
const reviewRatingScaleTemplateInitialState = reviewRatingScaleTemplatesAdapter.getInitialState()
const reviewQuestionTemplateInitialState = reviewQuestionTemplatesAdapter.getInitialState()
const reviewQuestionRatingScaleTemplateInitialState = reviewQuestionRatingScaleTemplatesAdapter.getInitialState()

const initialState: PerformanceRootState = {
  questions: questionInitialState,
  questionsLoading: false,

  ratings: ratingInitialState,
  ratingsLoading: false,
  ratingScales: {},
  ratingScalesLoading: {},

  reviewTemplates: reviewTemplateInitialState,
  reviewTemplatesLoading: false,
  reviewRatingScaleTemplates: {},
  reviewRatingScaleTemplatesLoading: {},
  reviewQuestionTemplates: {},
  reviewQuestionTemplatesLoading: {},
  reviewQuestionRatingScaleTemplates: {},
  reviewQuestionRatingScaleTemplatesLoading: {}
}

const performanceSlice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    // questions
    replaceQuestions: (state, action: PayloadAction<QuestionState[]>) => {
      questionsAdapter.setAll(state.questions, action.payload)
    },
    setQuestion: (state, action: PayloadAction<QuestionState>) => {
      questionsAdapter.upsertOne(state.questions, action.payload)
    },
    removeQuestion: (state, action: PayloadAction<string>) => {
      questionsAdapter.removeOne(state.questions, action.payload)
    },
    setQuestionsLoading: (state, action: PayloadAction<boolean>) => {
      state.questionsLoading = action.payload
    },

    // ratings
    replaceRatings: (state, action: PayloadAction<RatingState[]>) => {
      ratingsAdapter.setAll(state.ratings, action.payload)
    },
    setRating: (state, action: PayloadAction<RatingState>) => {
      ratingsAdapter.upsertOne(state.ratings, action.payload)
    },
    removeRating: (state, action: PayloadAction<string>) => {
      ratingsAdapter.removeOne(state.ratings, action.payload)
    },
    setRatingsLoading: (state, action: PayloadAction<boolean>) => {
      state.ratingsLoading = action.payload
    },

    // ratingScales
    replaceRatingScales: (state, action: PayloadAction<{ ratingId: string; data: RatingScaleState[] }>) => {
      const { ratingId, data } = action.payload
      ratingScalesAdapter.setAll(state.ratingScales[ratingId], data)
    },
    setRatingScale: (state, action: PayloadAction<{ ratingId: string; data: RatingScaleState }>) => {
      const { ratingId, data } = action.payload
      ratingScalesAdapter.upsertOne(state.ratingScales[ratingId], data)
    },
    removeRatingScale: (state, action: PayloadAction<{ ratingId: string; id: string }>) => {
      const { ratingId, id } = action.payload
      ratingScalesAdapter.removeOne(state.ratingScales[ratingId], id)
    },
    setRatingScalesLoading: (state, action: PayloadAction<{ ratingId: string; loading: boolean }>) => {
      const { ratingId, loading } = action.payload
      state.ratingScales[ratingId] = state.ratingScales[ratingId] || ratingScaleInitialState
      state.ratingScalesLoading[ratingId] = loading
    },

    // reviewTemplates
    replaceReviewTemplates: (state, action: PayloadAction<ReviewTemplateState[]>) => {
      reviewTemplatesAdapter.setAll(state.reviewTemplates, action.payload)
    },
    setReviewTemplate: (state, action: PayloadAction<ReviewTemplateState>) => {
      reviewTemplatesAdapter.upsertOne(state.reviewTemplates, action.payload)
    },
    removeReviewTemplate: (state, action: PayloadAction<string>) => {
      reviewTemplatesAdapter.removeOne(state.reviewTemplates, action.payload)
    },
    setReviewTemplatesLoading: (state, action: PayloadAction<boolean>) => {
      state.reviewTemplatesLoading = action.payload
    },

    // reviewRatingScaleTemplates
    replaceReviewRatingScaleTemplates: (
      state,
      action: PayloadAction<{ templateId: string; data: ReviewRatingScaleTemplateState[] }>
    ) => {
      const { templateId, data } = action.payload
      reviewRatingScaleTemplatesAdapter.setAll(state.reviewRatingScaleTemplates[templateId], data)
    },
    setReviewRatingScaleTemplate: (
      state,
      action: PayloadAction<{ templateId: string; data: ReviewRatingScaleTemplateState }>
    ) => {
      const { templateId, data } = action.payload
      reviewRatingScaleTemplatesAdapter.upsertOne(state.reviewRatingScaleTemplates[templateId], data)
    },
    removeReviewRatingScaleTemplate: (state, action: PayloadAction<{ templateId: string; id: string }>) => {
      const { templateId, id } = action.payload
      reviewRatingScaleTemplatesAdapter.removeOne(state.reviewRatingScaleTemplates[templateId], id)
    },
    setReviewRatingScaleTemplatesLoading: (state, action: PayloadAction<{ templateId: string; loading: boolean }>) => {
      const { templateId, loading } = action.payload
      state.reviewRatingScaleTemplates[templateId] =
        state.reviewRatingScaleTemplates[templateId] || reviewRatingScaleTemplateInitialState
      state.reviewRatingScaleTemplatesLoading[templateId] = loading
    },

    // reviewQuestionTemplates
    replaceReviewQuestionTemplates: (
      state,
      action: PayloadAction<{ templateId: string; data: ReviewQuestionTemplateState[] }>
    ) => {
      const { templateId, data } = action.payload
      reviewQuestionTemplatesAdapter.setAll(state.reviewQuestionTemplates[templateId], data)
    },
    setReviewQuestionTemplate: (
      state,
      action: PayloadAction<{ templateId: string; data: ReviewQuestionTemplateState }>
    ) => {
      const { templateId, data } = action.payload
      reviewQuestionTemplatesAdapter.upsertOne(state.reviewQuestionTemplates[templateId], data)
    },
    removeReviewQuestionTemplate: (state, action: PayloadAction<{ templateId: string; id: string }>) => {
      const { templateId, id } = action.payload
      reviewQuestionTemplatesAdapter.removeOne(state.reviewQuestionTemplates[templateId], id)
    },
    setReviewQuestionTemplatesLoading: (state, action: PayloadAction<{ templateId: string; loading: boolean }>) => {
      const { templateId, loading } = action.payload
      state.reviewQuestionTemplates[templateId] =
        state.reviewQuestionTemplates[templateId] || reviewQuestionTemplateInitialState
      state.reviewQuestionTemplatesLoading[templateId] = loading
    },

    // reviewQuestionRatingScaleTemplates
    replaceReviewQuestionRatingScaleTemplates: (
      state,
      action: PayloadAction<{ questionId: string; data: ReviewQuestionRatingScaleTemplateState[] }>
    ) => {
      const { questionId, data } = action.payload
      reviewQuestionRatingScaleTemplatesAdapter.setAll(state.reviewQuestionRatingScaleTemplates[questionId], data)
    },
    setReviewQuestionRatingScaleTemplate: (
      state,
      action: PayloadAction<{ questionId: string; data: ReviewQuestionRatingScaleTemplateState }>
    ) => {
      const { questionId, data } = action.payload
      reviewQuestionRatingScaleTemplatesAdapter.upsertOne(state.reviewQuestionRatingScaleTemplates[questionId], data)
    },
    removeReviewQuestionRatingScaleTemplate: (state, action: PayloadAction<{ questionId: string; id: string }>) => {
      const { questionId, id } = action.payload
      reviewQuestionRatingScaleTemplatesAdapter.removeOne(state.reviewQuestionRatingScaleTemplates[questionId], id)
    },
    setReviewQuestionRatingScaleTemplatesLoading: (
      state,
      action: PayloadAction<{ questionId: string; loading: boolean }>
    ) => {
      const { questionId, loading } = action.payload
      state.reviewQuestionRatingScaleTemplates[questionId] =
        state.reviewQuestionRatingScaleTemplates[questionId] || reviewQuestionRatingScaleTemplateInitialState
      state.reviewQuestionRatingScaleTemplatesLoading[questionId] = loading
    }
  }
})

export const {
  replaceQuestions,
  setQuestion,
  removeQuestion,
  setQuestionsLoading,

  replaceRatings,
  setRating,
  removeRating,
  setRatingsLoading,

  replaceRatingScales,
  setRatingScale,
  removeRatingScale,
  setRatingScalesLoading,

  replaceReviewTemplates,
  setReviewTemplate,
  removeReviewTemplate,
  setReviewTemplatesLoading,

  replaceReviewRatingScaleTemplates,
  setReviewRatingScaleTemplate,
  removeReviewRatingScaleTemplate,
  setReviewRatingScaleTemplatesLoading,

  replaceReviewQuestionTemplates,
  setReviewQuestionTemplate,
  removeReviewQuestionTemplate,
  setReviewQuestionTemplatesLoading,

  replaceReviewQuestionRatingScaleTemplates,
  setReviewQuestionRatingScaleTemplate,
  removeReviewQuestionRatingScaleTemplate,
  setReviewQuestionRatingScaleTemplatesLoading
} = performanceSlice.actions

export const performanceReducers = {
  performance: persistReducer<PerformanceRootState>(performancePersistConfig, performanceSlice.reducer)
}
