import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { CurrencyState } from '../types'

export const selectCurrencies = createSelector(
  (state: StoreState) => state.claim.currencies,
  (currencyState): CurrencyState[] => {
    const currencies = Object.values(currencyState?.entities || {}) as CurrencyState[]
    return currencies.sort((a, b) => (a.isShown === b.isShown ? a.name?.localeCompare(b.name) : a.isShown ? -1 : 1))
  }
)
