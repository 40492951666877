import { showSuccess, showError, showWarning } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSendToQuickBooks } from '../api/payrun-quick-books.api'
import { initiateQuickBooksAuthentication } from './initiate-quick-books-authentication'

export const sendToQuickBooks = (payRunId: string): ThunkResult<void> => {
  return async dispatch => {
    const { status, statusCode, errors, message, errorData } = await apiSendToQuickBooks(payRunId)

    if (status) {
      showSuccess('Sent')
    } else if (statusCode === 403) {
      showWarning('Access denied. Redirecting to QuickBooks to login.')
      dispatch(initiateQuickBooksAuthentication())
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
