import React, { CSSProperties, FC } from 'react'
import { PageHeader, Text } from '~/core-components'
import { Col, DocumentTitle, MenuLinks, Row } from '~/components'
import { PermissionGate } from '~/features/iam'
import { MenuItem } from '~/types/common'
import { auditLogMenus } from '../configs'
import './Systems.less'

const nameStyle: CSSProperties = { display: 'block' }
const descriptionStyle: CSSProperties = { display: 'block', fontSize: 12 }

interface System {
  name: string
  description: string
  menus: MenuItem[]
  permissions: string[]
  gridStyles?: CSSProperties
}

const auditLogSystem: System = {
  name: 'Audit & monitoring',
  description: 'Real-time insights for system & user activities.',
  menus: auditLogMenus,
  permissions: auditLogMenus.map(m => m.sysPermissionId as string)
}

export const Systems: FC = () => {
  return (
    <div className="systems">
      <DocumentTitle title="System" />
      <PageHeader title="System" />
      <div className="systems__content">
        <Row>
          <Col>
            <SystemSection system={auditLogSystem} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

interface SystemSectionProps {
  system: System
}

const SystemSection: FC<SystemSectionProps> = ({ system }) => (
  <PermissionGate key={system.name} sysPermissionId={system.permissions}>
    <div className="systems__section" style={system.gridStyles}>
      <Text style={nameStyle}>{system.name}</Text>
      <Text type="secondary" style={descriptionStyle}>
        {system.description}
      </Text>
      <MenuLinks className="systems__menu" menus={system.menus} />
    </div>
  </PermissionGate>
)
