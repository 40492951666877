import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ZealysLoading } from '~/components'
import { apiGetApexToken } from '~/features/employee'
import { dispatch } from '~/stores/store'
import { useQuery } from '~/hooks/use-query'
import { showError } from '~/utils'
import { PAY_ROUTES } from '~/routes/routes'
import { setCpfApexState } from '../../reducers'

interface CpfCallbackProps {}

export const CpfCallback: FC<CpfCallbackProps> = () => {
  const query = useQuery()
  const history = useHistory()
  const historyPush = history.push
  const code = query.get('code')

  useEffect(() => {
    const retrieveIrasToken = async () => {
      console.log('Retrieving APEX token', code)
      if (code) {
        const { result, status, message } = await apiGetApexToken(code)
        console.log('APEX token', result)
        if (status && result) {
          await dispatch(setCpfApexState(result))
          historyPush(`${PAY_ROUTES.tab.replace(':tab?', 'cpf')}`)
        } else {
          showError(message || 'Error occurred when getting access token from APEX')
        }
      }
    }

    retrieveIrasToken()
  }, [code, historyPush])

  return <ZealysLoading />
}
