import React, { CSSProperties, FC, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ColumnsType, Link, Table } from '~/core-components'
import { DrawerForm } from '~/components'
import { fetchBanks } from '~/features/master'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchSysBankFiles } from '../../../actions'
import { selectSysBankFiles } from '../../../selectors'
import { SysBankFileState } from '../../../types'
import './SelectBankFileDrawer.less'

export interface BankFileDrawerProps {
  visible: boolean
  onClose: (sysBankFileId?: string) => void
}

interface SysBankFileRow extends SysBankFileState {}

const groupRowStyle: CSSProperties = { whiteSpace: 'nowrap' }
const expandable = { defaultExpandAllRows: true, expandRowByClick: false }

export const SelectBankFileDrawer: FC<BankFileDrawerProps> = ({ visible, onClose }) => {
  const sysBankFiles = useSelector(selectSysBankFiles)()
  const sysBankFilesLoading = useSelector((state: StoreState) => state.report.sysBankFilesLoading)
  const banks = useSelector((state: StoreState) => state.master.banks?.entities || {})

  useEffect(() => {
    dispatch(fetchSysBankFiles({ strategy: 'when-empty' }))
    dispatch(fetchBanks({ strategy: 'when-empty' }))
  }, [])

  const handleOk = useCallback(
    (sysBankFileId: string) => {
      typeof onClose === 'function' && onClose(sysBankFileId)
    },
    [onClose]
  )

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const columns: ColumnsType<SysBankFileRow> = [
    {
      title: 'Bank file',
      key: 'name',
      dataIndex: 'name',
      className: 'inline-flex',
      render: (value: string, record) => {
        if ('children' in record) {
          return <div style={groupRowStyle}>{banks[value]?.name || ''}</div>
        }

        return value
      }
    },
    {
      key: 'action',
      align: 'right',
      width: 70,
      render: (value: string, record) => {
        if ('children' in record) {
          return null
        }
        return (
          <Link size="small" onClick={() => handleOk(record.id)}>
            select
          </Link>
        )
      }
    }
  ]

  return (
    <DrawerForm
      open={visible}
      title="Select bank file"
      onClose={handleCloseDrawer}
      width={500}
      okText=""
      className="select-bank-file"
    >
      {sysBankFiles?.length > 0 && (
        <Table
          rowKey="id"
          dataSource={sysBankFiles}
          columns={columns}
          showHeader={false}
          loading={sysBankFilesLoading}
          pagination={false}
          expandable={expandable}
          className="select-bank-file__table"
        />
      )}
    </DrawerForm>
  )
}
