import { replaceRatings, setRatingsLoading } from '../reducers'
import { apiGetRatings } from '../api/rating.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchRatings = (options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().performance.ratingsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().performance.ratings?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setRatingsLoading(true))
      const { result, status } = await apiGetRatings()
      if (status) {
        dispatch(replaceRatings(result))
      }
    } finally {
      dispatch(setRatingsLoading(false))
    }
  }
}
