import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { AuditTableViewState } from '../types'

export const selectAuditTablesView = createSelector(
  (state: StoreState) => state.system.auditTablesView,
  auditTablesState =>
    memoize((viewId: string): AuditTableViewState => {
      const auditTables = auditTablesState.entities[viewId] || ({} as AuditTableViewState)
      return auditTables
    })
)
