import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { TenantIdentityProviderState } from '../types'

export const selectTenantIdentityProviders = createSelector(
  (state: StoreState) => state.master.tenantIdentityProviders,
  tenantIdentityProviderState => {
    return Object.values(tenantIdentityProviderState?.entities || {}) as TenantIdentityProviderState[]
  }
)
