import { Operation } from 'fast-json-patch'
import { request, getBaseUrl, toQueryParams } from '~/utils/request'
import { ConfigState, IGetConfigs } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/config')

export const apiGetConfigs = (params?: IGetConfigs) => request<ConfigState[]>('get', baseUrl, toQueryParams(params))

export const apiUpdateConfig = (sysConfigcode: string, config: Operation[]) =>
  request('patch', `${baseUrl}/${sysConfigcode}`, config)
