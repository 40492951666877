import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteEmployeePersonalData } from '../api/employee.api'
import { fetchEmployee } from './fetch-employee'
import { fetchEmIdentities } from './fetch-em-identities'
import { fetchEmFamilies } from './fetch-em-families'
import { fetchEmAddresses } from './fetch-em-addresses'
import { fetchEmEducations } from './fetch-em-educations'
import { fetchEmCerts } from './fetch-em-certs'
import { fetchEmDocuments } from './fetch-em-documents'

export const deleteEmployeePersonalData = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDeleteEmployeePersonalData(id)
    if (status) {
      dispatch(fetchEmployee(id))
      dispatch(fetchEmAddresses(id))
      dispatch(fetchEmIdentities(id))
      dispatch(fetchEmFamilies(id))
      dispatch(fetchEmEducations(id))
      dispatch(fetchEmCerts(id))
      dispatch(fetchEmDocuments(id))

      showSuccess('Personal data removed')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
