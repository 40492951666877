import { request, getBaseUrl } from '~/utils/request'
import { IRating, ISaveRating, RatingScaleState, RatingState } from '../types'

const baseUrl = getBaseUrl('/performance/rating')

export const apiGetRatings = () => request<IRating[]>('get', baseUrl)

export const apiGetRating = (id: string) => request<RatingState>('get', `${baseUrl}/${id}`)

export const apiSaveRating = (rating: ISaveRating) => request<{ id: string }>('post', baseUrl, rating)

export const apiDeleteRating = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetRatingScales = (ratingId: string) =>
  request<RatingScaleState[]>('get', `${baseUrl}/${ratingId}/scale`)
