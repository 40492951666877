import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { CardSubTitle, Col, Row } from '~/components'
import { YtdSubmissionStatus } from '~/constants'
import { Button, Card, Form } from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { fetchYtdSummary } from '~/features/tax/actions'
import { apiGetIrasSubmissionReport } from '~/features/tax/api/ytd.api'
import { useIrasLog } from '~/features/tax/hooks/use-iras-log'
import { fetchUser, selectUserById } from '~/features/temp'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { downloadWithDom, formatMoney, getFileTimestamp, showError } from '~/utils'
import { YtdSubmissionState } from '../../../types'
import './YtdFileSummary.less'

interface YtdFileSummaryProps {
  ytdSubmission?: YtdSubmissionState
}

export const YtdFileSummary: FC<YtdFileSummaryProps> = ({ ytdSubmission }) => {
  const ytdSubmissionId = ytdSubmission?.id || ''
  const ytdSummary = useSelector((state: StoreState) => state.tax.ytdSummaries?.entities[ytdSubmissionId])
  const [fileTypes] = useSysOptions('ytd_file_type')
  const [irasLog] = useIrasLog(ytdSubmissionId)
  const [downloading, setDownloading] = useState(false)
  const submittor = useSelector((state: StoreState) => selectUserById(state, irasLog?.submittedBy || ''))

  useEffect(() => {
    dispatch(fetchYtdSummary(ytdSubmissionId))
  }, [ytdSubmissionId])

  useEffect(() => {
    if (irasLog?.submittedBy) {
      dispatch(fetchUser(irasLog.submittedBy))
    }
  }, [irasLog])

  const handleIrasSubmissionReport = useCallback(async () => {
    if (!irasLog) return

    try {
      setDownloading(true)

      const { status, result, errors, message, errorData } = await apiGetIrasSubmissionReport(irasLog.id)
      if (status) {
        const fileName = `iras_submission_summary_${getFileTimestamp()}.pdf`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      setDownloading(false)
    }
  }, [irasLog])

  return (
    <Card
      className="ytd-file-summary"
      title="File summary"
      extra={
        ytdSubmission?.status === YtdSubmissionStatus.submitted &&
        irasLog && (
          <Button size="small" onClick={handleIrasSubmissionReport} loading={downloading}>
            Submission report
          </Button>
        )
      }
    >
      <Form layout="horizontal">
        <Row>
          <Col span={ytdSubmission?.status === YtdSubmissionStatus.draft ? 24 : 12}>
            <Form.Item label="File income earned in year">{ytdSubmission?.ytdYear || '-'}</Form.Item>
          </Col>
          <Col span={12} hidden={ytdSubmission?.status === YtdSubmissionStatus.draft}>
            <Form.Item label="Submitted by">{submittor?.loginName}</Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={ytdSubmission?.status === YtdSubmissionStatus.draft ? 24 : 12}>
            <Form.Item label="File type">{fileTypes[ytdSubmission?.fileType || '']?.value || '-'}</Form.Item>
          </Col>
          <Col span={12} hidden={ytdSubmission?.status === YtdSubmissionStatus.draft}>
            <Form.Item label="Submitted on">{moment(irasLog?.submittedDate).format('DD MMM YYYY HH:mm:ss')}</Form.Item>
          </Col>
        </Row>
        <Row hidden={ytdSubmission?.status === YtdSubmissionStatus.draft}>
          <Col span={24}>
            <Form.Item label="File creation date">{moment(irasLog?.createdDate).format('DD MMM YYYY')}</Form.Item>
          </Col>
        </Row>

        <CardSubTitle>Form IR8A</CardSubTitle>
        <Row hidden={ytdSubmission?.status === YtdSubmissionStatus.draft && !irasLog?.ir8aReferenceNo}>
          <Col span={24}>
            <Form.Item className="value-right" label="Reference no.">
              {irasLog?.ir8aReferenceNo}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className="value-right" label="No. of records">
              {formatMoney(ytdSummary?.ir8aRecordCount || 0)}
            </Form.Item>
          </Col>
        </Row>

        <div className="indented">
          <CardSubTitle>Income</CardSubTitle>
          <Row>
            <Col span={24}>
              <Form.Item className="value-right" label="Salary">
                {formatMoney(ytdSummary?.totalGross || 0)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className="value-right" label="Bonus">
                {formatMoney(ytdSummary?.totalBonus || 0)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className="value-right" label="Director's fee">
                {formatMoney(ytdSummary?.totalDirectorFee || 0)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className="value-right" label="Others">
                {formatMoney(ytdSummary?.totalOthers || 0)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className="value-right" label="Gain & profit from share options for S10(1)(b)">
                {formatMoney(ytdSummary?.gainTotalAfter || 0)}
              </Form.Item>
            </Col>
          </Row>

          <CardSubTitle>Deductions</CardSubTitle>
          <Row>
            <Col span={24}>
              <Form.Item className="value-right" label="CPF/designated pension or provident fund">
                {formatMoney(ytdSummary?.totalCpf || 0)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className="value-right" label="Donations">
                {formatMoney(ytdSummary?.totalFund || 0)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className="value-right" label="Mosque building fund">
                {formatMoney(ytdSummary?.totalMbf || 0)}
              </Form.Item>
            </Col>
          </Row>
        </div>

        <CardSubTitle>Form IR8S</CardSubTitle>
        <Row hidden={ytdSubmission?.status === YtdSubmissionStatus.draft && !irasLog?.ir8sReferenceNo}>
          <Col span={24}>
            <Form.Item className="value-right" label="Reference no.">
              {irasLog?.ir8sReferenceNo}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className="value-right" label="No. of records">
              {formatMoney(ytdSummary?.ir8sRecordCount || 0)}
            </Form.Item>
          </Col>
        </Row>

        <CardSubTitle>Appendix 8A</CardSubTitle>
        <Row hidden={ytdSubmission?.status === YtdSubmissionStatus.draft && !irasLog?.a8aReferenceNo}>
          <Col span={24}>
            <Form.Item className="value-right" label="Reference no.">
              {irasLog?.a8aReferenceNo}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className="value-right" label="No. of records">
              {formatMoney(ytdSummary?.a8aRecordCount || 0)}
            </Form.Item>
          </Col>
        </Row>

        <CardSubTitle>Appendix 8B</CardSubTitle>
        <Row hidden={ytdSubmission?.status === YtdSubmissionStatus.draft && !irasLog?.a8bReferenceNo}>
          <Col span={24}>
            <Form.Item className="value-right" label="Reference no.">
              {irasLog?.a8bReferenceNo}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className="value-right" label="No. of records">
              {formatMoney(ytdSummary?.a8bRecordCount || 0)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item className="value-right" label="Total gross amount of gains - S10(1)(b)">
              {formatMoney(ytdSummary?.gainTotalAfter || 0)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item className="value-right" label="Total gross amount of gains - S10(1)(g)">
              {formatMoney(ytdSummary?.gainTotalBefore || 0)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
