import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select, SelectProps, Space, Text } from '~/core-components'
import { InactiveTag } from '~/components'
import { isInactive } from '~/utils'
import { useQuestions } from '../../../hooks'
import './SelectQuestions.less'

export interface SelectQuestionsProps extends SelectProps {
  value: string[]
  onChange: (value: string[]) => void
}

const SelectQuestionsInternal = ({ className, ...props }: SelectQuestionsProps, ref: React.Ref<any>) => {
  const classes = classNames('select-questions', { [`${className}`]: className })
  const [questions] = useQuestions()

  return (
    <Select
      ref={ref}
      className={classes}
      allowClear={false}
      mode="multiple"
      optionLabelProp="title"
      showSearch
      {...props}
    >
      {questions.map(q => (
        <Select.Option key={q.id} value={q.id} title={q.name}>
          <Space direction="vertical" size={1}>
            <Space>
              <span>{q.name}</span>
              {isInactive(q.inactiveDate) && <InactiveTag />}
            </Space>
            {q.description && (
              <Text size="small" italic>
                {q.description}
              </Text>
            )}
          </Space>
        </Select.Option>
      ))}
    </Select>
  )
}

export const SelectQuestions = forwardRef(SelectQuestionsInternal)
