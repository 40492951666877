import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { StoreState } from '~/types/store'
import { EmSelectState } from '~/components'
import {
  IrasErrorState,
  taxPersistConfig,
  TaxRootState,
  Ir8aFormState,
  A8aFormState,
  Ir8sFormState,
  A8bFormState,
  YtdSubmissionViewState,
  YtdSubmissionState,
  YtdSubmissionIr8aViewState,
  YtdSubmissionIr8sViewState,
  YtdSubmissionA8aViewState,
  YtdSubmissionA8bViewState,
  YtdSummaryState,
  YtdCompanyState,
  Ir21FormState,
  Ir21SubmissionState,
  Ir21SubmissionIr21ViewState,
  Ir21SubmissionViewState,
  IrasLogState,
  Ir21ResigneeState,
  Ir21A1FormState,
  Ir21SubmissionA1ViewState,
  YtdIndexViewState,
  YtdRecordViewState
} from './types'

const ir8aErrorAdapter = createEntityAdapter<IrasErrorState>()
const ir8aWarningAdapter = createEntityAdapter<IrasErrorState>()
const ir8sErrorAdapter = createEntityAdapter<IrasErrorState>()
const ir8sWarningAdapter = createEntityAdapter<IrasErrorState>()
const a8aErrorAdapter = createEntityAdapter<IrasErrorState>()
const a8aWarningAdapter = createEntityAdapter<IrasErrorState>()
const a8bErrorAdapter = createEntityAdapter<IrasErrorState>()
const a8bWarningAdapter = createEntityAdapter<IrasErrorState>()
const ir8aFormAdapter = createEntityAdapter<Ir8aFormState>({ selectId: model => model.employeeTaxNo })
const ir8sFormAdapter = createEntityAdapter<Ir8sFormState>({ selectId: model => model.employeeTaxNo })
const a8aFormAdapter = createEntityAdapter<A8aFormState>({ selectId: model => model.employeeTaxNo })
const a8bFormAdapter = createEntityAdapter<A8bFormState>({ selectId: model => model.employeeTaxNo })
const ytdSubmissionViewsAdapter = createEntityAdapter<YtdSubmissionViewState>({ selectId: model => model.viewId })
const ytdSubmissionsAdapter = createEntityAdapter<YtdSubmissionState>()
const ytdSummariesAdapter = createEntityAdapter<YtdSummaryState>()
const ytdSubmissionIr8aViewsAdapter = createEntityAdapter<YtdSubmissionIr8aViewState>({
  selectId: model => model.ytdSubmissionId
})
const ytdSubmissionIr8sViewsAdapter = createEntityAdapter<YtdSubmissionIr8sViewState>({
  selectId: model => model.ytdSubmissionId
})
const ytdSubmissionA8aViewsAdapter = createEntityAdapter<YtdSubmissionA8aViewState>({
  selectId: model => model.ytdSubmissionId
})
const ytdSubmissionA8bViewsAdapter = createEntityAdapter<YtdSubmissionA8bViewState>({
  selectId: model => model.ytdSubmissionId
})
const ytdSubmissionEmployeesAdapter = createEntityAdapter<EmSelectState>()
const ytdCompaniesAdapter = createEntityAdapter<YtdCompanyState>({ selectId: model => model.ytdSubmissionId })
const ytdIndexViewAdapter = createEntityAdapter<YtdIndexViewState>({ selectId: model => model.viewId })
const ytdRecordViewAdapter = createEntityAdapter<YtdRecordViewState>({ selectId: model => model.viewId })
const irasLogsAdapter = createEntityAdapter<IrasLogState>({ selectId: model => model.ytdSubmissionId })
const ir21SubmissionsAdapter = createEntityAdapter<Ir21SubmissionState>()
const ir21FormAdapter = createEntityAdapter<Ir21FormState>({ selectId: model => model.employeeTaxNo })
const ir21SubmissionViewsAdapter = createEntityAdapter<Ir21SubmissionViewState>({ selectId: model => model.viewId })
const ir21SubmissionIr21ViewsAdapter = createEntityAdapter<Ir21SubmissionIr21ViewState>({
  selectId: model => model.viewId
})
const ir21SubmissionA1ViewsAdapter = createEntityAdapter<Ir21SubmissionA1ViewState>({
  selectId: model => model.viewId
})
const ir21SubmissionEmployeesAdapter = createEntityAdapter<EmSelectState>()
const ir21ResigneesAdapter = createEntityAdapter<Ir21ResigneeState>({ selectId: model => model.employeeId })
const ir21A1FormAdapter = createEntityAdapter<Ir21A1FormState>({ selectId: model => model.employeeTaxNo })

const ir8aFormInitialState = ir8aFormAdapter.getInitialState()
const ir8aErrorInitialState = ir8aErrorAdapter.getInitialState()
const ir8aWarningInitialState = ir8aWarningAdapter.getInitialState()
const ir8sFormInitialState = ir8sFormAdapter.getInitialState()
const ir8sErrorInitialState = ir8sErrorAdapter.getInitialState()
const ir8sWarningInitialState = ir8sWarningAdapter.getInitialState()
const a8aFormInitialState = a8aFormAdapter.getInitialState()
const a8aErrorInitialState = a8aErrorAdapter.getInitialState()
const a8aWarningInitialState = a8aWarningAdapter.getInitialState()
const a8bFormInitialState = a8bFormAdapter.getInitialState()
const a8bErrorInitialState = a8bErrorAdapter.getInitialState()
const a8bWarningInitialState = a8bWarningAdapter.getInitialState()
const ytdSubmissionViewsInitialState = ytdSubmissionViewsAdapter.getInitialState()
const ytdSubmissionsInitialState = ytdSubmissionsAdapter.getInitialState()
const ytdSummariesInitialState = ytdSummariesAdapter.getInitialState()
const ytdSubmissionIr8aViewsInitialState = ytdSubmissionIr8aViewsAdapter.getInitialState()
const ytdSubmissionIr8sViewsInitialState = ytdSubmissionIr8sViewsAdapter.getInitialState()
const ytdSubmissionA8aViewsInitialState = ytdSubmissionA8aViewsAdapter.getInitialState()
const ytdSubmissionA8bViewsInitialState = ytdSubmissionA8bViewsAdapter.getInitialState()
const ytdSubmissionEmployeesInitialState = ytdSubmissionEmployeesAdapter.getInitialState()
const ytdCompaniesInitialState = ytdCompaniesAdapter.getInitialState()
const ytdIndexViewInitialState = ytdIndexViewAdapter.getInitialState()
const ytdRecordViewInitialState = ytdRecordViewAdapter.getInitialState()
const irasLogsInitialState = irasLogsAdapter.getInitialState()
const ir21FormInitialState = ir21FormAdapter.getInitialState()
const ir21SubmissionsInitialState = ir21SubmissionsAdapter.getInitialState()
const ir21SubmissionViewsInitialState = ir21SubmissionViewsAdapter.getInitialState()
const ir21SubmissionIr21ViewsInitialState = ir21SubmissionIr21ViewsAdapter.getInitialState()
const ir21SubmissionA1ViewsInitialState = ir21SubmissionA1ViewsAdapter.getInitialState()
const ir21SubmissionEmployeesInitialState = ir21SubmissionEmployeesAdapter.getInitialState()
const ir21ResigneesInitialState = ir21ResigneesAdapter.getInitialState()
const ir21A1FormInitialState = ir21A1FormAdapter.getInitialState()

const initialState: TaxRootState = {
  irasState: '',
  irasStateTimestamp: 0,
  irasSubmissionId: '',

  // IR8A
  ir8aForm: ir8aFormInitialState,
  ir8aFormLoading: false,
  ir8aFormRefetch: 0,
  ir8aFormUpdating: false,
  ir8aFormDeleting: false,
  ir8aErrors: {},
  ir8aWarnings: {},
  ir8aProcessing: false,

  // IR8S
  ir8sForm: ir8sFormInitialState,
  ir8sFormLoading: false,
  ir8sFormRefetch: 0,
  ir8sFormUpdating: false,
  ir8sFormDeleting: false,
  ir8sErrors: {},
  ir8sWarnings: {},
  ir8sProcessing: false,

  // Appendix 8A
  a8aForm: a8aFormInitialState,
  a8aFormLoading: false,
  a8aFormRefetch: 0,
  a8aFormUpdating: false,
  a8aFormDeleting: false,
  a8aErrors: {},
  a8aWarnings: {},
  a8aProcessing: false,

  // Appendix 8B
  a8bForm: a8bFormInitialState,
  a8bFormLoading: false,
  a8bFormRefetch: 0,
  a8bFormUpdating: false,
  a8bFormDeleting: false,
  a8bErrors: {},
  a8bWarnings: {},

  ytdSubmissionExpanded: false,
  ytdSubmissionViews: ytdSubmissionViewsInitialState,
  ytdSubmissionViewsLoading: false,
  ytdSubmissionViewsRefetch: 0,
  ytdSubmissions: ytdSubmissionsInitialState,
  ytdSubmissionsLoading: false,
  ytdSummaries: ytdSummariesInitialState,
  ytdSummariesLoading: false,
  ytdSubmissionIr8aViewsRefetch: 0,
  ytdSubmissionIr8aViews: ytdSubmissionIr8aViewsInitialState,
  ytdSubmissionIr8aViewsLoading: false,
  ytdSubmissionIr8sViewsRefetch: 0,
  ytdSubmissionIr8sViews: ytdSubmissionIr8sViewsInitialState,
  ytdSubmissionIr8sViewsLoading: false,
  ytdSubmissionA8aViewsRefetch: 0,
  ytdSubmissionA8aViews: ytdSubmissionA8aViewsInitialState,
  ytdSubmissionA8aViewsLoading: false,
  ytdSubmissionA8bViews: ytdSubmissionA8bViewsInitialState,
  ytdSubmissionA8bViewsLoading: false,
  ytdSubmissionA8bViewsRefetch: 0,
  ytdSubmissionEmployees: {},
  ytdSubmissionEmployeesLoading: {},
  ytdCompanies: ytdCompaniesInitialState,
  ytdCompaniesLoading: false,
  ytdIndexView: ytdIndexViewInitialState,
  ytdIndexViewLoading: false,
  ytdIndexViewRefetch: 0,
  ytdIndexYears: [],
  ytdIndexYearsLoading: false,

  // Ytd record
  ytdRecordView: ytdRecordViewInitialState,
  ytdRecordViewLoading: false,
  ytdRecordViewRefetch: 0,
  ytdRecordExpanded: false,

  irasLogs: irasLogsInitialState,
  irasLogsLoading: false,

  // IR21
  ir21Form: ir21FormInitialState,
  ir21FormLoading: false,
  ir21FormRefetch: 0,
  ir21FormUpdating: false,
  ir21FormDeleting: false,
  ir21Processing: false,

  ir21SubmissionExpanded: false,
  ir21Submissions: ir21SubmissionsInitialState,
  ir21SubmissionsLoading: false,
  ir21SubmissionViews: ir21SubmissionViewsInitialState,
  ir21SubmissionViewsLoading: false,
  ir21SubmissionViewsRefetch: 0,
  ir21SubmissionIr21Views: ir21SubmissionIr21ViewsInitialState,
  ir21SubmissionIr21ViewsLoading: false,
  ir21SubmissionIr21ViewsRefetch: 0,
  ir21SubmissionA1Views: ir21SubmissionA1ViewsInitialState,
  ir21SubmissionA1ViewsLoading: false,
  ir21SubmissionA1ViewsRefetch: 0,
  ir21SubmissionEmployees: {},
  ir21SubmissionEmployeesLoading: {},
  ir21Resignees: ir21ResigneesInitialState,
  ir21ResigneesLoading: false,

  // IR21A1
  ir21A1Form: ir21A1FormInitialState,
  ir21A1FormLoading: false,
  ir21A1FormRefetch: 0,
  ir21A1FormUpdating: false,
  ir21A1FormDeleting: false,
  ir21A1Processing: false
}

const taxSlice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    setIrasState: (state, action: PayloadAction<string>) => {
      state.irasState = action.payload
      state.irasStateTimestamp = new Date().getTime()
    },
    setIrasSubmissionId: (state, action: PayloadAction<string>) => {
      state.irasSubmissionId = action.payload
    },

    // IR8A
    setIr8aForm: (state, action: PayloadAction<Ir8aFormState>) => {
      ir8aFormAdapter.upsertOne(state.ir8aForm, action.payload)
    },
    setIr8aForms: (state, action: PayloadAction<Ir8aFormState[]>) => {
      ir8aFormAdapter.upsertMany(state.ir8aForm, action.payload)
    },
    setIr8aFormLoading: (state, action: PayloadAction<boolean>) => {
      state.ir8aFormLoading = action.payload
    },
    removeIr8aForm: (state, action: PayloadAction<string>) => {
      ir8aFormAdapter.removeOne(state.ir8aForm, action.payload)
    },
    setIr8aFormRefetch: state => {
      state.ir8aFormRefetch += 1
    },
    setIr8aFormUpdating: (state, action: PayloadAction<boolean>) => {
      state.ir8aFormUpdating = action.payload
    },
    setIr8aFormDeleting: (state, action: PayloadAction<boolean>) => {
      state.ir8aFormDeleting = action.payload
    },
    initIr8aErrors: (state, action: PayloadAction<string>) => {
      state.ir8aErrors[action.payload] = state.ir8aErrors[action.payload] || ir8aErrorInitialState
    },
    replaceIr8aErrors: (state, action: PayloadAction<{ ytdSubmissionId: string; data: IrasErrorState[] }>) => {
      const { ytdSubmissionId, data } = action.payload
      ir8aErrorAdapter.setAll(state.ir8aErrors[ytdSubmissionId], data)
    },
    initIr8aWarnings: (state, action: PayloadAction<string>) => {
      state.ir8aWarnings[action.payload] = state.ir8aWarnings[action.payload] || ir8aWarningInitialState
    },
    replaceIr8aWarnings: (state, action: PayloadAction<{ ytdSubmissionId: string; data: IrasErrorState[] }>) => {
      const { ytdSubmissionId, data } = action.payload
      ir8aWarningAdapter.setAll(state.ir8aWarnings[ytdSubmissionId], data)
    },
    setIr8aProcessing: (state, action: PayloadAction<boolean>) => {
      state.ir8aProcessing = action.payload
    },

    // IR8S
    setIr8sForm: (state, action: PayloadAction<Ir8sFormState>) => {
      ir8sFormAdapter.upsertOne(state.ir8sForm, action.payload)
    },
    setIr8sFormLoading: (state, action: PayloadAction<boolean>) => {
      state.ir8sFormLoading = action.payload
    },
    removeIr8sForm: (state, action: PayloadAction<string>) => {
      ir8sFormAdapter.removeOne(state.ir8sForm, action.payload)
    },
    setIr8sFormRefetch: state => {
      state.ir8sFormRefetch += 1
    },
    setIr8sFormUpdating: (state, action: PayloadAction<boolean>) => {
      state.ir8sFormUpdating = action.payload
    },
    setIr8sFormDeleting: (state, action: PayloadAction<boolean>) => {
      state.ir8sFormDeleting = action.payload
    },
    initIr8sErrors: (state, action: PayloadAction<string>) => {
      state.ir8sErrors[action.payload] = state.ir8sErrors[action.payload] || ir8sErrorInitialState
    },
    replaceIr8sErrors: (state, action: PayloadAction<{ ytdSubmissionId: string; data: IrasErrorState[] }>) => {
      const { ytdSubmissionId, data } = action.payload
      ir8sErrorAdapter.setAll(state.ir8sErrors[ytdSubmissionId], data)
    },
    initIr8sWarnings: (state, action: PayloadAction<string>) => {
      state.ir8sWarnings[action.payload] = state.ir8sWarnings[action.payload] || ir8sWarningInitialState
    },
    replaceIr8sWarnings: (state, action: PayloadAction<{ ytdSubmissionId: string; data: IrasErrorState[] }>) => {
      const { ytdSubmissionId, data } = action.payload
      ir8sWarningAdapter.setAll(state.ir8sWarnings[ytdSubmissionId], data)
    },
    setIr8sProcessing: (state, action: PayloadAction<boolean>) => {
      state.ir8sProcessing = action.payload
    },

    // Appendix 8A
    setA8aForm: (state, action: PayloadAction<A8aFormState>) => {
      a8aFormAdapter.upsertOne(state.a8aForm, action.payload)
    },
    setA8aFormLoading: (state, action: PayloadAction<boolean>) => {
      state.a8aFormLoading = action.payload
    },
    removeA8aForm: (state, action: PayloadAction<string>) => {
      a8aFormAdapter.removeOne(state.a8aForm, action.payload)
    },
    setA8aFormRefetch: state => {
      state.a8aFormRefetch += 1
    },
    setA8aFormUpdating: (state, action: PayloadAction<boolean>) => {
      state.a8aFormUpdating = action.payload
    },
    setA8aFormDeleting: (state, action: PayloadAction<boolean>) => {
      state.a8aFormDeleting = action.payload
    },
    initA8aErrors: (state, action: PayloadAction<string>) => {
      state.a8aErrors[action.payload] = state.a8aErrors[action.payload] || a8aErrorInitialState
    },
    replaceA8aErrors: (state, action: PayloadAction<{ ytdSubmissionId: string; data: IrasErrorState[] }>) => {
      const { ytdSubmissionId, data } = action.payload
      a8aErrorAdapter.setAll(state.a8aErrors[ytdSubmissionId], data)
    },
    initA8aWarnings: (state, action: PayloadAction<string>) => {
      state.a8aWarnings[action.payload] = state.a8aWarnings[action.payload] || a8aWarningInitialState
    },
    replaceA8aWarnings: (state, action: PayloadAction<{ ytdSubmissionId: string; data: IrasErrorState[] }>) => {
      const { ytdSubmissionId, data } = action.payload
      a8aWarningAdapter.setAll(state.a8aWarnings[ytdSubmissionId], data)
    },
    setA8aProcessing: (state, action: PayloadAction<boolean>) => {
      state.a8aProcessing = action.payload
    },

    // Appendix 8B
    setA8bForm: (state, action: PayloadAction<A8bFormState>) => {
      a8bFormAdapter.upsertOne(state.a8bForm, action.payload)
    },
    setA8bForms: (state, action: PayloadAction<A8bFormState[]>) => {
      a8bFormAdapter.upsertMany(state.a8bForm, action.payload)
    },
    removeA8bForm: (state, action: PayloadAction<string>) => {
      a8bFormAdapter.removeOne(state.a8bForm, action.payload)
    },
    setA8bFormLoading: (state, action: PayloadAction<boolean>) => {
      state.a8bFormLoading = action.payload
    },
    setA8bFormRefetch: state => {
      state.a8bFormRefetch += 1
    },
    setA8bFormUpdating: (state, action: PayloadAction<boolean>) => {
      state.a8bFormUpdating = action.payload
    },
    setA8bFormDeleting: (state, action: PayloadAction<boolean>) => {
      state.a8bFormDeleting = action.payload
    },
    initA8bErrors: (state, action: PayloadAction<string>) => {
      state.a8bErrors[action.payload] = state.a8bErrors[action.payload] || a8bErrorInitialState
    },
    replaceA8bErrors: (state, action: PayloadAction<{ ytdSubmissionId: string; data: IrasErrorState[] }>) => {
      const { ytdSubmissionId, data } = action.payload
      a8bErrorAdapter.setAll(state.a8bErrors[ytdSubmissionId], data)
    },
    initA8bWarnings: (state, action: PayloadAction<string>) => {
      state.a8bWarnings[action.payload] = state.a8bWarnings[action.payload] || a8bWarningInitialState
    },
    replaceA8bWarnings: (state, action: PayloadAction<{ ytdSubmissionId: string; data: IrasErrorState[] }>) => {
      const { ytdSubmissionId, data } = action.payload
      a8bWarningAdapter.setAll(state.a8bWarnings[ytdSubmissionId], data)
    },

    setYtdSubmissionExpanded: (state, action: PayloadAction<boolean>) => {
      state.ytdSubmissionExpanded = action.payload
    },
    refetchYtdSubmissionViews: state => {
      state.ytdSubmissionViewsRefetch += 1
    },
    setYtdSubmissionView: (state, action: PayloadAction<YtdSubmissionViewState>) => {
      ytdSubmissionViewsAdapter.upsertOne(state.ytdSubmissionViews, action.payload)
    },
    setYtdSubmissionViewsLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdSubmissionViewsLoading = action.payload
    },
    setYtdSubmission: (state, action: PayloadAction<YtdSubmissionState>) => {
      ytdSubmissionsAdapter.upsertOne(state.ytdSubmissions, action.payload)
    },
    setYtdSubmissionsLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdSubmissionsLoading = action.payload
    },
    setYtdSummary: (state, action: PayloadAction<YtdSummaryState>) => {
      ytdSummariesAdapter.upsertOne(state.ytdSummaries, action.payload)
    },
    setYtdSummariesLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdSummariesLoading = action.payload
    },
    setIrasLog: (state, action: PayloadAction<IrasLogState>) => {
      irasLogsAdapter.upsertOne(state.irasLogs, action.payload)
    },
    setIrasLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.irasLogsLoading = action.payload
    },
    refetchYtdSubmissionIr8aViews: state => {
      state.ytdSubmissionIr8aViewsRefetch += 1
    },
    setYtdSubmissionIr8aView: (state, action: PayloadAction<YtdSubmissionIr8aViewState>) => {
      ytdSubmissionIr8aViewsAdapter.upsertOne(state.ytdSubmissionIr8aViews, action.payload)
    },
    setYtdSubmissionIr8aViewsLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdSubmissionIr8aViewsLoading = action.payload
    },
    refetchYtdSubmissionIr8sViews: state => {
      state.ytdSubmissionIr8sViewsRefetch += 1
    },
    setYtdSubmissionIr8sView: (state, action: PayloadAction<YtdSubmissionIr8sViewState>) => {
      ytdSubmissionIr8sViewsAdapter.upsertOne(state.ytdSubmissionIr8sViews, action.payload)
    },
    setYtdSubmissionIr8sViewsLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdSubmissionIr8sViewsLoading = action.payload
    },
    refetchYtdSubmissionA8aViews: state => {
      state.ytdSubmissionA8aViewsRefetch += 1
    },
    setYtdSubmissionA8aView: (state, action: PayloadAction<YtdSubmissionA8aViewState>) => {
      ytdSubmissionA8aViewsAdapter.upsertOne(state.ytdSubmissionA8aViews, action.payload)
    },
    setYtdSubmissionA8aViewsLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdSubmissionA8aViewsLoading = action.payload
    },
    refetchYtdSubmissionA8bViews: state => {
      state.ytdSubmissionA8bViewsRefetch += 1
    },
    setYtdSubmissionA8bView: (state, action: PayloadAction<YtdSubmissionA8bViewState>) => {
      ytdSubmissionA8bViewsAdapter.upsertOne(state.ytdSubmissionA8bViews, action.payload)
    },
    setYtdSubmissionA8bViewsLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdSubmissionA8bViewsLoading = action.payload
    },
    setYtdSubmissionEmployeesLoading: (state, action: PayloadAction<{ ytdFormType: string; loading: boolean }>) => {
      const { ytdFormType, loading } = action.payload
      state.ytdSubmissionEmployees[ytdFormType] =
        state.ytdSubmissionEmployees[ytdFormType] || ytdSubmissionEmployeesInitialState
      state.ytdSubmissionEmployeesLoading[ytdFormType] = loading
    },
    replaceYtdSubmissionEmployees: (state, action: PayloadAction<{ ytdFormType: string; data: EmSelectState[] }>) => {
      const { ytdFormType, data } = action.payload
      state.ytdSubmissionEmployees[ytdFormType] =
        state.ytdSubmissionEmployees[ytdFormType] || ytdSubmissionEmployeesInitialState
      ytdSubmissionEmployeesAdapter.setAll(state.ytdSubmissionEmployees[ytdFormType]!, data)
    },
    setYtdCompaniesLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdCompaniesLoading = action.payload
    },
    setYtdCompany: (state, action: PayloadAction<YtdCompanyState>) => {
      ytdCompaniesAdapter.upsertOne(state.ytdCompanies, action.payload)
    },
    refetchYtdIndexView: state => {
      state.ytdIndexViewRefetch += 1
    },
    setYtdIndexView: (state, action: PayloadAction<YtdIndexViewState>) => {
      ytdIndexViewAdapter.upsertOne(state.ytdIndexView, action.payload)
    },
    setYtdIndexViewLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdIndexViewLoading = action.payload
    },
    setYtdIndexYearsLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdIndexYearsLoading = action.payload
    },
    replaceYtdIndexYears: (state, action: PayloadAction<string[]>) => {
      state.ytdIndexYears = action.payload
    },

    // Ytd record
    refetchYtdRecordView: state => {
      state.ytdRecordViewRefetch += 1
    },
    setYtdRecordView: (state, action: PayloadAction<YtdRecordViewState>) => {
      ytdRecordViewAdapter.upsertOne(state.ytdRecordView, action.payload)
    },
    setYtdRecordViewLoading: (state, action: PayloadAction<boolean>) => {
      state.ytdRecordViewLoading = action.payload
    },
    setYtdRecordExpanded: (state, action: PayloadAction<boolean>) => {
      state.ytdRecordExpanded = action.payload
    },

    // IR21
    setIr21Form: (state, action: PayloadAction<Ir21FormState>) => {
      ir21FormAdapter.upsertOne(state.ir21Form, action.payload)
    },
    setIr21Forms: (state, action: PayloadAction<Ir21FormState[]>) => {
      ir21FormAdapter.upsertMany(state.ir21Form, action.payload)
    },
    setIr21FormLoading: (state, action: PayloadAction<boolean>) => {
      state.ir21FormLoading = action.payload
    },
    removeIr21Form: (state, action: PayloadAction<string>) => {
      ir21FormAdapter.removeOne(state.ir21Form, action.payload)
    },
    setIr21FormRefetch: state => {
      state.ir21FormRefetch += 1
    },
    setIr21FormUpdating: (state, action: PayloadAction<boolean>) => {
      state.ir21FormUpdating = action.payload
    },
    setIr21FormDeleting: (state, action: PayloadAction<boolean>) => {
      state.ir21FormDeleting = action.payload
    },
    setIr21Processing: (state, action: PayloadAction<boolean>) => {
      state.ir21Processing = action.payload
    },

    setIr21SubmissionExpanded: (state, action: PayloadAction<boolean>) => {
      state.ir21SubmissionExpanded = action.payload
    },
    refetchIr21SubmissionViews: state => {
      state.ir21SubmissionViewsRefetch += 1
    },
    setIr21SubmissionView: (state, action: PayloadAction<Ir21SubmissionViewState>) => {
      ir21SubmissionViewsAdapter.upsertOne(state.ir21SubmissionViews, action.payload)
    },
    setIr21SubmissionViewsLoading: (state, action: PayloadAction<boolean>) => {
      state.ir21SubmissionViewsLoading = action.payload
    },
    setIr21Submission: (state, action: PayloadAction<Ir21SubmissionState>) => {
      ir21SubmissionsAdapter.upsertOne(state.ir21Submissions, action.payload)
    },
    setIr21SubmissionsLoading: (state, action: PayloadAction<boolean>) => {
      state.ir21SubmissionsLoading = action.payload
    },
    refetchIr21SubmissionIr21Views: state => {
      state.ir21SubmissionIr21ViewsRefetch += 1
    },
    setIr21SubmissionIr21View: (state, action: PayloadAction<Ir21SubmissionIr21ViewState>) => {
      ir21SubmissionIr21ViewsAdapter.upsertOne(state.ir21SubmissionIr21Views, action.payload)
    },
    setIr21SubmissionIr21ViewsLoading: (state, action: PayloadAction<boolean>) => {
      state.ir21SubmissionIr21ViewsLoading = action.payload
    },
    refetchIr21SubmissionA1Views: state => {
      state.ir21SubmissionA1ViewsRefetch += 1
    },
    setIr21SubmissionA1View: (state, action: PayloadAction<Ir21SubmissionA1ViewState>) => {
      ir21SubmissionA1ViewsAdapter.upsertOne(state.ir21SubmissionA1Views, action.payload)
    },
    setIr21SubmissionA1ViewsLoading: (state, action: PayloadAction<boolean>) => {
      state.ir21SubmissionA1ViewsLoading = action.payload
    },
    setIr21SubmissionEmployeesLoading: (state, action: PayloadAction<{ ir21FormType: string; loading: boolean }>) => {
      const { ir21FormType, loading } = action.payload
      state.ir21SubmissionEmployees[ir21FormType] =
        state.ir21SubmissionEmployees[ir21FormType] || ir21SubmissionEmployeesInitialState
      state.ir21SubmissionEmployeesLoading[ir21FormType] = loading
    },
    replaceIr21SubmissionEmployees: (state, action: PayloadAction<{ ir21FormType: string; data: EmSelectState[] }>) => {
      const { ir21FormType, data } = action.payload
      state.ir21SubmissionEmployees[ir21FormType] =
        state.ir21SubmissionEmployees[ir21FormType] || ir21SubmissionEmployeesInitialState
      ir21SubmissionEmployeesAdapter.setAll(state.ir21SubmissionEmployees[ir21FormType]!, data)
    },
    replaceIr21Resignees: (state, action: PayloadAction<Ir21ResigneeState[]>) => {
      ir21ResigneesAdapter.setAll(state.ir21Resignees, action.payload)
    },
    setIr21ResigneesLoading: (state, action: PayloadAction<boolean>) => {
      state.ir21ResigneesLoading = action.payload
    },

    // IR21A1
    setIr21A1Form: (state, action: PayloadAction<Ir21A1FormState>) => {
      ir21A1FormAdapter.upsertOne(state.ir21A1Form, action.payload)
    },
    setIr21A1FormLoading: (state, action: PayloadAction<boolean>) => {
      state.ir21A1FormLoading = action.payload
    },
    removeIr21A1Form: (state, action: PayloadAction<string>) => {
      ir21A1FormAdapter.removeOne(state.ir21A1Form, action.payload)
    },
    setIr21A1FormRefetch: state => {
      state.ir21A1FormRefetch += 1
    },
    setIr21A1FormUpdating: (state, action: PayloadAction<boolean>) => {
      state.ir21A1FormUpdating = action.payload
    },
    setIr21A1FormDeleting: (state, action: PayloadAction<boolean>) => {
      state.ir21A1FormDeleting = action.payload
    },
    setIr21A1Processing: (state, action: PayloadAction<boolean>) => {
      state.ir21A1Processing = action.payload
    }
  }
})

export const {
  setIrasState,
  setIrasSubmissionId,

  setIr8aForm,
  setIr8aForms,
  setIr8aFormLoading,
  removeIr8aForm,
  setIr8aFormRefetch,
  setIr8aFormUpdating,
  setIr8aFormDeleting,
  initIr8aErrors,
  replaceIr8aErrors,
  initIr8aWarnings,
  replaceIr8aWarnings,
  setIr8aProcessing,
  setIr8sProcessing,
  setA8aProcessing,

  setIr8sForm,
  setIr8sFormLoading,
  removeIr8sForm,
  setIr8sFormRefetch,
  setIr8sFormUpdating,
  setIr8sFormDeleting,
  initIr8sErrors,
  replaceIr8sErrors,
  initIr8sWarnings,
  replaceIr8sWarnings,

  setA8aForm,
  setA8aFormLoading,
  removeA8aForm,
  setA8aFormRefetch,
  setA8aFormUpdating,
  setA8aFormDeleting,
  initA8aErrors,
  replaceA8aErrors,
  initA8aWarnings,
  replaceA8aWarnings,

  setA8bForm,
  setA8bForms,
  removeA8bForm,
  setA8bFormLoading,
  setA8bFormRefetch,
  setA8bFormUpdating,
  setA8bFormDeleting,
  initA8bErrors,
  replaceA8bErrors,
  initA8bWarnings,
  replaceA8bWarnings,

  setYtdSubmissionExpanded,
  refetchYtdSubmissionViews,
  setYtdSubmissionView,
  setYtdSubmissionViewsLoading,
  setYtdSubmission,
  setYtdSubmissionsLoading,
  setYtdSummary,
  setYtdSummariesLoading,
  refetchYtdSubmissionIr8aViews,
  setYtdSubmissionIr8aView,
  setYtdSubmissionIr8aViewsLoading,
  refetchYtdSubmissionIr8sViews,
  setYtdSubmissionIr8sView,
  setYtdSubmissionIr8sViewsLoading,
  refetchYtdSubmissionA8aViews,
  setYtdSubmissionA8aView,
  setYtdSubmissionA8aViewsLoading,
  refetchYtdSubmissionA8bViews,
  setYtdSubmissionA8bView,
  setYtdSubmissionA8bViewsLoading,
  setYtdSubmissionEmployeesLoading,
  replaceYtdSubmissionEmployees,
  setYtdCompaniesLoading,
  setYtdCompany,
  refetchYtdIndexView,
  setYtdIndexView,
  setYtdIndexViewLoading,
  replaceYtdIndexYears,
  setYtdIndexYearsLoading,

  setYtdRecordView,
  setYtdRecordViewLoading,
  refetchYtdRecordView,

  setIrasLog,
  setIrasLogsLoading,

  setIr21Form,
  setIr21Forms,
  setIr21FormLoading,
  removeIr21Form,
  setIr21FormRefetch,
  setIr21FormUpdating,
  setIr21FormDeleting,
  setIr21Processing,

  setIr21SubmissionExpanded,
  refetchIr21SubmissionViews,
  setIr21SubmissionView,
  setIr21SubmissionViewsLoading,
  setIr21Submission,
  setIr21SubmissionsLoading,
  refetchIr21SubmissionIr21Views,
  setIr21SubmissionIr21View,
  setIr21SubmissionIr21ViewsLoading,
  refetchIr21SubmissionA1Views,
  setIr21SubmissionA1View,
  setIr21SubmissionA1ViewsLoading,
  setIr21SubmissionEmployeesLoading,
  replaceIr21SubmissionEmployees,
  replaceIr21Resignees,
  setIr21ResigneesLoading,

  setIr21A1Form,
  setIr21A1FormLoading,
  removeIr21A1Form,
  setIr21A1FormRefetch,
  setIr21A1FormUpdating,
  setIr21A1FormDeleting,
  setIr21A1Processing
} = taxSlice.actions

export const { selectById: selectYtdSubmissionIr8aViewById } = ytdSubmissionIr8aViewsAdapter.getSelectors(
  (state: StoreState) => state.tax.ytdSubmissionIr8aViews
)

export const { selectById: selectYtdSubmissionIr8sViewById } = ytdSubmissionIr8sViewsAdapter.getSelectors(
  (state: StoreState) => state.tax.ytdSubmissionIr8sViews
)

export const { selectById: selectYtdSubmissionA8aViewById } = ytdSubmissionA8aViewsAdapter.getSelectors(
  (state: StoreState) => state.tax.ytdSubmissionA8aViews
)

export const { selectById: selectYtdSubmissionA8bViewById } = ytdSubmissionA8bViewsAdapter.getSelectors(
  (state: StoreState) => state.tax.ytdSubmissionA8bViews
)

export const taxReducers = { tax: persistReducer<TaxRootState>(taxPersistConfig, taxSlice.reducer) }
