import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Button, Drawer, Space, Steps } from '~/core-components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import {
  LeaveGrantBulkRowState,
  LeaveGrantBulkState,
  LeaveGrantBulkVerifyState,
  mapLeaveGrantBulkToILeaveGrantBulk,
  mapLeaveGrantBulkToILeaveGrantBulkVerify
} from '../../types'
import { FilterEmStatus } from '~/constants'
import { executeLeaveGrantBulk, prepareLeaveGrantBulk, verifyLeaveGrantBulk } from '../../actions'
import { LeaveGrantBulkInput } from './components/LeaveGrantBulkInput'
import { SelectLeaveGrantBulkRecord } from './components/SelectLeaveGrantBulkRecord'
import { LeaveGrantBulkRecord } from './components/LeaveGrantBulkRecord'
import { Col, Row } from '~/components'
import { apiDownloadLeaveGrantBulkExcel } from '../../api/leave-grant-bulk.api'
import { useIsMountedRef } from '~/hooks'
import './LeaveGrantBulkDrawer.less'

interface LeaveGrantBulkDrawerProps {
  visible: boolean
  onClose: (success: boolean) => void
}

export interface LeaveGrantBulkFormData {
  leaveTypeId: string
  startDate: string
  endDate: string
  earned: number
  notes: string
  asAtDate: string
  emStatus: string
  isIncludeProbation: boolean
  source: LeaveGrantBulkRowState[]
  selected: LeaveGrantBulkRowState[]
}

const TODAY = moment().format('YYYY-MM-DD')
const EMPTY_FORM_DATA: LeaveGrantBulkFormData = {
  leaveTypeId: '',
  startDate: '',
  endDate: '',
  earned: 0,
  notes: '',
  asAtDate: TODAY,
  emStatus: FilterEmStatus.existing,
  isIncludeProbation: true,
  source: [],
  selected: []
}

const { Step } = Steps

type StepKey = 'input' | 'select' | 'verify' | 'complete'

interface IStep {
  key: StepKey
  title: string
  nextButton?: string
  backButton?: string
}

const STEPS: IStep[] = [
  {
    key: 'input',
    title: 'Enter details'
  },
  {
    key: 'select',
    title: 'Select employees'
  },
  {
    key: 'verify',
    title: 'Verify and execute',
    nextButton: 'Grant'
  },
  {
    key: 'complete',
    title: 'Done',
    nextButton: 'Close'
  }
]

export const LeaveGrantBulkDrawer: FC<LeaveGrantBulkDrawerProps> = ({ visible, onClose }) => {
  const [formData, setFormData] = useState<LeaveGrantBulkFormData>(EMPTY_FORM_DATA)
  const [step, setStep] = useState(0)
  const [nextLoading, setNextLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    setStep(0)
    setErrors(undefined)
    setFormData(EMPTY_FORM_DATA)
  }, [visible])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => {
      const updated = { ...formData, ...updates }
      if ('emStatus' in updates && updates.emStatus === FilterEmStatus.all) {
        updated.asAtDate = TODAY
        updated.isIncludeProbation = true
      }
      if ('earned' in updates) {
        updated.earned = Math.round(updates.earned * 2) / 2
      }

      return updated
    })
  }, [])

  const prepareBulkRecords = useCallback(async () => {
    let result: ActionResult<LeaveGrantBulkState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(prepareLeaveGrantBulk(mapLeaveGrantBulkToILeaveGrantBulk(formData)))

      const source = result?.result?.source || []
      handleFormDataChange({ source, selected: source })
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [formData, handleFormDataChange])

  const filterSource = useCallback(async () => {
    const errorMessages: string[] = []
    if (formData.selected.length === 0) {
      errorMessages.push('No employee is selected')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<LeaveGrantBulkVerifyState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(verifyLeaveGrantBulk(mapLeaveGrantBulkToILeaveGrantBulkVerify(formData)))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [formData])

  const executeBulk = useCallback(async () => {
    let result: ActionResult<void> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(executeLeaveGrantBulk())
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [])

  const handlePrev = useCallback(() => {
    setErrors(undefined)
    setStep(step => step - 1)
  }, [])

  const handleNext = useCallback(async () => {
    if (STEPS[step].key === 'input') {
      await prepareBulkRecords()
    } else if (STEPS[step].key === 'select') {
      await filterSource()
    } else if (STEPS[step].key === 'verify') {
      await executeBulk()
    } else if (STEPS[step].key === 'complete') {
      typeof onClose === 'function' && onClose(true)
    }
  }, [step, prepareBulkRecords, filterSource, executeBulk, onClose])

  const handleDownloadExcel = useCallback(async () => {
    try {
      setDownloading(true)
      const { status, result, errors, message, errorData } = await apiDownloadLeaveGrantBulkExcel()

      if (status) {
        const fileName = `bulk_grant_leave_${getFileTimestamp()}.xlsx`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [isMountedRef])

  return (
    <Drawer
      open={visible}
      title="Bulk grant leave"
      onClose={() => onClose(false)}
      width="80%"
      className="leave-grant-bulk-drawer"
      footer={
        <Row>
          <Col flex={1}>
            {STEPS[step].key === 'verify' && (
              <Button onClick={handleDownloadExcel} loading={downloading}>
                Download
              </Button>
            )}
          </Col>
          <Col>
            <Space>
              {STEPS[step - 1] && step !== STEPS.length - 1 && (
                <Button onClick={handlePrev}>{STEPS[step].backButton || 'Back'}</Button>
              )}
              {STEPS[step] && (
                <Button type="primary" onClick={handleNext} loading={nextLoading}>
                  {STEPS[step].nextButton || 'Next'}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      }
    >
      <Steps progressDot size="small" current={step}>
        {STEPS.map(s => (
          <Step key={s.key} title={s.title} />
        ))}
      </Steps>
      <LeaveGrantBulkInput
        visible={STEPS[step].key === 'input'}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <SelectLeaveGrantBulkRecord
        visible={STEPS[step].key === 'select'}
        data={formData}
        onSelect={handleFormDataChange}
        errors={errors}
      />
      <LeaveGrantBulkRecord
        visible={['verify', 'complete'].includes(STEPS[step].key)}
        success={STEPS[step].key === 'complete'}
      />
    </Drawer>
  )
}
