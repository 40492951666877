import React, { FC } from 'react'
import { Select, SelectProps, Tag } from '~/core-components'
import { useQuickBooksAccounts } from '../../hooks'
import { Col, InactiveTag, Row } from '~/components'

interface QuickBooksSelectAccountProps extends SelectProps {
  realmId?: string
}

export const QuickBooksSelectAccount: FC<QuickBooksSelectAccountProps> = ({ realmId, ...props }) => {
  const [accounts] = useQuickBooksAccounts(realmId)

  return (
    <Select showSearch optionFilterProp="title" optionLabelProp="title" dropdownMatchSelectWidth={false} {...props}>
      {accounts.map(a => {
        const parts = a.displayName.split(':')

        return (
          <Select.Option key={a.id} value={a.id} title={a.displayName}>
            <Row>
              {a.subAccount && <Col flex={`${(parts.length - 1) * 10}px`}></Col>}
              <Col flex={1}>
                {!a.active && <InactiveTag />}
                {a.name}
              </Col>
              <Col>
                <Tag>{a.subAccount ? `sub of ${parts.at(-2)}` : a.accountType.replace(/([a-z])([A-Z])/g, '$1 $2')}</Tag>
              </Col>
            </Row>
          </Select.Option>
        )
      })}
    </Select>
  )
}
