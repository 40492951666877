import React, { FC, useState, useCallback, useEffect } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Checkbox, CheckboxChangeEvent, Form } from '~/core-components'
import { Col, DrawerForm, Row, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import {
  addTenantIdentityProvider,
  deleteTenantIdentityProvider,
  fetchDepartmentsExtra,
  updateTenantIdentityProvider
} from '../../../../actions'
import { TenantIdentityProviderState } from '../../../../types'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { useSysOptions } from '~/features/employee'
import { ProviderType } from '~/constants'
import { ConfigMicrosoft } from './ConfigMicrosoft'
import { ConfigSecretCommon } from './ConfigSecretCommon'
import { ConfigCommon } from './ConfigCommon'

export interface MutateIdentityProviderDrawerProps {
  visible: boolean
  data?: TenantIdentityProviderState
  onClose: () => void
}

interface IdentityProviderFormData {
  id?: string
  providerType: string
  config: string
  configSecret: string
  providerField: string
  userField: string
  inactiveDate?: string
}

const EMPTY_FORM_DATA: IdentityProviderFormData = {
  id: '',
  providerType: '',
  config: '',
  configSecret: '',
  providerField: '',
  userField: '',
  inactiveDate: ''
}

const activeTenantCode = localStorage.getItem('tenant')

export const MutateIdentityProviderDrawer: FC<MutateIdentityProviderDrawerProps> = ({
  visible,
  data,
  onClose
}: MutateIdentityProviderDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IdentityProviderFormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [isInactive, setIsInactive] = useState(false)
  const [identityProviderDict] = useSysOptions('identity_provider')

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { providerType, config, configSecret, providerField, userField, inactiveDate } = data
      setFormData({
        providerType,
        config,
        configSecret,
        providerField,
        userField,
        inactiveDate
      })
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      setIsInactive(!!updated.inactiveDate)
    },
    [formData]
  )

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(
          updateTenantIdentityProvider(data.id, data, { ...formData, tenantCode: activeTenantCode || '' })
        )
      } else {
        result = await dispatch(addTenantIdentityProvider({ ...formData, tenantCode: activeTenantCode || '' }))
      }
      dispatch(fetchDepartmentsExtra())
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (idp: TenantIdentityProviderState | undefined) => {
      if (idp) {
        const { id, providerType } = idp
        const name = identityProviderDict[providerType]?.value || ''
        confirm({
          title: `Delete ${name}`,
          content: `Do you want to delete ${name} record?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteTenantIdentityProvider(id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [identityProviderDict, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? `Edit authentication provider` : `Add authentication provider`}
      onClose={onClose}
      confirmLoading={loading}
      width={700}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-identity-provider"
    >
      <Form id="form-identity-provider" onFinish={handleOk}>
        <Row gutter={15}>
          <Col flex="auto">
            <Form.Item label="Name" validateStatus={errors?.providerType ? 'error' : ''} help={errors?.providerType}>
              <SysOptions
                ref={focusRef}
                type="identity_provider"
                value={formData.providerType}
                onChange={(providerType: string) => handleFormDataChange({ providerType })}
              />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              <Checkbox
                checked={isInactive}
                onChange={(event: CheckboxChangeEvent) => {
                  setIsInactive(event.target.checked)
                  handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {formData.providerType === ProviderType.MS_ENTRA ? (
              <ConfigMicrosoft
                configJsonb={formData.config}
                onChange={(config: string) => handleFormDataChange({ config })}
                errors={errors}
              />
            ) : (
              <ConfigCommon
                configJsonb={formData.config}
                onChange={(config: string) => handleFormDataChange({ config })}
                errors={errors}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ConfigSecretCommon
              configSecretJsonb={formData.configSecret}
              onChange={(configSecret: string) => handleFormDataChange({ configSecret })}
              errors={errors}
            />
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
