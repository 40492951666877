import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Button, Checkbox, CheckboxChangeEvent, Input, Link } from '~/core-components'
import { Col, EditableCard, EditableCardState, PayItemKeyValues, Row, SubHeader } from '~/components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction, emptyGuid, QuickBooksSplitType } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { updateQuickBooksLineItemMaps } from '../../actions'
import { useQuickBooksLineItemMaps } from '../../hooks'
import { QuickBooksLineItemMapState } from '../../types'
import { QuickBooksSelectAccount } from './QuickBooksSelectAccount'
import { QuickBooksSelectContact } from './QuickBooksSelectContact'
import './QuickBooksLineItemMap.less'

interface QuickBooksLineItemMapProps {
  realmId?: string
}

interface FormData {
  lineItemMaps: QuickBooksLineItemMapState[]
  lineItemMaps2: QuickBooksLineItemMapState[]
}

const EMPTY_FORM_DATA: FormData = {
  lineItemMaps: [],
  lineItemMaps2: []
}

const NEW_LINE_ITEM_MAP: QuickBooksLineItemMapState = {
  id: emptyGuid,
  payItemCode: undefined,
  payItemId: undefined,
  description: undefined,
  debitCode: undefined,
  creditCode: undefined,
  contactId: undefined,
  splitType: undefined
}

export const QuickBooksLineItemMap: FC<QuickBooksLineItemMapProps> = ({ realmId }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.quickBooks, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'
  const [errors, setErrors] = useState<Errors>()
  const [lineItemMaps, lineItemMapsLoading] = useQuickBooksLineItemMaps(realmId)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [payItemCodes] = useSysOptions('quick_books_pay_item_code')
  const payItems = useSelector((state: StoreState) => state.master.payItems.entities)

  useEffect(() => {
    if (lineItemMaps) {
      setFormData({
        lineItemMaps: lineItemMaps.filter(x => x.payItemCode != null),
        lineItemMaps2: lineItemMaps.filter(x => x.payItemCode == null)
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [lineItemMaps])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    setCardState('saving')
    setErrors(undefined)

    let result: ActionResult | undefined
    try {
      const combined = [...formData.lineItemMaps, ...formData.lineItemMaps2]
      result = await dispatch(updateQuickBooksLineItemMaps(realmId, combined))
    } catch {
      setCardState('editing')
    }

    if (result?.errors) {
      setCardState('editing')
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setCardState(undefined)
    }
  }, [realmId, formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)

    if (lineItemMaps) {
      setFormData({
        lineItemMaps: lineItemMaps.filter(x => x.payItemCode != null),
        lineItemMaps2: lineItemMaps.filter(x => x.payItemCode == null)
      })
    }
  }, [lineItemMaps])

  return (
    <EditableCard
      className="quick-books-line-item-map"
      title="Map to QuickBooks Account & Contact"
      bodyStyle={{ paddingBottom: !readOnly ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      loading={lineItemMapsLoading}
    >
      <Row>
        <Col span={24}>
          {errors && Object.keys(errors).length > 0 && (
            <Alert
              className="quick-books-line-item-map__error"
              type="error"
              message={
                <>
                  {Object.keys(errors).map((error, index) => {
                    let message = `${errors[error]}`
                    const match = error.match(/lineItems\[(\d+)\]/) // find lineItems[n]
                    if (match) {
                      const lineIndex = parseInt(match[1], 10)
                      message = `${errors[error]} at line item ${lineIndex + 1}`
                    }

                    return <div key={index}>{message}</div>
                  })}
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={4}>
              <div className="quick-books-line-item-map__row-title">Payroll item</div>
            </Col>
            <Col span={4}>
              <div className="quick-books-line-item-map__row-title">Description</div>
            </Col>
            <Col span={5}>
              <div className="quick-books-line-item-map__row-title">Debit</div>
            </Col>
            <Col span={5}>
              <div className="quick-books-line-item-map__row-title">Credit</div>
            </Col>
            <Col span={4}>
              <div className="quick-books-line-item-map__row-title">Contact</div>
            </Col>
            <Col span={1}>Split</Col>
            <Col span={1}></Col>
          </Row>
          {formData.lineItemMaps.map((t, index) => (
            <Row gutter={16} key={index} align="middle" className="quick-books-line-item-map__row">
              <Col span={4}>{payItemCodes[t.payItemCode || '']?.value}</Col>
              <Col span={4}>
                <Input
                  readOnly={readOnly}
                  value={t.description}
                  placeholder={payItemCodes[t.payItemCode || '']?.value || payItems[t.payItemId || '']?.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({
                      lineItemMaps: [
                        ...formData.lineItemMaps.slice(0, index),
                        { ...formData.lineItemMaps[index], description: event.target.value },
                        ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={5}>
                <QuickBooksSelectAccount
                  realmId={realmId}
                  value={t.debitCode}
                  readOnly={readOnly}
                  onChange={(debitCode: string) =>
                    handleFormDataChange({
                      lineItemMaps: [
                        ...formData.lineItemMaps.slice(0, index),
                        { ...formData.lineItemMaps[index], debitCode },
                        ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={5}>
                <QuickBooksSelectAccount
                  realmId={realmId}
                  value={t.creditCode}
                  readOnly={readOnly}
                  onChange={(creditCode: string) =>
                    handleFormDataChange({
                      lineItemMaps: [
                        ...formData.lineItemMaps.slice(0, index),
                        { ...formData.lineItemMaps[index], creditCode },
                        ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={4}>
                <QuickBooksSelectContact
                  realmId={realmId}
                  value={t.contactId}
                  readOnly={readOnly}
                  onChange={(contactId: string) =>
                    handleFormDataChange({
                      lineItemMaps: [
                        ...formData.lineItemMaps.slice(0, index),
                        { ...formData.lineItemMaps[index], contactId },
                        ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={1}>
                <Checkbox
                  readOnly={readOnly}
                  checked={t.splitType === QuickBooksSplitType.Employee}
                  onChange={(event: CheckboxChangeEvent) => {
                    handleFormDataChange({
                      lineItemMaps: [
                        ...formData.lineItemMaps.slice(0, index),
                        {
                          ...formData.lineItemMaps[index],
                          splitType: event.target.checked ? QuickBooksSplitType.Employee : ''
                        },
                        ...formData.lineItemMaps.slice(index + 1, formData.lineItemMaps.length)
                      ]
                    })
                  }}
                />
              </Col>
              <Col span={1}></Col>
            </Row>
          ))}
          {formData.lineItemMaps2.length > 0 && (
            <Row gutter={16} className="quick-books-line-item-map__row quick-books-line-item-map__row-sub-header">
              <Col span={24}>
                <SubHeader>Specific payroll items (to be excluded from "All payroll items")</SubHeader>
              </Col>
            </Row>
          )}
          {formData.lineItemMaps2.map((t, index) => (
            <Row
              gutter={16}
              key={index}
              align="middle"
              className="quick-books-line-item-map__row quick-books-line-item-map__row-indent"
            >
              <Col span={4}>
                <PayItemKeyValues
                  dropdownMatchSelectWidth={false}
                  readOnly={readOnly}
                  value={t.payItemId}
                  onChange={(payItemId: string) =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        { ...formData.lineItemMaps2[index], payItemId },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={4}>
                <Input
                  readOnly={readOnly}
                  value={t.description}
                  placeholder={payItemCodes[t.payItemCode || '']?.value || payItems[t.payItemId || '']?.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        { ...formData.lineItemMaps2[index], description: event.target.value },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={5}>
                <QuickBooksSelectAccount
                  realmId={realmId}
                  value={t.debitCode}
                  readOnly={readOnly}
                  onChange={(debitCode: string) =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        { ...formData.lineItemMaps2[index], debitCode },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={5}>
                <QuickBooksSelectAccount
                  realmId={realmId}
                  value={t.creditCode}
                  readOnly={readOnly}
                  onChange={(creditCode: string) =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        { ...formData.lineItemMaps2[index], creditCode },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={4}>
                <QuickBooksSelectContact
                  realmId={realmId}
                  value={t.contactId}
                  readOnly={readOnly}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        { ...formData.lineItemMaps2[index], contactId: value },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={1}>
                <Checkbox
                  readOnly={readOnly}
                  checked={t.splitType === QuickBooksSplitType.Employee}
                  onChange={(event: CheckboxChangeEvent) => {
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        {
                          ...formData.lineItemMaps2[index],
                          splitType: event.target.checked ? QuickBooksSplitType.Employee : ''
                        },
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }}
                />
              </Col>
              <Col span={1} hidden={readOnly}>
                <Button
                  type="text"
                  icon={<i className="fal fa-xmark" />}
                  onClick={() =>
                    handleFormDataChange({
                      lineItemMaps2: [
                        ...formData.lineItemMaps2.slice(0, index),
                        ...formData.lineItemMaps2.slice(index + 1, formData.lineItemMaps2.length)
                      ]
                    })
                  }
                />
              </Col>
            </Row>
          ))}
          <Row hidden={readOnly}>
            <Col span={6}>
              <Link
                onClick={() => handleFormDataChange({ lineItemMaps2: [...formData.lineItemMaps2, NEW_LINE_ITEM_MAP] })}
              >
                {formData.lineItemMaps2.length === 0 ? 'add specific payroll item' : 'add payroll item'}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </EditableCard>
  )
}
