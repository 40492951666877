import { ThunkResult } from '~/types/store'
import { apiGetCpfSubmission } from '../api/cpf-submission.api'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { setCpfSubmission, setCpfSubmissionsLoading } from '../reducers'

export const fetchCpfSubmission = (id?: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<any> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().payroll.cpfSubmissionsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().payroll.cpfSubmissions?.entities[id]
      if (hasData) return
    }

    try {
      dispatch(setCpfSubmissionsLoading(true))
      const { result, status } = await apiGetCpfSubmission(id)
      if (status) {
        dispatch(setCpfSubmission(result))
      }
    } finally {
      dispatch(setCpfSubmissionsLoading(false))
    }
  }
}
