import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from '~/core-components'
import { Col, EditableCard, EditableCardState, EmpKeyValues, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Delimiter, Permission, PermissionAction, emptyGuid } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { updateQuickBooksCompanyMap } from '../../actions'
import { useQuickBooksCompanyMap } from '../../hooks'
import { IUpdateQuickBooksCompanyMap } from '../../types'

interface QuickBooksCompanyMapProps {
  realmId?: string
}

const EMPTY_FORM_DATA: IUpdateQuickBooksCompanyMap = {
  id: '',
  realmId: '',
  companyIds: ''
}

export const QuickBooksCompanyMap: FC<QuickBooksCompanyMapProps> = ({ realmId }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.quickBooks, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'
  const [errors, setErrors] = useState<Errors>()
  const qBooksCompany = useSelector((state: StoreState) => state.payroll.quickBooksConnections?.entities[realmId || ''])
  const [companyMap, companyMapLoading] = useQuickBooksCompanyMap(realmId)
  const companyMapId = companyMap?.id
  const [formData, setFormData] = useState<IUpdateQuickBooksCompanyMap>(EMPTY_FORM_DATA)

  useEffect(() => {
    if (companyMap) {
      setErrors(undefined)
      setFormData({ ...companyMap, companyIds: companyMap.companyIds.join(Delimiter.pipe) })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [companyMap])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    setCardState('saving')
    setErrors(undefined)

    let result: ActionResult | undefined
    try {
      result = await dispatch(updateQuickBooksCompanyMap({ ...formData, id: companyMapId || emptyGuid }))
    } catch {
      setCardState('editing')
    }

    if (result?.errors) {
      setCardState('editing')
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setCardState(undefined)
    }
  }, [formData, companyMapId])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)
  }, [])

  return (
    <EditableCard
      className="quick-books-company-map"
      title="Company mapping"
      bodyStyle={{ paddingBottom: !readOnly ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      loading={companyMapLoading}
    >
      <Row gutter={30}>
        <Col span={7}>
          <Form.Item
            label="QuickBooks Company"
            validateStatus={errors?.billStatus ? 'error' : ''}
            help={errors?.billStatus}
          >
            {qBooksCompany?.companyName}
          </Form.Item>
        </Col>
        <Col span={2} style={{ textAlign: 'center' }}>
          <Form.Item label=" ">
            <i className="fal fa-arrow-right-long" />
          </Form.Item>
        </Col>
        <Col span={15}>
          <Form.Item label="Companies" validateStatus={errors?.companyIds ? 'error' : ''} help={errors?.companyIds}>
            <EmpKeyValues
              id="company"
              mode="multiple"
              value={formData.companyIds ? formData.companyIds.split(Delimiter.pipe) : undefined}
              readOnly={readOnly}
              onChange={(value: string[]) => handleFormDataChange({ companyIds: value.join(Delimiter.pipe) })}
            />
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
