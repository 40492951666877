import React, { FC, useCallback, useEffect, useState } from 'react'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { ActionResult, Errors } from '~/types/store'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { saveRating } from '../../../actions'
import { useRatingScales } from '../../../hooks'
import { RatingState, ISaveRating } from '../../../types'
import { RatingForm, getEmptyRatingFormData } from './RatingForm'

interface RatingCardProps {
  rating?: RatingState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface FormData extends ISaveRating {}

export const RatingCard: FC<RatingCardProps> = ({ rating, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(getEmptyRatingFormData())
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.rating, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [ratingScales] = useRatingScales(rating?.id || '')

  useEffect(() => {
    if (rating) {
      const { id, name, inactiveDate } = rating
      setFormData({ id, name, inactiveDate, scales: ratingScales })
    } else {
      setFormData(getEmptyRatingFormData())
    }
  }, [rating, ratingScales])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (rating) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(saveRating(formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [rating, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (rating) {
      const { name, inactiveDate } = rating
      setFormData({ name, inactiveDate, scales: ratingScales })
    }
  }, [rating, ratingScales, onCancel])

  return (
    <EditableCard
      title="Rating information"
      bodyStyle={{ paddingBottom: rating ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-rating-info"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <RatingForm
        data={formData}
        errors={errors}
        readOnly={readOnly}
        onChange={data => setFormData({ ...formData, ...data })}
      />
    </EditableCard>
  )
}
