import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { AuditLoginViewState } from '../types'

export const selectAuditLoginsView = createSelector(
  (state: StoreState) => state.system.auditLoginsView,
  auditLoginsState =>
    memoize((viewId: string): AuditLoginViewState => {
      const auditLogins = auditLoginsState.entities[viewId] || ({} as AuditLoginViewState)
      return auditLogins
    })
)
