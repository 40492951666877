import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select, SelectProps, Space, Tag } from '~/core-components'
import { useRatings } from '../../../hooks'
import './SelectRating.less'

export interface SelectRatingProps extends SelectProps {
  value: string
  onChange: (value: string) => void
}

const SelectRatingInternal = ({ className, ...props }: SelectRatingProps, ref: React.Ref<any>) => {
  const classes = classNames('select-rating', { [`${className}`]: className })
  const [ratings] = useRatings()

  return (
    <Select ref={ref} className={classes} allowClear={false} {...props}>
      {ratings.map(r => (
        <Select.Option key={r.id} value={r.id}>
          <Space direction="vertical" size={1}>
            <span>{r.name}</span>
            <Space>
              {r.scaleNames.map(name => (
                <Tag>{name}</Tag>
              ))}
            </Space>
          </Space>
        </Select.Option>
      ))}
    </Select>
  )
}

export const SelectRating = forwardRef(SelectRatingInternal)
