import React, { FC, useCallback } from 'react'
import { Space } from '~/core-components'
import { Col, Row } from '~/components'
import { EmGroup } from '~/constants'
import { TotalHeadcount } from './components/TotalHeadcount'
import { AverageAge } from './components/AverageAge'
import { AverageServiceLength } from './components/AverageServiceLength'
import { AttritionRate } from './components/AttritionRate'
import { HeadcountDistribution } from './components/HeadcountDistribution'
import { ServiceLength } from './components/ServiceLength'
import { Screen, updateViewCriteria, useFirstView, ViewCriteria, ViewCriteriaSimple } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { refetchEmDashboard } from '../../reducers'
import './EmDashboard.less'

interface EmDashboardProps {}

const SCREEN_CODE: Screen = 'em_dashboard'

export const EmDashboard: FC<EmDashboardProps> = () => {
  const [view] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchEmDashboard())
      }
    },
    [viewId]
  )

  return (
    <div className="em-dashboard">
      <Row>
        <Col span={24}>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Space align="start">
            <TotalHeadcount viewId={viewId} />
            <AverageAge viewId={viewId} />
            <AverageServiceLength viewId={viewId} />
            <AttritionRate viewId={viewId} />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="row-title">Headcount distribution</div>
        </Col>
        <Col span={24}>
          <Space align="start">
            <HeadcountDistribution cacheKey="hc_1" viewId={viewId} groupBy={EmGroup.gender} />
            <HeadcountDistribution cacheKey="hc_2" viewId={viewId} groupBy={EmGroup.nationality} />
            <HeadcountDistribution cacheKey="hc_3" viewId={viewId} groupBy={EmGroup.employmentType} />
            <ServiceLength viewId={viewId} />
          </Space>
        </Col>
      </Row>
    </div>
  )
}
