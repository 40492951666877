import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form, Input, Space, Tag } from '~/core-components'
import { Col, EmailRecipients, Row } from '~/components'
import { Delimiter, EmailRecipientSource } from '~/constants'
import { Errors } from '~/types/store'
import { ClaimTypeKeyValues, useClaimTypesDict } from '~/features/claim'

interface FormData {
  daysAfterSubmit: number
  repeatEvery: number
  claimTypeIds: string
  emailTo: string
  emailCc: string
}

interface ParamsReminderClaimApprovalProps {
  sysJobCode: string
  paramJsonb: string
  isEditing?: boolean
  onChange: (value: string) => void
  errors?: Errors
}

const DEFAULT_DAYS_BEFORE_DUE = 1
const DEFAULT_REPEAT_EVERY = 1

const EMPTY_FORM_DATA: FormData = {
  daysAfterSubmit: DEFAULT_DAYS_BEFORE_DUE,
  repeatEvery: DEFAULT_REPEAT_EVERY,
  claimTypeIds: '',
  emailTo: '',
  emailCc: ''
}

export const ParamsReminderClaimApproval: FC<ParamsReminderClaimApprovalProps> = ({
  paramJsonb,
  isEditing,
  onChange,
  errors
}) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [claimTypesDict] = useClaimTypesDict()

  useEffect(() => {
    const data = JSON.parse(paramJsonb || '{}') as FormData
    setFormData({
      daysAfterSubmit: data.daysAfterSubmit || DEFAULT_DAYS_BEFORE_DUE,
      repeatEvery: data.repeatEvery || DEFAULT_REPEAT_EVERY,
      claimTypeIds: data.claimTypeIds,
      emailTo: data.emailTo,
      emailCc: data.emailCc
    })
  }, [paramJsonb])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        const value = JSON.stringify({
          daysAfterSubmit: updated.daysAfterSubmit,
          repeatEvery: updated.repeatEvery,
          claimTypeIds: updated.claimTypeIds,
          emailTo: updated.emailTo,
          emailCc: updated.emailCc
        })

        typeof onChange === 'function' && onChange(value)
        return updated
      })
    },
    [onChange]
  )

  return (
    <div className="params-reminder">
      <Row>
        <Col span={24}>
          <Form.Item
            label="Send the reminder"
            validateStatus={errors?.daysAfterSubmit ? 'error' : ''}
            help={errors?.daysAfterSubmit}
          >
            {isEditing ? (
              <Space>
                <Input.Number
                  precision={0}
                  value={formData.daysAfterSubmit}
                  min={1}
                  onChange={(value: number | null) => handleFormDataChange({ daysAfterSubmit: value || 0 })}
                />
                day(s) after claim submission
              </Space>
            ) : (
              <>{formData.daysAfterSubmit} day(s) after claim submission</>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Repeat every"
            validateStatus={errors?.repeatEvery ? 'error' : ''}
            help={errors?.repeatEvery}
          >
            {isEditing ? (
              <Space>
                <Input.Number
                  precision={0}
                  value={formData.repeatEvery}
                  min={0}
                  onChange={(value: number | null) => handleFormDataChange({ repeatEvery: value || 0 })}
                />
                day(s)
              </Space>
            ) : (
              <>{formData.repeatEvery} day(s)</>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Claim types"
            validateStatus={errors?.claimTypeIds ? 'error' : ''}
            help={errors?.claimTypeIds}
          >
            {isEditing ? (
              <ClaimTypeKeyValues
                mode="multiple"
                value={formData.claimTypeIds ? formData.claimTypeIds.split(Delimiter.pipe) : []}
                onChange={(value?: string[]) => handleFormDataChange({ claimTypeIds: value?.join(Delimiter.pipe) })}
              />
            ) : (
              <>
                {formData.claimTypeIds
                  ? formData.claimTypeIds
                      .split(Delimiter.pipe)
                      .map(ctId => <Tag key={ctId}>{claimTypesDict[ctId]?.name}</Tag>)
                  : '-'}
              </>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Email to" validateStatus={errors?.emailTo ? 'error' : ''} help={errors?.emailTo}>
            <EmailRecipients
              value={formData.emailTo ? formData.emailTo.split(Delimiter.pipe) : []}
              onChange={(value?: string[]) => handleFormDataChange({ emailTo: value?.join(Delimiter.pipe) })}
              readOnly={!isEditing}
              sources={[
                EmailRecipientSource.Role,
                EmailRecipientSource.RoleClaApproval,
                EmailRecipientSource.EmailGroup
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Email cc" validateStatus={errors?.emailCc ? 'error' : ''} help={errors?.emailCc}>
            <EmailRecipients
              value={formData.emailCc ? formData.emailCc.split(Delimiter.pipe) : []}
              onChange={(value?: string[]) => handleFormDataChange({ emailCc: value?.join(Delimiter.pipe) })}
              readOnly={!isEditing}
              sources={[
                EmailRecipientSource.Role,
                EmailRecipientSource.RoleClaApproval,
                EmailRecipientSource.EmailGroup
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
