import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Form, Space, Switch } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { IPayGroupPayslipSettings, PayGroupState } from '../../../types'
import { mapPayGroupStateToPayGroupPayslipSettings } from '../../../types/paygroup.mapper'
import { updateMaster } from '../../../actions'
import { Permission, PermissionAction } from '~/constants'
import { ActionResult, Errors } from '~/types/store'
import { usePermissionGate } from '~/features/iam/hooks'

interface PayslipSettingsProps {
  payGroup?: PayGroupState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const EMPTY_FORM_DATA: IPayGroupPayslipSettings = {
  isShowPayslipYtdAmount: true
}

const cardStyle: CSSProperties = { margin: 24 }

export const PayslipSettings: FC<PayslipSettingsProps> = ({
  payGroup,
  onEdit,
  onSave,
  onCancel
}: PayslipSettingsProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IPayGroupPayslipSettings>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)

  useEffect(() => {
    if (payGroup) {
      const { isShowPayslipYtdAmount } = payGroup
      setFormData({ isShowPayslipYtdAmount })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payGroup])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData({ ...formData, ...updates }),
    [formData]
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (payGroup) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateMaster('payGroup', payGroup.id, mapPayGroupStateToPayGroupPayslipSettings(payGroup), formData)
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [payGroup, formData, onSave])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    typeof onCancel === 'function' && onCancel()
    if (payGroup) {
      const { isShowPayslipYtdAmount } = payGroup
      setFormData({ isShowPayslipYtdAmount })
    }
  }, [payGroup, onCancel])

  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  return (
    <EditableCard
      title="Payslip settings"
      style={cardStyle}
      bodyStyle={{ paddingBottom: payGroup ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-paygroup-payslip-settings"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            validateStatus={errors?.isShowPayslipYtdAmount ? 'error' : ''}
            help={errors?.isShowPayslipYtdAmount}
          >
            <Space>
              <label>Show YTD amount</label>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={formData.isShowPayslipYtdAmount}
                onChange={(checked: boolean) => handleFormDataChange({ isShowPayslipYtdAmount: checked })}
                disabled={readOnly}
              />
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
