import { FC } from 'react'
import { Card, Tabs } from '~/core-components'
import { CpfSubmissionForm } from './CpfSubmissionForm'
import './CpfSubmissionCard.less'
import { useSelector } from 'react-redux'
import { StoreState } from '~/types/store'

interface CpfSubmissionCardProps {}

export const CpfSubmissionCard: FC<CpfSubmissionCardProps> = () => {
  const cpfApexState = useSelector((state: StoreState) => state.payroll.cpfApexState)

  return (
    <Card className="cpf-submission-card">
      <Tabs
        defaultActiveKey={cpfApexState ? 'submit' : 'download'}
        items={[
          {
            key: 'download',
            label: 'Download',
            children: <CpfSubmissionForm type="download" />
          },
          {
            key: 'submit',
            label: 'Direct submision (beta)',
            children: <CpfSubmissionForm type="submit" />
          }
        ]}
      />
    </Card>
  )
}
