import { ThunkResult } from '~/types/store'
import { apiGetQuickBooksAuthUrl } from '../api/quick-books.api'
import { setQuickBooksRedirectUrl } from '../reducers'
import { showError } from '~/utils'

export const initiateQuickBooksAuthentication = (): ThunkResult<void> => {
  return async dispatch => {
    const { result } = await apiGetQuickBooksAuthUrl()
    if (result) {
      dispatch(setQuickBooksRedirectUrl(window.location.href))
      window.location.href = result
    } else {
      showError('Failed to get QuickBooks authentication URL')
    }
  }
}
