import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { IRating } from '../types'
import { fetchRatings } from '../actions'
import { selectRatings } from '../selectors'
import { FetchStrategy } from '~/types/common'

export const useRatings = (fetchStrategy: FetchStrategy = 'always'): [IRating[], boolean] => {
  const ratings = useSelector(selectRatings)
  const loading = useSelector((state: StoreState) => state.performance.ratingsLoading)

  useEffect(() => {
    dispatch(fetchRatings({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [ratings, loading]
}
