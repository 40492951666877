import React, { FC, ChangeEvent, useState, useCallback, useEffect } from 'react'
import moment from 'moment-timezone'
import { Checkbox, CheckboxChangeEvent, Form, Input, Link, Space, Switch, Text } from '~/core-components'
import { Col, ErrorDisplay, InactiveTag, Row, SubHeader } from '~/components'
import { Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { emptyGuid } from '~/constants'
import { ISaveReviewTemplate } from '../../../types'
import { useRatingScales } from '../../../hooks'
import { SelectRating } from '../../Rating/components/SelectRating'
import { getDefaultRatingScales, getNewScale } from '../../Rating/components/RatingForm'
import './ReviewTemplateForm.less'

interface ReviewTemplateFormProps {
  data: ISaveReviewTemplate
  readOnly?: boolean
  errors?: Errors
  onChange: (data: ISaveReviewTemplate) => void
}

export const getEmptyReviewTemplateFormData = () =>
  ({
    name: '',
    inactiveDate: '',
    hasOverallScore: true,
    questions: [
      {
        id: emptyGuid,
        answerType: '',
        questionIds: [],
        scales: getDefaultRatingScales()
      }
    ],
    scales: getDefaultRatingScales()
  } as ISaveReviewTemplate)

export const ReviewTemplateForm: FC<ReviewTemplateFormProps> = ({ data, readOnly, errors, onChange }) => {
  const [formData, setFormData] = useState<ISaveReviewTemplate>(getEmptyReviewTemplateFormData())
  const [focusRef] = useFocus(!readOnly)
  const [isInactive, setIsInactive] = useState(false)
  const [ratingId, setRatingId] = useState<string>('')
  const [ratingScales] = useRatingScales(ratingId)

  useEffect(() => {
    if (data) {
      setFormData(data)
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(getEmptyReviewTemplateFormData())
      setIsInactive(false)
    }
  }, [data])

  useEffect(() => {
    setRatingId('')
  }, [readOnly])

  useEffect(() => {
    if (ratingScales.length > 0) {
      setFormData(formData => {
        const updated = { ...formData, scales: ratingScales }
        typeof onChange === 'function' && onChange(updated)
        return updated
      })
    }
  }, [ratingScales, onChange])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      if (!updated.hasOverallScore) {
        updated.scales = []
      }
      setFormData(updated)
      typeof onChange === 'function' && onChange(updated)
    },
    [formData, onChange]
  )

  return (
    <div className="review-template-form">
      <Row gutter={15}>
        <Col flex="auto">
          <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
            {readOnly ? (
              <span>
                {formData.name} {isInactive && <InactiveTag />}
              </span>
            ) : (
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            )}
          </Form.Item>
        </Col>
        <Col flex="none" hidden={readOnly}>
          <Form.Item label="Inactive" validateStatus={errors?.inactiveDate ? 'error' : ''} help={errors?.inactiveDate}>
            <Checkbox
              checked={isInactive}
              onChange={(event: CheckboxChangeEvent) => {
                setIsInactive(event.target.checked)
                handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Form.Item label="Description" validateStatus={errors?.description ? 'error' : ''} help={errors?.description}>
            {readOnly ? (
              <span>{formData.description}</span>
            ) : (
              <Input.TextArea
                rows={2}
                value={formData.description}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ description: event.target.value })
                }
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <SubHeader type="primary">Overall rating scale</SubHeader>
      <Row hidden={readOnly}>
        <Col flex={1}>
          <Form.Item validateStatus={errors?.hasOverallScore ? 'error' : ''} help={errors?.hasOverallScore}>
            <Space>
              <Switch
                checked={formData.hasOverallScore}
                disabled={readOnly}
                onChange={(hasOverallScore: boolean) => handleFormDataChange({ hasOverallScore })}
              />
              <label>Add an overall rating to the template</label>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!formData.hasOverallScore || readOnly}>
        <Col flex={1}>
          <Form.Item label="Select pre-defined rating scales">
            <SelectRating value={ratingId} readOnly={readOnly} onChange={(ratingId: string) => setRatingId(ratingId)} />
          </Form.Item>
        </Col>
      </Row>
      {formData.hasOverallScore &&
        (readOnly ? (
          <>
            {formData.scales.map(scale => (
              <Row className="review-template-form-scales--readonly">
                <Col span={24}>
                  <b>
                    {scale.scale} - {scale.name}
                  </b>
                </Col>
                <Col span={24}>{scale.description && <Text type="secondary">{scale.description}</Text>}</Col>
              </Row>
            ))}
          </>
        ) : (
          <>
            <ErrorDisplay keys={['scales[*]']} errors={errors} />
            {formData.scales.map((scale, index) => (
              <Row key={`${scale.scale}`} gutter={15} className="review-template-form-scales">
                <Col flex="50px">
                  {index === 0 && <label>Scale</label>}
                  <Input.Number
                    value={scale.scale}
                    onChange={(scale: number | null) =>
                      handleFormDataChange({
                        scales: [
                          ...formData.scales.slice(0, index),
                          { ...formData.scales[index], scale },
                          ...formData.scales.slice(index + 1, formData.scales.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col flex={1}>
                  {index === 0 && <label>Name</label>}
                  <Input
                    value={scale.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({
                        scales: [
                          ...formData.scales.slice(0, index),
                          { ...formData.scales[index], name: event.target.value },
                          ...formData.scales.slice(index + 1, formData.scales.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col flex={2}>
                  {index === 0 && <label>Description</label>}
                  <Input.TextArea
                    rows={2}
                    value={scale.description}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                      handleFormDataChange({
                        scales: [
                          ...formData.scales.slice(0, index),
                          { ...formData.scales[index], description: event.target.value },
                          ...formData.scales.slice(index + 1, formData.scales.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col>
                  {index === 0 && <label>&nbsp;</label>}
                  <Link
                    onClick={() => {
                      const scales = [
                        ...formData.scales.slice(0, index),
                        ...formData.scales.slice(index + 1, formData.scales.length)
                      ].map((item, i) => ({ ...item, scale: i + 1 }))

                      handleFormDataChange({ scales })
                    }}
                  >
                    remove
                  </Link>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Link
                onClick={() => handleFormDataChange({ scales: [...formData.scales, getNewScale(formData.scales)] })}
              >
                add scale
              </Link>
            </Form.Item>
          </>
        ))}
    </div>
  )
}
