import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchQuickBooksLineItemMaps } from '../actions'
import { QuickBooksLineItemMapState } from '../types'
import { selectQuickBooksLineItemMaps } from '../selectors'

export const useQuickBooksLineItemMaps = (
  realmId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [QuickBooksLineItemMapState[], boolean] => {
  const lineItemMap = useSelector(selectQuickBooksLineItemMaps)(realmId || '')
  const loading = useSelector((state: StoreState) => state.payroll.quickBooksLineItemMapsLoading[realmId || ''])

  useEffect(() => {
    dispatch(fetchQuickBooksLineItemMaps(realmId, { strategy: fetchStrategy }))
  }, [realmId, fetchStrategy])

  return [lineItemMap, loading]
}
