import React, { FC, useCallback, useEffect, useState } from 'react'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { ActionResult, Errors } from '~/types/store'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { saveReviewTemplate } from '../../../actions'
import { useReviewRatingScaleTemplates, useReviewQuestionTemplates } from '../../../hooks'
import { ReviewTemplateState, ISaveReviewTemplate } from '../../../types'
import { ReviewTemplateForm, getEmptyReviewTemplateFormData } from './ReviewTemplateForm'

interface ReviewTemplateCardProps {
  template?: ReviewTemplateState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface FormData extends ISaveReviewTemplate {}

export const ReviewTemplateCard: FC<ReviewTemplateCardProps> = ({ template, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(getEmptyReviewTemplateFormData())
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.rating, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [scales] = useReviewRatingScaleTemplates(template?.id || '')
  const [questions] = useReviewQuestionTemplates(template?.id || '')

  useEffect(() => {
    if (template) {
      const { id, name, description, inactiveDate, hasOverallScore } = template
      setFormData({
        id,
        name,
        description,
        inactiveDate,
        hasOverallScore,
        questions,
        scales
      })
    } else {
      setFormData(getEmptyReviewTemplateFormData())
    }
  }, [template, questions, scales])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (template) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(saveReviewTemplate(formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [template, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (template) {
      const { name, description, hasOverallScore, inactiveDate } = template
      setFormData({ name, description, hasOverallScore, inactiveDate, questions, scales })
    }
  }, [template, questions, scales, onCancel])

  return (
    <EditableCard
      title="General"
      bodyStyle={{ paddingBottom: template ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-review-template-card"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <ReviewTemplateForm
        data={formData}
        errors={errors}
        readOnly={readOnly}
        onChange={data => setFormData({ ...formData, ...data })}
      />
    </EditableCard>
  )
}
