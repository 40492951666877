import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddQuestion } from '../api/question.api'
import { setQuestion } from '../reducers'
import { QuestionInfoState, QuestionState } from '../types'

export const addQuestion = (question: QuestionInfoState): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddQuestion(question)
    if (status) {
      const newQuestion: QuestionState = {
        ...question,
        id: result.id
      }
      dispatch(setQuestion(newQuestion))
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
