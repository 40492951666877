import React, { forwardRef, useMemo } from 'react'
import classNames from 'classnames'
import { Alert } from '~/core-components'
import { Errors } from '~/types/store'
import './ErrorDisplay.less'

interface ErrorDisplayProps {
  keys?: string[]
  errors: Errors | undefined
}

const ErrorDisplayInternal = ({ keys = ['*'], errors }: ErrorDisplayProps, ref: React.Ref<any>) => {
  const filtered = useMemo(() => {
    if (!errors) return []

    return Object.keys(errors).filter(key => {
      if (keys.includes('*')) return true
      if (keys.includes(key)) return true

      return keys.some(pattern => {
        // Match any array-like structure, e.g., 'array_props[*]'
        const arrayPatternMatch = pattern.match(/^(.+)\[\*\]$/)
        if (arrayPatternMatch) {
          const baseKey = arrayPatternMatch[1] // e.g., 'array_props' from 'array_props[*]'
          return new RegExp(`^${baseKey}\\[\\d+\\]\\.`).test(key)
        }

        // Match specific properties inside array items, e.g., 'array_props[*].name'
        if (pattern.includes('[*]')) {
          const regex = new RegExp(`^${pattern.replace('[*]', '\\[\\d+\\]')}$`)
          return regex.test(key)
        }

        return false
      })
    })
  }, [errors, keys])

  if (!errors || !filtered) return null
  if (filtered.length === 0) return null

  const errorList = filtered.flatMap(key => errors[key])

  return (
    <div ref={ref}>
      <Alert
        className="error-display"
        type="error"
        message={
          <>
            {errorList.map((error: string, index) => (
              <div
                className={classNames('error-display__point', {
                  'error-display__point--multiple': errorList.length > 1
                })}
                key={index}
              >
                {error}
              </div>
            ))}
          </>
        }
      />
    </div>
  )
}

export const ErrorDisplay = forwardRef(ErrorDisplayInternal)
