import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddTenantIdentityProvider } from '../api/tenant-identity-provider.api'
import { setTenantIdentityProvider } from '../reducers'
import { ITenantIdentityProvider, TenantIdentityProviderState } from '../types'

export const addTenantIdentityProvider = (idp: ITenantIdentityProvider): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddTenantIdentityProvider(idp)
    if (status) {
      const newIdp: TenantIdentityProviderState = { id: result.id, ...idp }
      dispatch(setTenantIdentityProvider(newIdp))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
