import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchQuickBooksAccounts } from '../actions'
import { QuickBooksAccountState } from '../types'
import { selectQuickBooksAccounts } from '../selectors'

export const useQuickBooksAccounts = (
  realmId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [QuickBooksAccountState[], boolean] => {
  const accounts = useSelector(selectQuickBooksAccounts)(realmId || '')
  const loading = useSelector((state: StoreState) => state.payroll.quickBooksAccountsLoading[realmId || ''])

  useEffect(() => {
    dispatch(fetchQuickBooksAccounts(realmId, { strategy: fetchStrategy }))
  }, [realmId, fetchStrategy])

  return [accounts, loading]
}
