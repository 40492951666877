import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ZealysLoading } from '~/components'
import { useQuery } from '~/hooks/use-query'
import { showError } from '~/utils'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { StoreState } from '~/types/store'
import { apiGetQuickBooksAuthToken } from '../../api/quick-books.api'

interface QuickBooksCallbackProps {}

export const QuickBooksCallback: FC<QuickBooksCallbackProps> = () => {
  const query = useQuery()
  const history = useHistory()
  const code = query.get('code')
  const state = query.get('state')
  const realmId = query.get('realmId')
  const error = query.get('error')
  const redirectUrl = useSelector((state: StoreState) => state.payroll.quickBooksRedirectUrl)

  useEffect(() => {
    const retrieveQuickBooksToken = async () => {
      if (code && state && realmId) {
        const { result, status, message } = await apiGetQuickBooksAuthToken(code, state, realmId)
        if (!status || !result) {
          showError(message || 'Error occurred when getting access token from QuickBooks. Did you select any company?')
        }
      } else {
        if (error === 'access_denied') {
          showError('Unable to connect. Did you cancel?')
        } else {
          showError(`Unable to connect. Error: ${error}`)
        }
      }

      if (redirectUrl) window.location.href = redirectUrl
      else history.push(SETTINGS_ROUTES.quickBooks)
    }

    retrieveQuickBooksToken()
  }, [code, state, realmId, error, history, redirectUrl])

  return <ZealysLoading />
}
