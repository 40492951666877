import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import omit from 'lodash/omit'
import { Button, Collapse, Form, Input, PageHeader, Space, Tooltip } from '~/core-components'
import { Col, DocumentTitle, Row, SysOptions } from '~/components'
import { GroupingButton, RptScreen } from '~/features/grouping'
import { EmFlexi, emptyGuid, RptEmStatus } from '~/constants'
import { Errors } from '~/types/store'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { IRptEmFlexi, ReportCode } from '../../types'
import { useFirstReportTemplate, useReportTemplate } from '../../hooks'
import { refetchReportTemplates } from '../../reducers'
import { Screen, useGetViewIdByName, ViewCriteriaButton, ViewSelectionButton } from '~/features/selection'
import { RptDownloadBtn } from '../components/RptDownloadBtn'
import { ReportTemplate } from '../ReportTemplate/ReportTemplate'
import { apiDownloadEmFlexiExcel } from '../../api/rpt-em-flexi.api'
import './RptEmFlexi.less'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'Employee flexible report'
  }
]

interface RptEmFlexiForm extends IRptEmFlexi {
  templateId: string
}

const TODAY = moment().format('YYYY-MM-DD')

const SCREEN_CODE: Screen = 'rpt_em_flexi'
const RPT_SCREEN_CODE: RptScreen = 'rpt_people'
const REPORT_CODE: ReportCode = 'rpt_em_flexi'

const EMPTY_FORM_DATA: RptEmFlexiForm = {
  asAtDate: TODAY,
  emFlexiType: EmFlexi.employee,
  emStatus: RptEmStatus.all,
  sorting: 'employee_name',
  groupingCodes: [],
  pageBreaks: [],
  viewId: '',
  screenCode: '',
  reportTitle: '',
  notes: '',
  templateId: emptyGuid
}

export const RptEmFlexi: FC = () => {
  const [formData, setFormData] = useState<RptEmFlexiForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [activeKey, setActiveKey] = useState(0)

  const [firstTemplate] = useFirstReportTemplate('employee', REPORT_CODE)
  const firstTemplateId = firstTemplate?.id || ''
  const [templateId, setTemplateId] = useState<string>()
  const [template] = useReportTemplate('employee', REPORT_CODE, templateId)
  const templateJsonb = template?.dataJsonb

  const activeScreen = useMemo(() => `${SCREEN_CODE}_${formData.emFlexiType}` as Screen, [formData.emFlexiType])

  const [viewId] = useGetViewIdByName(activeScreen, templateId ? `${activeScreen}_${templateId}` : undefined)

  useEffect(() => {
    if (!templateId && firstTemplateId) {
      setTemplateId(firstTemplateId)
    }

    if (templateJsonb) {
      setFormData(formData => {
        const saved = JSON.parse(templateJsonb) as RptEmFlexiForm
        saved.templateId = templateId || ''
        saved.viewId = viewId || ''
        saved.screenCode = activeScreen
        saved.emStatus = saved.emStatus || RptEmStatus.all

        if (templateId !== formData.templateId) {
          return { ...formData, ...saved }
        }

        return { ...formData, ...omit(saved, 'emFlexiType', 'groupingCodes') }
      })
    }
  }, [firstTemplateId, templateId, templateJsonb, viewId, activeScreen])

  const handleDownloadExcel = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadEmFlexiExcel(formData)

    if (status) {
      const fileName = `employee_flexible_report_${getFileTimestamp()}.xlsx`
      downloadWithDom(result, fileName)
      dispatch(refetchReportTemplates())
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [formData])

  const handleCollapse = useCallback(async () => {
    if (!activeKey) {
      setActiveKey(1)
    } else {
      setActiveKey(0)
    }
  }, [activeKey])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if (updates.emFlexiType) {
      confirm({
        title: 'Change information type',
        content: 'Do you want to apply the change? It will reset the filter and selection columns',
        onOk: () => {
          setFormData(data => {
            updates.groupingCodes = []
            return { ...data, ...updates }
          })
        },
        okText: 'Apply'
      })
    } else {
      setFormData(data => ({ ...data, ...updates }))
    }
  }, [])

  const handleTemplateChange = useCallback((templateId?: string) => {
    setTemplateId(templateId)
  }, [])

  return (
    <div id="rpt-em-flexi" className="rpt-em-flexi">
      <DocumentTitle title="Employee Flexible Report" />
      <PageHeader
        title="Employee flexible report"
        containerId="rpt-em-flexi"
        breadcrumb={{ routes }}
        extra={
          <ReportTemplate<IRptEmFlexi>
            basePath="employee"
            reportCode={REPORT_CODE}
            templateId={templateId}
            templateData={formData}
            size="small"
            onChange={handleTemplateChange}
          />
        }
      />
      <Form className="rpt-em-flexi__form" layout="horizontal" labelCol={{ flex: '130px' }}>
        <Row gutter={30}>
          <Col flex="300px">
            <Form.Item
              label="As-of date"
              validateStatus={errors?.asAtDate ? 'error' : ''}
              help={errors?.asAtDate}
              tooltip="Please note that for Address, the record indicated as Main will be displayed and might not respect the As-of date."
            >
              <Input.Date
                allowClear={false}
                value={formData.asAtDate ? moment(formData.asAtDate) : undefined}
                onChange={(value: moment.Moment | null) => {
                  handleFormDataChange({ asAtDate: value?.format('YYYY-MM-DD') })
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1"></Col>
          <Col flex="none">
            <Space>
              <ViewCriteriaButton screenCode={activeScreen} viewId={formData.viewId} />
              <ViewSelectionButton
                screenCode={activeScreen}
                viewId={formData.viewId}
                title="Information to show in report"
              />
              <GroupingButton
                screenCode={RPT_SCREEN_CODE}
                groupingCodes={formData.groupingCodes || []}
                pageBreaks={formData.pageBreaks || []}
                onSelect={(groupingCodes: string[], pageBreaks: string[]) =>
                  handleFormDataChange({ groupingCodes, pageBreaks })
                }
              />
              <Tooltip title={`${activeKey ? 'Hide' : 'Show'} more settings`}>
                <Button
                  type={activeKey ? 'primary' : 'default'}
                  icon={<i className="fal fa-gear" />}
                  onClick={handleCollapse}
                />
              </Tooltip>
              <RptDownloadBtn onDownloadExcel={handleDownloadExcel} />
            </Space>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Information type"
              validateStatus={errors?.emFlexiType ? 'error' : ''}
              help={errors?.emFlexiType}
            >
              <SysOptions
                allowClear={false}
                type="em_flexi"
                value={formData.emFlexiType || []}
                onChange={(emFlexiType: string[]) => handleFormDataChange({ emFlexiType })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Collapse className="rpt-em-flexi__more-settings" activeKey={activeKey} onChange={handleCollapse} noStyle>
              <Collapse.Panel key="1" header={null} showArrow={false}>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item
                      label="Filter by employee"
                      validateStatus={errors?.emStatus ? 'error' : ''}
                      help={errors?.emStatus}
                    >
                      <SysOptions
                        allowClear={false}
                        type="rpt_em_status"
                        placeholder="All employees"
                        value={formData.emStatus}
                        onChange={(emStatus?: string) => handleFormDataChange({ emStatus })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item label="Report title">
                      <Input
                        value={formData.reportTitle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ reportTitle: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Custom notes">
                      <Input.TextArea
                        rows={3}
                        value={formData.notes}
                        onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                          handleFormDataChange({ notes: value?.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
