import React, { FC } from 'react'
import { Modal, Space } from '~/core-components'

interface ImageBase64ModalProps {
  visible: boolean
  base64?: string
  title?: string
  description?: string
  onClose: () => void
}

export const ImageBase64Modal: FC<ImageBase64ModalProps> = ({
  visible,
  base64,
  title = 'Image',
  description,
  onClose
}) => {
  if (!base64) return null

  return (
    <Modal className="image-base64-modal" open={visible} title={title} onCancel={onClose} width={350} okText="">
      <Space size="middle" direction="vertical" align="center">
        {description && <div>{description}</div>}
        <div>
          <img src={`data:image/png;base64,${base64}`} alt="PayNow QR Code" />
        </div>
      </Space>
    </Modal>
  )
}
