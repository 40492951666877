import { request, getBaseUrl } from '~/utils/request'
import {
  IUpdateQuickBooksCompanyMap,
  QuickBooksAccountState,
  QuickBooksCompanyMapState,
  QuickBooksConnectionState,
  QuickBooksContactState,
  QuickBooksLineItemMapState
} from '../types'

const baseUrl = getBaseUrl('/payroll-sg/quickbooks')

export const apiGetQuickBooksAuthUrl = () => request<string>('get', `${baseUrl}/login`)

export const apiGetQuickBooksAuthToken = (authorizationCode: string, state: string, realmId: string) =>
  request<boolean>('post', `${baseUrl}/token`, { authorizationCode, state, realmId })

export const apiGetQuickBooksConnections = () => request<QuickBooksConnectionState[]>('get', `${baseUrl}/connections`)

export const apiDisconnectQuickBooks = (realmId: string) =>
  request<boolean>('delete', `${baseUrl}/connection/${realmId}`)

export const apiGetQuickBooksCompanyMap = (realmId: string) =>
  request<QuickBooksCompanyMapState>('get', `${baseUrl}/${realmId}/companymap`)

export const apiUpdateQuickBooksCompanyMap = (realmId: string, data: IUpdateQuickBooksCompanyMap) =>
  request<QuickBooksCompanyMapState>('post', `${baseUrl}/${realmId}/companymap`, data)

export const apiGetQuickBooksLineItemMaps = (realmId: string) =>
  request<QuickBooksLineItemMapState[]>('get', `${baseUrl}/${realmId}/lineitemmap`)

export const apiUpdateQuickBooksLineItemMaps = (realmId: string, data: QuickBooksLineItemMapState[]) => {
  let formData = new FormData()

  data.forEach((x: QuickBooksLineItemMapState, index: number) => {
    Object.keys(x).forEach((key: string) => {
      const lineItemValue = x[key as keyof QuickBooksLineItemMapState] as any
      formData.append(
        `lineItems[${index}][${key}]`,
        lineItemValue === null || lineItemValue === undefined ? '' : lineItemValue
      )
    })
  })

  return request<QuickBooksLineItemMapState[]>('post', `${baseUrl}/${realmId}/lineitemmap`, formData)
}

export const apiGetQuickBooksAccounts = (realmId: string) =>
  request<QuickBooksAccountState[]>('get', `${baseUrl}/${realmId}/account`)

export const apiGetQuickBooksContacts = (realmId: string) =>
  request<QuickBooksContactState[]>('get', `${baseUrl}/${realmId}/contact`)
