export enum AuditLoginStatus {
  LoginSuccess = 'login_success',
  LoginFail = 'login_fail',
  LogoutSuccess = 'logout_success'
}

export enum AuditTableAction {
  Add = 'add',
  Update = 'update',
  Delete = 'delete'
}
