import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { ConfigState } from '../types'
import { FetchStrategy } from '~/types/common'
import { fetchConfigs } from '../actions'
import { Dictionary } from '@reduxjs/toolkit'

export const useConfigsDict = (
  sysConfigCodes: string[],
  fetchStrategy: FetchStrategy = 'when-empty'
): [Dictionary<ConfigState>, boolean] => {
  const data = useSelector((state: StoreState) => state.payroll.configs.entities as Dictionary<ConfigState>)
  const loading = useSelector((state: StoreState) => state.payroll.configsLoading)

  useEffect(() => {
    dispatch(fetchConfigs(sysConfigCodes, { strategy: fetchStrategy }))
  }, [sysConfigCodes, fetchStrategy])

  return [data, loading]
}
