import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { refetchYtdRecordView } from '../reducers'
import { apiProcessYtdRecords } from '../api/ytd-record.api'

export const processYtdRecord = (
  ytdYear: string,
  isAllEmployees: boolean,
  paymentMonth: number,
  employeeIds: string[] | null
): ThunkResult<void> => {
  return async dispatch => {
    if (!ytdYear) return

    if (isAllEmployees) {
      paymentMonth = 0
      employeeIds = null
    }

    const { status, errors, message, errorData } = await apiProcessYtdRecords(
      ytdYear,
      isAllEmployees,
      paymentMonth,
      employeeIds
    )
    if (status) {
      dispatch(refetchYtdRecordView())
      showSuccess('Recalculated')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }

    return { errors }
  }
}
