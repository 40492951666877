import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveReviewTemplate } from '../api/review-template.api'
import { ISaveReviewTemplate, ReviewTemplateState } from '../types'
import { setReviewTemplate } from '../reducers'
import { fetchReviewRatingScaleTemplates } from './fetch-review-rating-scale-templates'
import pick from 'lodash/pick'

export const saveReviewTemplate = (request: ISaveReviewTemplate): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const { status, result, errors, message, errorData } = await apiSaveReviewTemplate(request)
    if (status) {
      const reviewTemplateId = result?.id || request.id
      if (reviewTemplateId) {
        dispatch(fetchReviewRatingScaleTemplates(reviewTemplateId))
      }
      const reviewTemplate = getState().performance.reviewTemplates?.entities[reviewTemplateId || '']
      if (reviewTemplate) {
        const updated: ReviewTemplateState = {
          ...(reviewTemplate as ReviewTemplateState),
          ...request
        }

        dispatch(setReviewTemplate(updated))
      } else {
        const newReviewTemplate: ReviewTemplateState = {
          ...(pick(request, 'name', 'description', 'inactiveDate', 'hasOverallScore') as ReviewTemplateState),
          ...result
        }

        dispatch(setReviewTemplate(newReviewTemplate))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
