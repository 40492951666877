import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { QuickBooksContactState } from '../types'

export const selectQuickBooksContacts = createSelector(
  (state: StoreState) => state.payroll.quickBooksContacts,
  quickBooksContactsState =>
    memoize((realmId: string): QuickBooksContactState[] => {
      const contacts = Object.values(quickBooksContactsState[realmId]?.entities || {}) as QuickBooksContactState[]
      return contacts.sort((a, b) =>
        a.active === b.active
          ? a.contactType?.localeCompare(b.contactType) || a.displayName.localeCompare(b.displayName)
          : a.active
          ? -1
          : 1
      )
    })
)
