import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Col, Row, SysOptions } from '~/components'
import { Form, Input } from '~/core-components'
import { Errors } from '~/types/store'

interface FormData {
  transType: string
  clearing: string
  referenceNo: string
}

interface BankFileOcbcVelocityGiroJsonbProps {
  valueDate: string
  batchNo: string
  bankFileJsonb: string
  onChange: (key: string, value: string) => void
  canModify: boolean
  errors?: Errors
}

const EMPTY_FORM_DATA: FormData = {
  transType: '',
  clearing: '',
  referenceNo: ''
}

export const BankFileOcbcVelocityGiroJsonb: FC<BankFileOcbcVelocityGiroJsonbProps> = ({
  valueDate,
  batchNo,
  bankFileJsonb,
  onChange,
  canModify,
  errors
}) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [once, setOnce] = useState(false)

  useEffect(() => {
    if (!once) {
      setOnce(true)

      if (bankFileJsonb) {
        const data = JSON.parse(bankFileJsonb || '{}') as FormData
        setFormData({ transType: data.transType, clearing: data.clearing, referenceNo: data.referenceNo })
      } else {
        setFormData(EMPTY_FORM_DATA)
      }
    }
  }, [once, bankFileJsonb])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      if ('batchNo' in updates) typeof onChange === 'function' && onChange('batchNo', updates.batchNo)
      else if ('valueDate' in updates) typeof onChange === 'function' && onChange('valueDate', updates.valueDate)
      else {
        setFormData(formData => {
          const updated = { ...formData, ...updates }
          const value = JSON.stringify({
            transType: updated.transType,
            clearing: updated.clearing,
            referenceNo: updated.referenceNo
          })

          typeof onChange === 'function' && onChange('bankFileJsonb', value)
          return updated
        })
      }
    },
    [onChange]
  )

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Transaction type"
            validateStatus={errors?.transType ? 'error' : ''}
            help={errors?.transType}
          >
            <SysOptions
              type="ocbc_velocity_trans_type"
              value={formData.transType}
              readOnly={!canModify}
              onChange={(value: string) => handleFormDataChange({ transType: value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Clearing" validateStatus={errors?.clearing ? 'error' : ''} help={errors?.clearing}>
            <SysOptions
              type="ocbc_velocity_clearing"
              value={formData.clearing}
              readOnly={!canModify}
              onChange={(value: string) => handleFormDataChange({ clearing: value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Value date" validateStatus={errors?.valueDate ? 'error' : ''} help={errors?.valueDate}>
            <Input.Date
              value={valueDate ? moment(valueDate) : undefined}
              inputReadOnly={!canModify}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ valueDate: value?.format('YYYY-MM-DD') })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Batch no." validateStatus={errors?.batchNo ? 'error' : ''} help={errors?.batchNo}>
            <Input
              maxLength={3}
              value={batchNo}
              readOnly={!canModify}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ batchNo: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Reference no.">
            <Input
              maxLength={16}
              value={formData.referenceNo}
              readOnly={!canModify}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ referenceNo: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
