import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchPayRunQuickBooks } from '../actions'
import { PayRunQuickBooksState } from '../types'
import { useHasChange } from '~/hooks'

export const usePayRunQuickBooks = (
  payRunId: string,
  fetchStrategy: FetchStrategy = 'always'
): [PayRunQuickBooksState | undefined, boolean] => {
  const payRunQuickBooks = useSelector((state: StoreState) => state.payroll.payRunQuickBooks.entities[payRunId])
  const loading = useSelector((state: StoreState) => state.payroll.payRunQuickBooksLoading)
  const isRefetch = useHasChange((state: StoreState) => state.payroll.payRunQuickBooksRefecth)
  const strategy = isRefetch ? 'always' : fetchStrategy

  useEffect(() => {
    dispatch(fetchPayRunQuickBooks(payRunId, { strategy }))
  }, [payRunId, isRefetch, strategy])

  return [payRunQuickBooks, loading]
}
