import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Link, SecondaryLink, Space, Spin, Tag, Tooltip } from '~/core-components'
import { Col, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { fetchUser, selectUserById } from '~/features/temp'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { Permission, PermissionAction } from '~/constants'
import { sendToQuickBooks } from '../../actions'
import { usePayRunQuickBooks, useQuickBooksConnections } from '../../hooks'
import { refetchPayRunQuickBooks } from '../../reducers'
import './QuickBooksIntegration.less'

interface QuickBooksIntegrationProps {
  payRunId: string
}

export const QuickBooksIntegration: FC<QuickBooksIntegrationProps> = ({ payRunId }) => {
  const history = useHistory()
  const [sending, setSending] = useState(false)
  const [connections] = useQuickBooksConnections()
  const [payRunQuickBooks] = usePayRunQuickBooks(payRunId)
  const postedDate = payRunQuickBooks?.postedDate
  const postedBy = payRunQuickBooks?.postedBy
  const poster = useSelector((state: StoreState) => selectUserById(state, postedBy || ''))
  const canSend = usePermissionGate(Permission.quickBooks, PermissionAction.Send)

  useEffect(() => {
    if (postedBy) {
      dispatch(fetchUser(postedBy, { strategy: 'when-empty' }))
    }
  }, [postedBy])

  const handleConfigure = useCallback(() => {
    history.push(SETTINGS_ROUTES.quickBooks)
  }, [history])

  const handleSend = useCallback(async () => {
    confirm({
      title: postedBy ? 'Re-send to QuickBooks' : 'Send to QuickBooks',
      content: postedBy
        ? 'Do you want to re-send the payroll to QuickBooks?'
        : 'Do you want to send the payroll to QuickBooks?',
      onOk: async () => {
        try {
          setSending(true)
          await dispatch(sendToQuickBooks(payRunId))
          dispatch(refetchPayRunQuickBooks())
        } finally {
          setSending(false)
        }
      },
      okText: postedBy ? 'Re-send' : 'Send',
      okType: 'primary'
    })
  }, [payRunId, postedBy])

  if (!canSend) return null

  return (
    <Row className="quick-books-integration" align="middle">
      <Col>
        {connections.length > 0 ? (
          <Space>
            <Link className="quick-books-integration__link" onClick={handleSend}>
              <img alt="QuickBooks" src="/partners/quick-books.png" className="quick-books-integration__logo" />
              Send to QuickBooks {sending && <Spin indicator={<LoadingOutlined spin />} />}
            </Link>
            {payRunQuickBooks?.postedDate && (
              <Tooltip
                title={
                  <>
                    Sent by {poster?.loginName || poster?.id} on {moment(postedDate).format('DD MMM YYYY hh:mm:ss A')}
                  </>
                }
              >
                <Tag type="success">Sent</Tag>
              </Tooltip>
            )}
          </Space>
        ) : (
          <Link className="quick-books-integration__link" onClick={handleConfigure}>
            Setup QuickBooks connection
          </Link>
        )}
      </Col>
      <Col flex="1"></Col>
      <Col hidden={connections.length === 0}>
        <Tooltip title="Configure QuickBooks connection & item mapping">
          <SecondaryLink onClick={handleConfigure}>
            <i className="fal fa-gear" />
          </SecondaryLink>
        </Tooltip>
      </Col>
    </Row>
  )
}
