import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { QuestionState } from '../types'

export const selectQuestions = createSelector(
  (state: StoreState) => state.performance.questions,
  (questionsState): QuestionState[] => {
    const questions = Object.values(questionsState?.entities || {}) as QuestionState[]
    return questions.sort((a, b) => {
      const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
      const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

      if (bInactiveDate === 0 && aInactiveDate === 0) {
        return a!.name.localeCompare(b!.name)
      } else {
        return aInactiveDate - bInactiveDate
      }
    })
  }
)
