import React, { FC } from 'react'
import { Select, SelectProps, Tag } from '~/core-components'
import { useQuickBooksContacts } from '../../hooks'
import { Col, InactiveTag, Row } from '~/components'

interface QuickBooksSelectContactProps extends SelectProps {
  realmId?: string
}

export const QuickBooksSelectContact: FC<QuickBooksSelectContactProps> = ({ realmId, ...props }) => {
  const [contacts] = useQuickBooksContacts(realmId)

  return (
    <Select showSearch optionFilterProp="title" optionLabelProp="title" dropdownMatchSelectWidth={false} {...props}>
      {contacts.map(a => (
        <Select.Option key={a.id} value={a.id} title={a.displayName}>
          <Row>
            <Col flex={1}>
              {!a.active && <InactiveTag />}
              {a.name}
            </Col>
            <Col>
              <Tag>{a.contactType}</Tag>
            </Col>
          </Row>
        </Select.Option>
      ))}
    </Select>
  )
}
