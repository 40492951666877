import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { AuditLoginViewState } from './audit-login.state'
import { AuditTableViewState } from './audit-table.state'

export const systemPersistConfig = {
  key: 'system',
  whitelist: [],
  storage
}

export interface SystemRootState {
  // auditLogins
  auditLoginsView: EntityState<AuditLoginViewState>
  auditLoginsViewLoading: boolean
  auditLoginsViewRefetch: number

  // auditTables
  auditTablesView: EntityState<AuditTableViewState>
  auditTablesViewLoading: boolean
  auditTablesViewRefetch: number
}
