import { KeyValue, Pagination } from '~/types/common'
import { request, getBaseUrl, timeout } from '~/utils'
import { CpfFileRequest, CpfSubmissionViewState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/cpfsubmission')

export const apiGetCpfSubmissionsView = (viewId: string, params: Pagination) =>
  request<CpfSubmissionViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetCpfPaygroups = (params: any) => request<KeyValue[]>('get', `${baseUrl}/paygroup`, params)

export const apiGetCpfFile = (params: CpfFileRequest) =>
  request('post', `${baseUrl}/download`, params, { responseType: 'blob', timeout })

export const apiSubmitCpfFile = (params: CpfFileRequest) => request('post', `${baseUrl}/submit`, params)

export const apiGetCpfSubmission = (id: string) => request('get', `${baseUrl}/submit/log/${id}`)

export const apiGetCpfSubmittedFiles = (id: string) =>
  request('post', `${baseUrl}/submit/download/${id}`, undefined, { responseType: 'blob', timeout })
