import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ZealysLoading } from '~/components'
import { apiGetApexToken, setOedApexState, setOedSubmissionId } from '~/features/employee'
import { dispatch } from '~/stores/store'
import { useQuery } from '~/hooks/use-query'
import { showError } from '~/utils'
import { StoreState } from '~/types/store'
import { REPORTS_ROUTES } from '~/routes/routes'

interface OedCallbackProps {}

export const OedCallback: FC<OedCallbackProps> = () => {
  const query = useQuery()
  const history = useHistory()
  const historyPush = history.push
  const code = query.get('code')
  const oedSubmissionId = useSelector((state: StoreState) => state.employee.oedSubmissionId)

  useEffect(() => {
    const retrieveIrasToken = async () => {
      console.log('Retrieving APEX token', code)
      if (code) {
        const { result, status, message } = await apiGetApexToken(code)
        console.log('APEX token', result)
        if (status && result) {
          await dispatch(setOedApexState(result))
          historyPush(
            `${REPORTS_ROUTES.oedSubmission.replace(':id', oedSubmissionId).replace(':tab?', 'summary')}?action=1`
          )
          dispatch(setOedSubmissionId(''))
        } else {
          showError(message || 'Error occurred when getting access token from APEX')
        }
      }
    }

    retrieveIrasToken()
  }, [code, historyPush, oedSubmissionId])

  return <ZealysLoading />
}
