import React, { FC, useCallback, useState, ChangeEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Form, Input, Space } from '~/core-components'
import { Col, DrawerForm, EmSelect, LveKeyValues, Row } from '~/components'
import { useSysOptions } from '~/features/employee'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { apiGetEmSelect } from '../../../api/employee.api'
import { IAddLeaveGrant } from '../../../types'
import { addLeaveGrant, fetchLeaveTypes } from '../../../actions'

export interface AddGrantLeaveDrawerProps {
  visible: boolean
  onClose: (success: boolean) => void
}

const EMPTY_FORM_DATA: IAddLeaveGrant = {
  employeeId: '',
  leaveTypeId: '',
  earned: 0,
  startDate: '',
  endDate: '',
  notes: ''
}

export const AddGrantLeaveDrawer: FC<AddGrantLeaveDrawerProps> = ({ visible, onClose }: AddGrantLeaveDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IAddLeaveGrant>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  const leaveTypes = useSelector((state: StoreState) => state.leave.leaveTypes.entities)
  const [leaveUnits] = useSysOptions('lve_unit_display')
  const leaveUnit = leaveUnits[leaveTypes[formData.leaveTypeId]?.unit || '']?.value.toLowerCase()

  useEffect(() => {
    dispatch(fetchLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => {
      const updated = { ...formData, ...updates }
      if ('earned' in updates) {
        updated.earned = Math.round(updates.earned * 2) / 2
      }

      return updated
    })
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addLeaveGrant(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose(true)
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('active')
    if (status) {
      return result
    }
    return []
  }, [])

  return (
    <DrawerForm
      open={visible}
      title="Grant leave"
      onClose={() => onClose(false)}
      confirmLoading={loading}
      width={500}
      formId="form-grant-leave"
    >
      <Form id="form-grant-leave" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Employee" validateStatus={errors?.employeeId ? 'error' : ''} help={errors?.employeeId}>
              <EmSelect
                ref={focusRef}
                value={formData.employeeId}
                onFetch={handleFetchEmployees}
                onChange={(value: string) => handleFormDataChange({ employeeId: value, leaveTypeId: '' })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Leave type"
              validateStatus={errors?.leaveTypeId ? 'error' : ''}
              help={errors?.leaveTypeId}
            >
              <LveKeyValues
                id="grantLeaveType"
                controller="leavetype/grant"
                fetchStrategy="always"
                value={formData.leaveTypeId}
                onChange={(value: string) => handleFormDataChange({ leaveTypeId: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Form.Item label="Quantity" validateStatus={errors?.earned ? 'error' : ''} help={errors?.earned}>
              <Space>
                <Input.Number
                  min={0}
                  step={0.5}
                  value={formData.earned}
                  onChange={(value: number | null) => handleFormDataChange({ earned: value || 0 })}
                />
                <span>{leaveUnit}</span>
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Validity period"
              validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
              help={errors?.startDate || errors?.endDate}
            >
              <Input.DateRange
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null

                  if (
                    startDate !== null &&
                    endDate !== null &&
                    (startDate !== formData.startDate || endDate !== formData.endDate)
                  ) {
                    handleFormDataChange({ startDate, endDate })
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
              <Input.TextArea
                rows={5}
                value={formData.notes}
                onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ notes: value?.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
