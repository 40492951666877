import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { IRating } from '../types'

export const selectRatings = createSelector(
  (state: StoreState) => state.performance.ratings,
  (ratingsState): IRating[] => {
    const ratings = Object.values(ratingsState?.entities || {}) as IRating[]
    return ratings.sort((a, b) => {
      const aInactiveDate = new Date(a!.inactiveDate || '').getTime() || 0
      const bInactiveDate = new Date(b!.inactiveDate || '').getTime() || 0

      if (bInactiveDate === 0 && aInactiveDate === 0) {
        return a!.name.localeCompare(b!.name)
      } else {
        return aInactiveDate - bInactiveDate
      }
    })
  }
)
