import React, { CSSProperties, FC, memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Checkbox,
  ColumnsType,
  Form,
  PageHeader,
  SecondaryText,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip
} from '~/core-components'
import { DocumentTitle, InactiveTag, SearchInput } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { fetchEmpKeyvalues } from '~/features/master'
import { useFormulas } from '~/features/formula'
import {
  Screen,
  ViewCriteria,
  ViewCriteriaSimple,
  updateViewCriteria,
  useViewSchema,
  useFirstView
} from '~/features/selection'
import { useSysOptions } from '~/features/employee'
import { FetchOptions } from '~/types/common'
import { ProrationType, WageType } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { getFileTimestamp, isInactive } from '~/utils/dateUtil'
import { AddPayItemDrawer } from './components/AddPayItemDrawer'
import { selectPayItemsSgView } from '../../selectors'
import { fetchPayItemsView } from '../../actions'
import { useIr8aCategories } from '../../hooks'
import { PayItemState } from '../../types'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { apiGetPayItemsExcel } from '../../api/payitem.api'
import { downloadWithDom, showError } from '~/utils'
import './PayItemsSg.less'

type PayItemSgTable = {
  name: string
  children: PayItemState[]
}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: PayItemState
}

export interface PayItemsSgProps {}

const SCREEN_CODE: Screen = 'pay_item'
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const cardBodyStyle: CSSProperties = { padding: 0 }
const groupRowStyle: CSSProperties = { whiteSpace: 'nowrap' }
const secondaryTextStyles: CSSProperties = { display: 'block', fontSize: 12 }
const expandable = { defaultExpandAllRows: true, expandRowByClick: false }
const labelRow1Style: CSSProperties = { lineHeight: 1.1 }

const AdditionSign = memo(() => (
  <Tooltip title="Allowance">
    <PlusOutlined style={{ color: 'green' }} />
  </Tooltip>
))
const DeductionSign = memo(() => (
  <Tooltip title="Deduction">
    <MinusOutlined style={{ color: 'red' }} />
  </Tooltip>
))

const whenEmptyStrategy: FetchOptions = { strategy: 'when-empty' }

export const PayItemsSg: FC<PayItemsSgProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const payItemsSg = useSelector(selectPayItemsSgView)
  const dataLoading = useSelector((state: StoreState) => state.master.payItemsLoading)
  const [formulas] = useFormulas()
  const [ir8aCategories] = useIr8aCategories()
  const [wageTypes] = useSysOptions('wage_type')
  const [prorationTypes] = useSysOptions('proration_type')
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const [search, setSearch] = useState<string>('')
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)

  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    // Fetch for selectPayItemsSgView
    dispatch(fetchEmpKeyvalues('payItemCategory', 'sysoption', { type: 'pay_item_category' }, whenEmptyStrategy))
    dispatch(fetchEmpKeyvalues('payItemType', 'sysoption', { type: 'pay_item_type' }, whenEmptyStrategy))
  }, [])

  useDeepCompareEffect(() => {
    if (schema && viewId) {
      dispatch(fetchPayItemsView(viewId, search, 'sg'))
    }
  }, [schema || {}, viewId, search])

  const handleAddPayItem = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleCriteriaApply = useCallback(
    (criteria: ViewCriteria[]) => {
      if (schema) {
        dispatch(updateViewCriteria(SCREEN_CODE, schema.id, { id: schema.id, criteria }))
      }
    },
    [schema]
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const columns: ColumnsType<PayItemSgTable | PayItemState> = [
    {
      title: 'Payroll item',
      key: 'name',
      dataIndex: 'name',
      className: 'inline-flex',
      render: (value: string, record) => {
        if ('children' in record) {
          return <div style={groupRowStyle}>{value}</div>
        }

        let rateDisplay = ''
        if (record.formulaId) {
          if (record.rate > 1) {
            rateDisplay = `${record.rate} x ${formulas[record.formulaId]?.name.toLowerCase()}`
          } else {
            rateDisplay = formulas[record.formulaId]?.name || ''
          }
        } else if (record.rate > 1) {
          rateDisplay = `Fixed rate: ${record.rate.toString()}` // fixed rate
        }

        return (
          <Space align="start">
            {record.type === 'a' ? <AdditionSign /> : <DeductionSign />}
            <div style={labelRow1Style}>
              <Link to={SETTINGS_ROUTES.payItemSg.replace(':id', record.id)}>{value}</Link>{' '}
              {record.isSys && <Tag type="original">system</Tag>}
              {isInactive(record.inactiveDate) && <InactiveTag />}
              <SecondaryText style={secondaryTextStyles}>{rateDisplay}</SecondaryText>
            </div>
          </Space>
        )
      }
    },
    {
      title: 'Proration',
      key: 'prorationType',
      dataIndex: 'prorationType',
      align: 'left',
      render: (value: string, record) => {
        if ('children' in record) {
          return null
        }
        if (value === ProrationType.noProration) {
          return null
        }
        return prorationTypes[value]?.value
      }
    },
    {
      title: 'CPF',
      key: 'wageType',
      dataIndex: 'wageType',
      align: 'left',
      render: (value: string, record) => {
        if ('children' in record) {
          return null
        }
        if (value === WageType.nonCpf) {
          return null
        }
        return wageTypes[value]?.value
      }
    },
    {
      title: 'SDL',
      key: 'hasSdl',
      dataIndex: 'hasSdl',
      align: 'center',
      render: (value: boolean, record) => {
        if ('children' in record) {
          return null
        }
        return value ? <Checkbox checked={value} readOnly /> : null
      }
    },
    {
      title: 'Taxable',
      key: 'ir8aCategory',
      dataIndex: 'ir8aCategory',
      align: 'center',
      render: (value: string, record) => {
        if ('children' in record) {
          return null
        }
        return value ? (
          <Tooltip title={ir8aCategories[value]?.value}>
            <Checkbox checked={value != null} readOnly />
          </Tooltip>
        ) : null
      }
    }
  ]

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetPayItemsExcel(viewId, { search })

        if (status) {
          const fileName = `payroll_items_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, search, isMountedRef])

  return (
    <div id="payitems" className="payitems payitems-sg">
      <DocumentTitle title="Payroll Items" />
      <PageHeader
        title="Payroll items"
        containerId="payitems"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Space>
              <Button key="add" onClick={handleAddPayItem}>
                Add payroll item
              </Button>
              <Tooltip title="Download payroll item details">
                <Button
                  icon={<i className="fal fa-arrow-down-to-bracket" />}
                  onClick={handleDownloadClick}
                  loading={downloading}
                />
              </Tooltip>
            </Space>
          )
        }
      />
      <div className="payitems__body">
        <div className="payitems__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
        </div>

        {payItemsSg?.length > 0 ? (
          <Card bodyStyle={cardBodyStyle}>
            <Table
              rowKey="id"
              dataSource={payItemsSg}
              pagination={false}
              expandable={expandable}
              columns={columns}
              loading={dataLoading || viewLoading}
              className="payitems__table"
            />
          </Card>
        ) : (
          <Skeleton active />
        )}
      </div>
      {canModify && <AddPayItemDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
