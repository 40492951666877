import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ReviewRatingScaleTemplateState } from '../types'

export const selectReviewRatingScaleTemplates = createSelector(
  (state: StoreState) => state.performance.reviewRatingScaleTemplates,
  reviewRatingScaleTemplatesState =>
    memoize((templateId?: string): ReviewRatingScaleTemplateState[] => {
      if (!templateId) return []

      const reviewRatingScaleTemplates = Object.values(
        reviewRatingScaleTemplatesState[templateId]?.entities || {}
      ) as ReviewRatingScaleTemplateState[]
      return reviewRatingScaleTemplates.sort((a, b) => a.scale - b.scale)
    })
)
